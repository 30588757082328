import { useCallback, useState } from 'react'
import styled from 'styled-components'
import ReactCrop, { ReactCropProps } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { Box, Flex, LoadingIndicator } from '../../atoms'
import { Button } from '..'

import { cropImage } from './ImageCropper.helpers'

import { UploadedImageType } from '@web-apps/utils-types'

const StyledLoadingWrapper = styled.div`
  position: relative;
  height: 200px;
`

type ImageCropperProps = {
  imageData: UploadedImageType
  forceSquare?: boolean
  onCropComplete: (imageData: UploadedImageType) => void
  onDiscard: () => void
  discardButtonText: string
  cropButtonText: string
}

export const ImageCropper = ({
  imageData,
  forceSquare = false,
  onCropComplete,
  onDiscard,
  discardButtonText,
  cropButtonText,
}: ImageCropperProps) => {
  const [crop, setCrop] = useState<ReactCropProps['crop']>()
  const [isLoading, setLoader] = useState(false)

  const onImageLoaded = useCallback(
    (image: HTMLImageElement) => {
      if (!forceSquare) {
        setCrop({
          width: image.width,
          height: image.height,
        })
        return false
      }

      let side, dimension
      if (image.width < image.height) {
        side = 'width'
        dimension = 'y'
      } else {
        side = 'height'
        dimension = 'x'
      }

      setCrop({
        [side]: 100,
        [dimension]:
          (Math.abs(image.width - image.height) /
            Math.max(image.width, image.height)) *
          50,
        unit: '%',
        aspect: 1,
      })

      return false
    },
    [forceSquare]
  )

  const handleCrop = async () => {
    if (!crop) return
    setLoader(true)

    const croppedImageData = await cropImage(imageData, crop)
    if (croppedImageData) onCropComplete(croppedImageData)
  }

  return (
    <Flex direction="column">
      {isLoading ? (
        <StyledLoadingWrapper>
          <LoadingIndicator isCentered />
        </StyledLoadingWrapper>
      ) : (
        <>
          <Box mb={24}>
            <Flex justify="center">
              <ReactCrop
                minWidth={10}
                minHeight={10}
                keepSelection={true}
                imageStyle={{ maxHeight: '300px' }}
                src={imageData.imageUrl}
                crop={crop || {}}
                onComplete={(_, percentCrop) => setCrop(percentCrop)}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onImageLoaded={onImageLoaded}
              />
            </Flex>
          </Box>
          <Flex justify="flex-end">
            <Box mr={12}>
              <Button inverse clickHandler={onDiscard}>
                {discardButtonText}
              </Button>
            </Box>
            <Button clickHandler={handleCrop}>{cropButtonText}</Button>
          </Flex>
        </>
      )}
    </Flex>
  )
}
