import styled, { CSSProperties } from 'styled-components'

import { Theme } from '../../theme'

export const StyleBarContainer = styled.div`
  position: relative;
  flex: 1;

  border-radius: 8px;
`

export const StyledBackgroundBar = styled.div`
  height: 8px;
  border-radius: 8px;
  background-color: ${Theme.Colors.line};

  flex: 1;
`

export const StyledFilledBar = styled.div<{
  $width: CSSProperties['width']
  $fillColor: CSSProperties['color']
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  border-radius: 8px;

  background-color: ${({ $fillColor = 0 }) => $fillColor};
  width: ${({ $width = 0 }) => $width};
`

export const StyledCompleteIndicatorContainer = styled.div<{
  $fillColor?: CSSProperties['color']
  $opacity?: CSSProperties['opacity']
}>`
  position: relative;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${({ $fillColor = Theme.Colors.line }) => $fillColor};

  & > span {
    opacity: ${({ $opacity = 1 }) => $opacity};
  }
`
