import styled from 'styled-components'

import { IconButton } from '../../molecules/IconButton'
import { Theme } from '../../theme'

const BORDER_RADIUS = '8px'
const CONTAINER_SQUARE_SIZE = '80px'

type ImageUploaderWrapperProps = {
  $hasError?: boolean
}

export const StyledLabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin: 0;
`

export const StyledImageUploaderWrapper = styled.div<ImageUploaderWrapperProps>`
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: ${BORDER_RADIUS};

  ${({ $hasError = false }) =>
    `border: 1px dashed ${
      $hasError ? Theme.Colors.typography.error : Theme.Colors.line
    };`}

  line-height: 16px;
  cursor: pointer;
`

export const StyledUploadedImagesContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;

  border-radius: ${BORDER_RADIUS};
  max-width: 112px;
  min-height: 112px;
  justify-content: center;
`

export const StyledImageUploaderContainer = styled.div`
  position: relative;
  width: ${CONTAINER_SQUARE_SIZE};
  height: ${CONTAINER_SQUARE_SIZE};

  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledUploadControl = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  max-width: max-content;
  padding: 8px;

  border: 1px solid ${Theme.Colors.line};
  border-radius: ${BORDER_RADIUS};

  background: inherit;
  outline: none;

  font-size: 14px;
  font-weight: 700;

  cursor: pointer;
`

export const StyledUplodedImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${BORDER_RADIUS};
  object-fit: cover;
`

export const StyledRemoveIcon = styled(IconButton)`
  position: absolute;
  top: -6px;
  right: -6px;
`

export const StyledAddIcon = styled(IconButton)`
  position: absolute;
  bottom: -6px;
  right: -6px;
`

export const StyledDropZoneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: ${BORDER_RADIUS};
  background: ${Theme.Colors.background.alternate};
  margin-right: 24px;
  margin: 0 auto;
`

export const StyledImageUploaderMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledInfoMessage = styled.p<{ $errored?: boolean }>`
  margin: 0;

  font-weight: 400;
  font-size: 12px;
  ${({ $errored }) =>
    `color: ${
      $errored ? Theme.Colors.typography.error : Theme.Colors.typography.regular
    }`};
`
