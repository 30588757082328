import React, { ReactNode } from 'react'

import { Flex } from '../../atoms'
import { ButtonProps, ButtonSize } from '../Button/Button'
import {
  StyledPrimaryButton,
  StyledPrimaryButtonMobile,
  StyledPrimaryButtonDesktop,
} from './PrimaryButton.styles'

type Props = Omit<ButtonProps, 'children'> & {
  title: string
  icon: ReactNode
  mobileIcon?: ReactNode
}

export const PrimaryButton = ({
  title,
  icon,
  mobileIcon,
  clickHandler,
  size = ButtonSize.REGULAR,
  outline = true,
  ...otherProps
}: Props) => {
  return (
    <StyledPrimaryButton
      $size={size}
      $outline={outline}
      onClick={clickHandler}
      {...otherProps}
    >
      <StyledPrimaryButtonMobile>
        {mobileIcon ? mobileIcon : icon}
      </StyledPrimaryButtonMobile>
      <StyledPrimaryButtonDesktop>
        <Flex gap={16}>
          {icon}
          <span>{title}</span>
        </Flex>
      </StyledPrimaryButtonDesktop>
    </StyledPrimaryButton>
  )
}
