import styled from 'styled-components'
import { Theme } from '../../../../theme'

export const StyledLeftBadgesContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledRightBadgesContainer = styled.div`
  position: absolute;
  right: -8px;
  top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const StyledLeftBadge = styled.div<{ $progress: number }>`
  color: ${Theme.Colors.typography.regular};
  text-align: center;
  font-size: 11px;
  line-height: 12px;

  display: flex;
  width: 48px;
  height: 48px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 48px;
  background: ${({
    $progress,
  }) => `radial-gradient(closest-side, white 89%, transparent 90% 100%),
    conic-gradient(#4fc01a ${$progress}%, #efefef 0)`};
`
export const StyledRightBadge = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background: ${Theme.Button.backgroundColor};
  color: ${Theme.Colors.typography.inverse};
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`
