import { isMobileDevice } from '../common'
import * as Sentry from '@sentry/nextjs'
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunctionForOptionals = () => {}

/* Suggestion from https://stackoverflow.com/a/38265168 */
const isUriEncoded = (uri: string) => {
  try {
    return uri !== decodeURIComponent(uri)
  } catch {
    return false
  }
}

export const encodeUriWhenNotEncoded = (uri: string) =>
  isUriEncoded(uri) ? uri : encodeURI(uri)

export const truncateText = (
  text: string,
  {
    maxLength,
    useEllipsis = false,
  }: { maxLength: number; useEllipsis?: boolean }
) => {
  const ellipsis = '...'
  const ellipsisLength = ellipsis.length

  if (text.length <= maxLength) return text
  if (useEllipsis && maxLength > ellipsisLength)
    return text.substring(0, maxLength - ellipsisLength) + ellipsis

  return text.substring(0, maxLength)
}

export const nativeShareWrapper = (url: string, cb: () => void) => {
  if (navigator.share && isMobileDevice()) {
    return navigator.share({ url }).catch((e) => {
      if (!e.toString().includes('AbortError')) {
        Sentry.captureException(e)
      }
    })
  }

  return cb()
}
