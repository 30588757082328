import styled from 'styled-components'

import { Theme } from '../../theme'

export const StyledOutsideContainer = styled.div<{ $sticky: boolean }>`
  background: ${Theme.Colors.lightSand};
  padding: 0 16px;
  margin: 0 -16px;

  ${({ $sticky }) =>
    $sticky &&
    `
      width: 100vw;
      position: sticky;
      top: 64px;
      z-index: 2;
    `}
`

export const StyledInsideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  padding: 4px 2px;
`

export const StyledFilterLabel = styled.span`
  font-weight: 500;
`
