import styled, { css } from 'styled-components'
import { Card } from '../atoms'

import { BREAKPOINTS, media } from '../helpers'
import { Theme } from '../theme'

export const BasePopoverCSS = css`
  z-index: ${Theme.Popover.zIndex};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-style: none;

  border-radius: ${Theme.Card.borderRadiusInPx}px
    ${Theme.Card.borderRadiusInPx}px 0 0;

  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: slide-up 0.2s ease;
  }

  ${media.from(
    BREAKPOINTS.sm,
    `
    position: absolute;
    top: calc(100% - 8px);
    bottom: unset;
    border-radius: ${Theme.Card.borderRadiusInPx}px;

    @keyframes slide-down {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      animation: slide-down 0.2s ease;
    }
    `
  )}

  ${media.from(BREAKPOINTS.lg, `z-index: 10;`)}
`

export const BasePopoverListCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: 80vh;
  padding-block: 8px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      max-height: unset;
    `
  )}
`

export const StyledPopoverWrapper = styled(Card)`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  max-height: ${Theme.Popover.maxHeightInPx}px;
  box-shadow: ${Theme.Card.boxShadow};
  overflow: auto;

  ${media.from(
    BREAKPOINTS.sm,
    `
      max-width: 375px;
      min-width: 310px;
      margin-inline: auto;
      margin-bottom: 16px;
      border-radius: ${Theme.Card.borderRadiusInPx}px;
    `
  )};
`
