import React from 'react'

import { FieldErrors, FormConfigsType } from './Form.types'

export const MAX_TITLE_LENGTH = 100

const isChildNotTraversable = (child: React.ReactChild) =>
  !child ||
  typeof child === 'string' ||
  typeof child === 'number' ||
  !child.props ||
  child.props['data-ignore-by-form']

const hasChildren = (child: React.ReactChild) =>
  !isChildNotTraversable(child) &&
  Boolean((child as React.ReactElement).props.children)

const isFormFieldShouldBeUncontrolled = (child: React.ReactElement): boolean =>
  typeof child.props.name === 'string' &&
  child.props.name.startsWith('uncontrolled_')

export const isFormFieldControlled = (child: React.ReactElement): boolean =>
  typeof child.props.name === 'string' &&
  typeof child.props.control === 'object'

const isFormFieldUncontrolled = (child: React.ReactElement): boolean =>
  !isFormFieldControlled(child) &&
  typeof child.props.name === 'string' &&
  !isFormFieldShouldBeUncontrolled(child)

const isFormFieldWatchable = (child: React.ReactElement): boolean =>
  isFormFieldUncontrolled(child) && child.props.showAllowedChars === true

const isDOMElement = (child: React.ReactElement): boolean =>
  typeof child.type === 'string'

/**
 * To check if a ref from form element (supplied by react-hook-form) is an HTMLElement
 * Idea from: https://stackoverflow.com/a/384380
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHTMLElement = (o: any) => {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === 'object' &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === 'string'
}

/**
 *
 * Get form name of fields to be watched between renders.
 * One example are the ones that have the length indicator
 *
 * @param c
 * @param fieldsToWatchByNameAccumulator
 *
 * @returns array with names of form fields which need to be watchable
 */
export const computeFieldsToWatch = (
  c: React.ReactChild[],
  fieldsToWatchByNameAccumulator: Set<string> = new Set()
) => {
  React.Children.forEach(c, (child: React.ReactChild) => {
    if (isChildNotTraversable(child)) return

    const childElement = child as React.ReactElement
    if (hasChildren(child)) {
      computeFieldsToWatch(
        childElement.props.children,
        fieldsToWatchByNameAccumulator
      )
      return
    }

    if (isFormFieldWatchable(childElement))
      fieldsToWatchByNameAccumulator.add(childElement.props.name)
  })

  return fieldsToWatchByNameAccumulator
}

/**
 * Register uncontrolled form fields found in react tree passed as argument
 *
 * @param c childs (react tree) to be enhanced
 * @param formConfigs actual state of form
 * @param formFieldHandler possiblity to add handlers which will be executed when passing through the field
 * @returns enhanced childs
 */
export const enhanceFormFields = (
  c: React.ReactChild[],
  formConfigs: FormConfigsType,
  formFieldHandler?: (e: React.ReactElement) => void
): React.ReactChild[] => {
  return React.Children.map(c, (child: React.ReactChild) => {
    if (isChildNotTraversable(child)) return child
    const childToEnhance = child as React.ReactElement

    /**
     * this was added due to a problem with NextJs links
     * do not know the reason for the error, but something seems to be odds when cloning the Link component from 'next/link'
     *
     * short circuiting the traversing of form children, since link elements can not have input elements,
     * there is no need to keep traversing it
     */
    if (childToEnhance.props.href) return child

    if (isFormFieldUncontrolled(childToEnhance)) {
      if (formFieldHandler) formFieldHandler(childToEnhance)

      const inputData = formConfigs.register(
        childToEnhance.props.name,
        computeValidationOptionsForFormFieldChild(childToEnhance, formConfigs)
      )

      const props = {
        ...{
          ...childToEnhance.props,
          ...{
            ...inputData,

            onChange: (e: string | boolean | number | []) => {
              if (childToEnhance.props.onChange)
                childToEnhance.props.onChange(e)
              inputData.onChange(e)
            },
            onBlur: (e: string | boolean | number | []) => {
              if (childToEnhance.props.onBlur) childToEnhance.props.onBlur(e)
              inputData.onBlur(e)
            },
          },
        },
      }

      if (!isDOMElement(childToEnhance)) {
        props.remainingAllowedChars =
          calculateRemainingAllowedCharsForFormFieldChild(
            childToEnhance,
            formConfigs
          )

        props.errorMessages = retrieveErrorMessagesForFormFieldChild(
          childToEnhance,
          formConfigs
        )
      }

      return React.createElement(childToEnhance.type, props)
    }

    if (isFormFieldControlled(childToEnhance)) {
      if (formFieldHandler) formFieldHandler(childToEnhance)

      // adding validation rules
      return React.createElement(childToEnhance.type, {
        ...{
          ...childToEnhance.props,
          rules: {
            ...(computeValidationOptionsForFormFieldChild(
              childToEnhance,
              formConfigs
            ) || {}),
            ...(childToEnhance.props.rules || {}),
          },
        },
      })
    }

    if (hasChildren(child) && typeof childToEnhance.props.href !== 'string') {
      return React.cloneElement(childToEnhance, {
        ...childToEnhance.props,
        children: enhanceFormFields(
          childToEnhance.props.children,
          formConfigs,
          formFieldHandler
        ),
      })
    }

    return child
  })
}

const calculateRemainingAllowedCharsForFormFieldChild = (
  formFieldChild: React.ReactElement,
  formConfigs: FormConfigsType
): number | undefined => {
  const indexWatchField =
    formConfigs.watchableInfo.watchableFieldNames.findIndex(
      (fieldName) => formFieldChild.props.name === fieldName
    )

  let remainingAllowedChars: number | undefined
  if (
    formConfigs.watchableInfo.watchableFieldValues &&
    indexWatchField >= 0 &&
    typeof formFieldChild.props.remainingAllowedChars !== 'number' &&
    typeof formConfigs.watchableInfo.watchableFieldValues[indexWatchField] ===
      'string' &&
    formFieldChild.props.maxLength &&
    formFieldChild.props.showAllowedChars
  ) {
    remainingAllowedChars =
      Number(formFieldChild.props.maxLength) -
      formConfigs.watchableInfo.watchableFieldValues[indexWatchField].length
  }

  return remainingAllowedChars
}

const retrieveErrorMessagesForFormFieldChild = (
  formFieldChild: React.ReactElement,
  formConfigs: FormConfigsType
): string[] => {
  let errorMessages = formFieldChild.props.errorMessages
    ? [...formFieldChild.props.errorMessages]
    : []

  if (formConfigs.formState.errors[formFieldChild.props.name]) {
    errorMessages = [
      ...errorMessages,
      formConfigs.formState.errors[formFieldChild.props.name].message,
    ]
  }

  return errorMessages
}

const computeValidationOptionsForFormFieldChild = (
  formFieldChild: React.ReactElement,
  formConfigs: FormConfigsType
) => {
  const formFieldName = formFieldChild.props.name
  const validationDescriptors = {
    ...(formConfigs.validations?.descriptors || {}),
  }

  const validationDescriptorsForField = validationDescriptors[formFieldName]

  if (!validationDescriptorsForField) return
  return {
    ...validationDescriptorsForField,
    // redefining validate function to send current data of form, for more advanced validations
    validate:
      typeof validationDescriptorsForField.validate === 'function'
        ? () => {
            if (!validationDescriptorsForField.validate) return
            const formData = formConfigs.getValues()

            return validationDescriptorsForField.validate(
              formData[formFieldName],
              formConfigs.getValues(),
              formConfigs.formState
            )
          }
        : undefined,
  }
}

export const getErrorMessages = (errors: FieldErrors, fieldName: string) => {
  if (!errors[fieldName]) return undefined

  return Array.isArray(errors[fieldName].message)
    ? errors[fieldName].message
    : [errors[fieldName].message]
}
