import styled from 'styled-components'

import { BREAKPOINTS, media } from '../../helpers'
import {
  HasErrorCSS,
  BasePopoverCSS,
  StyledPopoverWrapper as BaseStyledPopoverWrapper,
} from '../../styles'
import { Theme } from '../../theme'
import { Button } from '../Button'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const StyledHeader = styled.div`
  padding-bottom: 24px;

  ${media.from(
    BREAKPOINTS.sm,
    `
    display: none;
    `
  )};
`

export const StyledButton = styled(Button)<{ $hasError?: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  color: ${Theme.Colors.typography.regular};
  min-width: 125px;
  background: ${Theme.Colors.background.base};
  border-radius: 8px;
  font-weight: 400;
  border: 1px solid ${Theme.Colors.line};

  &:disabled {
    border-color: ${Theme.Colors.typography.inactive};
  }

  ${({ $hasError }) => HasErrorCSS({ hasError: $hasError })}
`

export const StyledPopoverWrapper = styled(BaseStyledPopoverWrapper)<{
  $maxDesktopWidth?: string
  $maxDesktopHeight?: string
}>`
  padding-top: 24px;
  ${media.from(
    BREAKPOINTS.sm,
    `
      min-width: 320px;
    `
  )};

  ${({ $maxDesktopWidth }) =>
    $maxDesktopWidth &&
    media.from(
      BREAKPOINTS.sm,
      `
        max-width: ${$maxDesktopWidth};
      `
    )};

  ${({ $maxDesktopHeight }) =>
    $maxDesktopHeight &&
    media.from(
      BREAKPOINTS.sm,
      `
        max-height: ${$maxDesktopHeight};
      `
    )};
`

export const StyledPopover = styled.div<{ $usePortal: boolean }>`
  ${BasePopoverCSS};

  ${media.from(
    BREAKPOINTS.sm,
    `
    top: calc(100% - 2px);
    left: unset;
    `
  )};
`
