import React, { InputHTMLAttributes } from 'react'

import { Box, Flex, Icon, Typography } from '../../atoms'
import { Theme } from '../../theme'

import {
  StyledLengthFieldIndicator,
  StyledError,
  StyledLabelText,
  StyledFieldDescriptionText,
  StyledNoteSmall,
} from '../../styles/form.styles'

import {
  StyledContainer,
  StyledInputContainer,
  StyledInput,
  StyledLeftDecoratorContainer,
  StyledRightDecoratorContainer,
} from './TextField.styles'

export enum TextFieldSize {
  NANO = 'nano',
  SLIM = 'slim',
  REGULAR = 'regular',
  HUGE = 'huge',
}

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'hidden'
  label?: string
  labelDecorator?: string
  labelRightDecorator?: JSX.Element
  description?: string
  fieldSize?: TextFieldSize
  leftDecorator?: JSX.Element
  rightDecorator?: JSX.Element
  remainingAllowedChars?: number
  showAllowedChars?: boolean
  showErrorState?: boolean
  showErrorIcon?: boolean
  errorMessages?: string[]
  notes?: string[]
  borderless?: boolean
  /* This is needed when using our form with react-hook-form and we want to do extra things with ref: https://www.react-hook-form.com/faqs/#Howtosharerefusage */
  refHandler?: (instance: HTMLInputElement) => void
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      labelDecorator,
      labelRightDecorator,
      description,
      leftDecorator,
      rightDecorator,
      showAllowedChars = false,
      remainingAllowedChars,
      errorMessages = [],
      showErrorState = false,
      showErrorIcon = true,
      notes = [],
      fieldSize = TextFieldSize.REGULAR,
      borderless = false,
      refHandler,
      ...inputProps
    },
    ref
  ) => {
    const hasErrors = showErrorState || errorMessages.length > 0

    return (
      <StyledContainer>
        {label && (
          <Flex justify="space-between">
            <StyledLabelText
              hasError={hasErrors}
              htmlFor={`input-${inputProps.name}`}
            >
              {label}{' '}
              {labelDecorator && (
                <Typography
                  as="span"
                  color={hasErrors ? 'error' : 'inactive'}
                  variant="hint"
                >
                  {labelDecorator}
                </Typography>
              )}
            </StyledLabelText>
            {labelRightDecorator}
          </Flex>
        )}
        {description && (
          <StyledFieldDescriptionText>{description}</StyledFieldDescriptionText>
        )}

        <StyledInputContainer mt={label ? Theme.Form.fieldLabelSpacingInPx : 0}>
          {leftDecorator && (
            <StyledLeftDecoratorContainer>
              {leftDecorator}
            </StyledLeftDecoratorContainer>
          )}

          <StyledInput
            id={`input-${inputProps.name}`}
            $size={fieldSize}
            $hasLeftDecorator={Boolean(leftDecorator)}
            $hasRightDecorator={Boolean(rightDecorator) || showAllowedChars}
            $borderless={borderless}
            $hasError={hasErrors}
            ref={
              refHandler
                ? (instance) => {
                    if (!instance || typeof ref !== 'function') return

                    ref(instance)
                    refHandler(instance)
                  }
                : ref
            }
            {...inputProps}
          />

          {!hasErrors &&
            showAllowedChars &&
            typeof remainingAllowedChars === 'number' &&
            remainingAllowedChars >= 0 && (
              <StyledLengthFieldIndicator>
                {remainingAllowedChars}
              </StyledLengthFieldIndicator>
            )}

          {(rightDecorator || (hasErrors && showErrorIcon)) && (
            <StyledRightDecoratorContainer>
              {hasErrors && showErrorIcon ? (
                <Icon.InfoImportant fillColor={Theme.Colors.typography.error} />
              ) : (
                rightDecorator
              )}
            </StyledRightDecoratorContainer>
          )}
        </StyledInputContainer>

        {hasErrors && <StyledError>{errorMessages[0]}</StyledError>}

        {!hasErrors && notes.length > 0 && (
          <Box mt={8}>
            <Flex direction="column">
              {notes.map((note) => (
                <StyledNoteSmall key={note}>{note}</StyledNoteSmall>
              ))}
            </Flex>
          </Box>
        )}
      </StyledContainer>
    )
  }
)
