import styled from 'styled-components'
import { Theme } from '../../theme'
import { Popover } from '../Popover'
import { BREAKPOINTS, media } from '../../helpers'

export const StyledOptionsItem = styled.a`
  padding: 8px 4px;
  width: 100%;

  &:hover {
    background: ${Theme.Colors.background.global};
    border-radius: 8px;
  }
`

export const StyledContainer = styled.div`
  position: relative;
`

export const StyledPopover = styled(Popover)`
  ${media.from(
    BREAKPOINTS.sm,
    `
      position: absolute;
      bottom unset;
      left: unset;
      right: unset;
      width: 225px;
    `
  )}
`
