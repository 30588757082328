import { AxiosResponse } from 'axios'

import { isFetchBaseQueryErrorType } from '../api'

export const raise = (message?: string) => {
  throw new Error(message)
}

export enum ApiErrorTypeEnum {
  UserEmailError = 'https://zezam.gitlab.io/api/problem/user/email-already-taken',
  UserEmailNotAllowed = 'https://zezam.gitlab.io/api/problem/user/email-not-allowed',
  ImageDownloadFailed = 'https://zezam.gitlab.io/api/problem/image/remote-asset-download-failed',
}

export const isCriteriaMatchedForApiErrorResponse = (
  {
    type = undefined,
    status = undefined,
  }: { type?: ApiErrorTypeEnum; status?: number },
  errorResponse?: unknown
) => {
  if (!isFetchBaseQueryErrorType(errorResponse)) return false

  const axiosResponse = errorResponse as AxiosResponse
  let isErrorCriteriaMatched = true
  if (type && axiosResponse.data?.type !== type) isErrorCriteriaMatched = false
  if (status && axiosResponse.status !== status) isErrorCriteriaMatched = false

  return isErrorCriteriaMatched
}

export const isImageDownloadApiError = (errorResponse?: unknown) =>
  isCriteriaMatchedForApiErrorResponse(
    {
      type: ApiErrorTypeEnum.ImageDownloadFailed,
      status: 502,
    },
    errorResponse
  )
