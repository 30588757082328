import { SocialNetworksEnum } from './socialNetworks.types'
import {
  SelfImageProps,
  RequestMethodsEnum,
  LinkAPIType,
  CountryISOCodeType,
} from './common.types'
import { CurrencyIdentifierEnum, PriceType } from './money.types'

import {
  CustomizationPropsType,
  SectionCoverFormat,
} from './customization.types'
import { ImageResponseType } from './image.types'
import {
  CommissionAggregateAPIType,
  CommissionAggregateType,
} from './affiliates.types'

export type SocialLinkType = {
  type: SocialNetworksEnum
  handle: string
  href?: string
}

export enum SectionTypeEnum {
  EXTERNAL_LINK = 'external-link',
  AFFILIATE_LINK = 'affiliate-link',
  CONTENT = 'content',
  CONTACT_LIST = 'contact-list',
  CONTAINER = 'container',
}

export enum SectionVariantEnum {
  PLAIN = 'plain',
  IMAGE = 'image',
  COVER = 'cover',
}

export enum VideoProviderEnum {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
}

export enum MusicProviderEnum {
  SPOTIFY = 'spotify',
  SOUND_CLOUD = 'soundCloud',
  APPLE_MUSIC = 'appleMusic',
  APPLE_PODCASTS = 'applePodcasts',
  DEEZER = 'deezer',
}

export type VideoDetailsType = {
  title: string
  url: string
}

export type EmbedVideoProps = {
  videoURL: string
}

export type VideoProviderDataType = {
  id: VideoProviderEnum
  name: string
  domains: string[]
  isVideoURLValid: (videoURL: URL) => boolean
  isSupportedByReactPlayer: boolean
  renderEmbedVideo?: (props: EmbedVideoProps) => JSX.Element
}

export type MusicDetailsType = {
  title: string
  url: string
}

export type PodcastDetailsType = {
  title: string
  url: string
}

export type EmbedMusicProps = {
  musicURL: string
}

export type MusicProviderDataType = {
  id: MusicProviderEnum
  name: string
  domains: string[]
  isMusicURLValid: (musicURL: URL) => boolean
  renderEmbed: (props: EmbedMusicProps) => JSX.Element
}

export type AuthorType = {
  href: string
  id: string
  name: string
  title?: string
}

export enum ExternalLinkMode {
  EMBED = 'EMBED',
  LINK = 'LINK',
}

export enum SectionExtension {
  NONE = 'none',
  ACCORDION = 'accordion',
}

export type SectionBasePropertiesType = {
  label: string
  tagline?: string
  styles: {
    variant: SectionVariantEnum
    extension?: SectionExtension
    cover?: SectionCoverFormat
    is_product_recommendations?: boolean
    is_video_link?: boolean
  }
  mode?: ExternalLinkMode
  price?: PriceType
  published: boolean
  _links: SelfImageProps & {
    about?: {
      href?: string
      id?: string
    }
    alternate?: {
      href: string
    }
    affiliate?: LinkAPIType
    self: {
      href: string
      id: string
      methods?: RequestMethodsEnum[]
    }
    author: AuthorType
    parent: {
      href: string
      id: string
      methods?: string[]
    }
    edit?: {
      href: string
      methods: string[]
      self?: string
      name: string
    }[]
  }
}

export type ContentSectionType = SectionBasePropertiesType & {
  type: SectionTypeEnum.CONTENT
  text: string
}

export type ExternalLinkSection = SectionBasePropertiesType & {
  type: SectionTypeEnum.EXTERNAL_LINK
  mode: ExternalLinkMode
}

export type AffiliateLinkSection = SectionBasePropertiesType & {
  type: SectionTypeEnum.AFFILIATE_LINK
  mode: ExternalLinkMode
  affiliate_link: {
    title: string
    created_at: string
    commission: CommissionAggregateAPIType
  }
}

export type ContactListSectionType = SectionBasePropertiesType & {
  type: SectionTypeEnum.CONTACT_LIST
  collect_email: boolean
  collect_phone: boolean
  submit_label: string
  contact_list_id?: string
  _links: {
    about: {
      id: string
    }
  }
}

export type ProductRecommendationsSectionType = Omit<
  SectionBasePropertiesType,
  'label'
> & {
  label?: SectionBasePropertiesType['label']
  type: SectionTypeEnum.CONTAINER
  items: ((
    | Omit<ExternalLinkSection, 'label'>
    | Omit<AffiliateLinkSection, 'label'>
  ) & {
    label?: SectionBasePropertiesType['label']
  })[]
}

export type SectionType =
  | ExternalLinkSection
  | ContentSectionType
  | ContactListSectionType
  | ProductRecommendationsSectionType
  | AffiliateLinkSection

export const LINKABLE_SECTION_TYPES = [
  SectionTypeEnum.EXTERNAL_LINK,
  SectionTypeEnum.AFFILIATE_LINK,
]

export type LinkableSectionTypeValidValues = Exclude<
  SectionTypeEnum,
  | SectionTypeEnum.CONTENT
  | SectionTypeEnum.CONTACT_LIST
  | SectionTypeEnum.CONTAINER
>

export type LinkableSectionType = Exclude<
  SectionType,
  | ContentSectionType
  | ContactListSectionType
  | ProductRecommendationsSectionType
>

export type LeafsSectionType = Exclude<
  SectionType,
  ProductRecommendationsSectionType
>

export const LEAF_SECTIONS = [
  ...LINKABLE_SECTION_TYPES,
  SectionTypeEnum.CONTENT,
  SectionTypeEnum.CONTACT_LIST,
]
export const isLeafSection = (section: SectionType) =>
  LEAF_SECTIONS.includes(section.type)

export type CreatorPageSettingsType = {
  show_classic_footer: boolean
  show_sticky_gradient_footer: boolean
}

export type Slug = string

export type CreatorPageLinkType = LinkAPIType & {
  id: string
  name: Slug
}

export type CreatorPageType = {
  name: string
  tagline: string
  social_links: SocialLinkType[]
  sections: SectionType[]
  styles: CustomizationPropsType
  settings: CreatorPageSettingsType
  redirect?: {
    section: SectionType
  }
  _links: SelfImageProps & {
    self: CreatorPageLinkType
    author: LinkAPIType
    affiliate_analytics_links?: LinkAPIType
    affiliate_analytics?: LinkAPIType
    edit?: LinkAPIType[]
    styles?: LinkAPIType
  }
}

export type ContainerItemAPIPayloadType = (
  | Omit<ExternalLinkSection, 'label'>
  | Omit<AffiliateLinkSection, 'label'>
) & {
  label?: SectionBasePropertiesType['label']
}
export type ContainerSectionAPIPayloadType = {
  items: ContainerItemAPIPayloadType[]
  label?: string
  tagline?: string
  published: boolean
  styles: SectionBasePropertiesType['styles']
  type: SectionTypeEnum.CONTAINER
  _links: SectionBasePropertiesType['_links'] & {
    'creator_page_section/create': LinkAPIType
    'create-in': LinkAPIType
  }
}

type AnalyticsClick = {
  visitors?: number
  events: number
}

type AnalyticsSocialLinkClick = AnalyticsClick & {
  type: string
}

type AnalyticsSectionClick = AnalyticsClick & {
  section: SectionType
}

type AnalyticsVisit = {
  visitors: number
  pageviews: number
}

type TimeseriesPoint = {
  date: string
}

export type EarningsType = {
  approved: PriceType
  pending: PriceType
  cancelled: PriceType
}

export type VisitsTimeseriesPoint = AnalyticsVisit & TimeseriesPoint

type CountryAnalyticsVisit = AnalyticsVisit & {
  country: CountryISOCodeType
}

type SourceAnalyticsVisit = AnalyticsVisit & {
  source: string
}

export type CreatorPageSimpleAnalyticsAPIPayload = {
  aggregate: {
    visitors: number
    pageviews: number
    clicks: number
    click_through_rate: number
  }
  timeseries: VisitsTimeseriesPoint[]
  social_link_clicks?: AnalyticsSocialLinkClick[]
  section_clicks?: AnalyticsSectionClick[]
  countries?: CountryAnalyticsVisit[]
  sources?: SourceAnalyticsVisit[]
}

export type CommissionsTimeseriesPoint = TimeseriesPoint & {
  stats: {
    earnings: EarningsType
    sales: number
    clicks: number
  }
}

export type CreatorPageAffiliateAnalyticsAPIPayload = {
  aggregate: {
    earnings: EarningsType
    sales: number
    clicks: number
    conversion_rate: number
    click_quality: number
  }
  timeseries: CommissionsTimeseriesPoint[]
}

export type BrandLinkAPIType = LinkAPIType & { title?: string; id: string }

export type AffiliateLinkAnalyticsAPI = {
  affiliate_link?: {
    commission?: CommissionAggregateAPIType
    title?: string
    _links: {
      self: LinkAPIType & { id: string }
      brand?: BrandLinkAPIType
      original: LinkAPIType
      short: LinkAPIType
      ['self/image']?: Omit<ImageResponseType, 'name'>
      ['brand/image']?: ImageResponseType[]
    }
  }
  earnings: EarningsType
}

export type CreatorPageAffiliateLinksAnalyticsAPIPayload = {
  content: AffiliateLinkAnalyticsAPI[]
  _links: {
    self: LinkAPIType
    parent: LinkAPIType & { name: string }
    ['parent/image']?: ImageResponseType[]
    next?: LinkAPIType
    prev?: LinkAPIType
  }
}

export type AffiliateLinkAnalytics = {
  id: string
  description: string
  originalURL: string
  commission?: CommissionAggregateType
  affiliateURL?: string
  image?: {
    href: string
    type?: string
    name?: string
  }
  earnings?: EarningsType
  detailsPageURL?: string
}

export type CreatorPageAffiliateLinksAnalytics = {
  results: AffiliateLinkAnalytics[]
  nextAPILink?: string
  prevAPILink?: string
}

export type CreatorPageCommerceSettings = {
  defaultCurrency: CurrencyIdentifierEnum
  convertAffiliateLinks?: boolean
}

export type CreatorPageMessagesType = {
  freeLabel: string
  contactList: {
    placeholder: string
    successMessage: string
    errorMessage: string
    requiredMessage: string
    emailValidationMessage: string
  }
}

export type CreatorPageTheme = {
  customization: CustomizationPropsType
}
