import { api } from '@web-apps/utils-shared'

const validationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateSlug: builder.mutation<void, { slug: string }>({
      query: ({ slug }) => ({
        path: '/validations/slug',
        method: 'POST',
        data: { slug },
      }),
    }),
    validateEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        path: '/validations/email',
        method: 'POST',
        data: { email },
      }),
    }),
  }),
})

export const { useValidateEmailMutation, useValidateSlugMutation } =
  validationsApi
