import { UiNode } from '@ory/client'
import { UiNodeInputAttributes } from '@ory/client/api'
import { UserIdType } from '@web-apps/utils-types'

export type SessionType = {
  active: boolean
  isSeamlessAuth?: boolean
  identity: {
    traits: {
      email: string
    }
    metadata_public: {
      user_id: UserIdType
      roles?: UserRoles[]
    }
  }
}

export enum UserRoles {
  BRAND_MANAGER = 'brand_manager',
}

export enum LoginMethods {
  PASSWORD = 'password',
}

export enum FlowTypes {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  RECOVERY = 'recovery',
  SETTINGS = 'settings',
}

export enum OryFormInputTypes {
  CSRF = 'csrf_token',
  IDENTIFIER = 'identifier',
  TRAITS_EMAIL = 'traits.email',
  EMAIL = 'email',
  PASSWORD = 'password',
  PROVIDER = 'provider',
  METHOD = 'method',
  LINK = 'link',
  UNLINK = 'unlink',
}

export enum ProviderTypes {
  GOOGLE = 'google',
  GITHUB = 'github',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  APPLE = 'apple',
  TIKTOK = 'tiktok',
}

export type NodeInputType = UiNode & {
  attributes: UiNodeInputAttributes & { placeholder?: string }
}

export type LoginRegisterRecoverFieldsFormatTypes = {
  csrfToken: NodeInputType[] | []
  google: NodeInputType[] | []
  github: NodeInputType[] | []
  facebook: NodeInputType[] | []
  twitter: NodeInputType[] | []
  apple: NodeInputType[] | []
  tiktok: NodeInputType[] | []
  email: NodeInputType[] | []
  password: NodeInputType[] | []
  method: NodeInputType[] | []
}

export type SettingsFieldsFormatTypes = {
  csrfToken: NodeInputType[] | []
  google: NodeInputType[] | []
  github: NodeInputType[] | []
  facebook: NodeInputType[] | []
  twitter: NodeInputType[] | []
  apple: NodeInputType[] | []
  tiktok: NodeInputType[] | []
  password: NodeInputType[] | []
  method: NodeInputType[] | []
}
