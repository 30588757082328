import styled from 'styled-components'
import { BREAKPOINTS, media, Theme } from '@web-apps/ui-shared'

export const StyledWrapper = styled.div`
  background: ${Theme.Colors.lightSand};
  padding: 32px 16px;
  height: 100vh;
  overflow: auto;

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 48px;
    `
  )}
`

export const StyledContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`
export const StyledUseFormLink = styled.div<{ $withBoldLink?: boolean }>`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: ${Theme.Colors.typography.light};

  a {
    text-decoration: none;

    ${({ $withBoldLink }) => $withBoldLink && 'font-weight: bold;'}
    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledContent = styled.div`
  background: ${Theme.Colors.background.base};
  border: 1px solid ${Theme.Colors.border.darker};
  border-radius: 8px;
  padding: 24px;
`
