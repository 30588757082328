import pathToRegexp from 'path-to-regexp'

import { Middleware } from 'redux'
import { isRejected } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

/* here we need to add the parameterized paths we have to our API */
export const API_PATHS_TO_CLASSIFY = [
  { parameterizedPath: '/users' },
  { parameterizedPath: '/users/:userId' },
  { parameterizedPath: '/creator-pages/:pageId/preview' },
  { parameterizedPath: '/account/slug' },
  { parameterizedPath: '/users/:slug/creator-page' },
  { parameterizedPath: '/creator-pages/:pageId/analytics' },
  { parameterizedPath: '/creator-pages/:pageId/affiliates/analytics/links' },
  { parameterizedPath: '/creator-pages/:pageId/affiliates/analytics' },
  { parameterizedPath: '/creator-pages/:pageId/sections/:sectionId/images' },
  {
    parameterizedPath:
      '/creator-pages/:pageId/sections/:sectionId/images/:imageId',
  },
  { parameterizedPath: '/users/:userId/creator-page/sections' },
  { parameterizedPath: '/creator-pages/:pageId/sections' },
  { parameterizedPath: '/creator-pages/:pageId/sections/:sectionId/sections' },
  { parameterizedPath: '/creator-pages/:pageId/sections/:sectionId' },
  { parameterizedPath: '/users/:userId/creator-page/sections/order' },
  { parameterizedPath: '/users/:userId/social-links' },
  { parameterizedPath: '/api/coach-profile-infos/update' },
  {
    parameterizedPath: '/users/:userId/creator-page/sections/:sectionId/image',
  },
  { parameterizedPath: '/creator-pages/:pageId/redirect' },
  { parameterizedPath: '/creator-pages/:pageId/analytics/settings' },
  { parameterizedPath: '/creator-pages/:pageId/settings/ecommerce' },
  { parameterizedPath: '/users/:userId/creator-page/styles' },
  { parameterizedPath: '/creator-pages/:pageId/images' },
  { parameterizedPath: '/creator-pages/:pageId/images/:imageId' },
  { parameterizedPath: '/creator-pages/:pageId/settings' },
  { parameterizedPath: '/creator-pages/:pageId/sections/:sectionId/published' },
  { parameterizedPath: '/creator-page-imports' },
  { parameterizedPath: '/creator-pages/:pageId/affiliates/convert' },
  { parameterizedPath: '/affiliate/settings/data-sharing' },
  { parameterizedPath: '/affiliate/settings' },
  { parameterizedPath: '/affiliate/brands/collabs' },
  { parameterizedPath: '/affiliate/brands/trending' },
  { parameterizedPath: '/affiliate/products/trending' },
  { parameterizedPath: '/affiliate/brands' },
  { parameterizedPath: '/creator-pages/:pageId/affiliate-links' },
  { parameterizedPath: '/creator-pages/:pageId/affiliate-links/:linkId/image' },
  { parameterizedPath: '/creator-pages/:pageId/affiliate-links/:linkId' },
  { parameterizedPath: '/affiliates/top-offers' },
  { parameterizedPath: '/affiliates/products/trending' },
  { parameterizedPath: '/affiliates/offers' },
  { parameterizedPath: '/validations/slug' },
  { parameterizedPath: '/validations/email' },
  { parameterizedPath: '/contact-lists' },
  { parameterizedPath: '/contact-lists/:contactListId/signups' },
  { parameterizedPath: '/contact-lists/:contactListId' },
  {
    parameterizedPath: '/affiliates/top-offers',
  },
  {
    parameterizedPath: '/affiliates/offers',
  },
  {
    parameterizedPath: '/affiliates/offers/:offerId/assign-brand',
  },
  {
    parameterizedPath: '/affiliates/offers/:offerId',
  },
  { parameterizedPath: '/affiliates/brands/:brandId' },
  { parameterizedPath: '/affiliates/brands/:brandId/images' },
  { parameterizedPath: '/users/:userId/affiliates/balance/outstanding' },
  { parameterizedPath: '/views/offers/:offerId' },
  { parameterizedPath: '/views/offers' },
]

export const getParameterizedPathForApiPath = (path: string) => {
  for (const pathToClassify of API_PATHS_TO_CLASSIFY) {
    const regex = pathToRegexp(pathToClassify.parameterizedPath)
    let pathname = path
    try {
      const url = new URL(path)
      pathname = url.pathname
    } catch {
      pathname = path
    }

    // the split remove query strings
    if (
      regex.test(
        (pathname.startsWith('/') ? pathname : `/${pathname}`).split('?')[0]
      )
    ) {
      return pathToClassify.parameterizedPath
    }
  }

  return 'unknown'
}

export const reduxUnexpectedErrorsLogger: Middleware =
  () => (next) => (action) => {
    // filtering error by name to not track internal handled and expected error on redux-async-thunk
    // read more about it here: https://stackoverflow.com/a/69789070
    if (isRejected(action) && action.error?.name === 'TypeError') {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          'We got a rejected action!',
          JSON.stringify(action.error, null, 2)
        )
      }

      Sentry.configureScope((scope) =>
        scope
          .setTags({ operations: 'Redux' })
          .setLevel('error')
          .setExtra('error', JSON.stringify(action.error, null, 2))
      )
      Sentry.captureException(action.error)
    }

    return next(action)
  }
