import { CSSProperties, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { TFunction } from 'react-i18next'

import { isCopyClipboardAvailable } from '@web-apps/utils-shared'

import { Box, Flex, Icon } from '../../atoms'
import { useShareLinkData } from '../../helpers'
import {
  shareAffiliateToSocialConfig,
  shareToSocialConfig,
} from './sharePopups.helpers'
import {
  ButtonSize,
  CopyButton,
  TextField,
  TextFieldSize,
} from '../../molecules'
import { Theme } from '../../theme'

import {
  StyledLinkShare,
  StyledLinkShareListItem,
  StyledShareLinkPopover,
} from './ShareLinkPopup.styles'

type ShareLinkProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string
  messages: {
    copyButton: {
      buttonText: string
      copiedTooltipText: string
    }
    t: TFunction
    namespace?: string
  }
  backdropMobileOnly?: boolean
  backdropStyles?: CSSProperties
  enableCloseButtonOnDesktop?: boolean
  isReduced?: boolean
  direction?: 'left' | 'right'
}

export const ShareLinkPopup = ({
  title,
  messages: {
    t,
    namespace: translationsNamespace,
    copyButton: { buttonText: copyButtonText, copiedTooltipText },
  },
  backdropMobileOnly = true,
  enableCloseButtonOnDesktop = false,
  isReduced = false,
  direction = 'right',
  backdropStyles,
  ...props
}: ShareLinkProps) => {
  const shareLinkContext = useShareLinkData()

  const shareButtons = useMemo(
    () =>
      shareLinkContext.url
        ? (isReduced ? shareAffiliateToSocialConfig : shareToSocialConfig)(
            shareLinkContext.copyUrl || shareLinkContext.url,
            t,
            'link',
            translationsNamespace
          )
        : [],
    [
      shareLinkContext.url,
      shareLinkContext.copyUrl,
      t,
      translationsNamespace,
      isReduced,
    ]
  )

  if (!shareLinkContext) return null

  const {
    url,
    copyUrl,
    container = typeof window === 'undefined' ? document.body : null,
    setShareLink,
  } = shareLinkContext

  return container
    ? createPortal(
        <StyledShareLinkPopover
          title={title}
          onClose={() => setShareLink(null)}
          hideHeaderOnDesktop={false}
          backdropMobileOnly={backdropMobileOnly}
          enableCloseButtonOnDesktop={enableCloseButtonOnDesktop}
          $dir={direction}
          backdropStyles={backdropStyles}
          {...props}
        >
          <Box mb={24}>
            <TextField
              name="url"
              leftDecorator={<Icon.Link />}
              readOnly
              value={copyUrl || url}
              fieldSize={TextFieldSize.REGULAR}
              rightDecorator={
                <StyledLinkShare>
                  <a href={url} target="_blank" rel="noreferrer">
                    <Box h={42} w={54} pr={12} pt={15} pl={28}>
                      <Icon.LinkExternal
                        width={14}
                        height={14}
                        fillColor={Theme.Button.backgroundColor}
                      />
                    </Box>
                  </a>
                  {url && isCopyClipboardAvailable() ? (
                    <CopyButton
                      value={copyUrl || url}
                      buttonSize={ButtonSize.SLIM}
                      messages={{
                        buttonText: copyButtonText,
                        copiedTooltipText,
                      }}
                    />
                  ) : null}
                </StyledLinkShare>
              }
            />
          </Box>
          {shareButtons.map(
            ({
              ShareComponent,
              shareComponentsProps,
              translation,
              IconComponent,
            }) => (
              <StyledLinkShareListItem as="div" key={translation}>
                <ShareComponent {...shareComponentsProps}>
                  <Flex justify="space-between">
                    <Flex>
                      <Box mr={16}>
                        <IconComponent />
                      </Box>
                      {translation}
                    </Flex>
                    <Icon.LinkExternal size={18} />
                  </Flex>
                </ShareComponent>
              </StyledLinkShareListItem>
            )
          )}
        </StyledShareLinkPopover>,
        container
      )
    : null
}
