import styled, { css, keyframes } from 'styled-components'
import { CSSProperties } from 'react'

import { Theme } from '../../theme'
import { BREAKPOINTS, media } from '../../helpers'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`

const buttonLoadingSpinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`

export const StyledBackdrop = styled.div<{
  $zIndex: CSSProperties['zIndex']
  $position: CSSProperties['position']
  $isMobileOnly: boolean
  $isLoading: boolean
}>`
  ${({ $zIndex }) => `z-index: ${$zIndex}`};
  position: ${({ $position }) => $position};
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  background: #303030;
  animation: ${fadeIn} 0.75s ease forwards;

  ${({ $isMobileOnly }) =>
    $isMobileOnly && media.from(BREAKPOINTS.sm, `display:none;`)};

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      > div {
        visibility: hidden;
        opacity: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: ${Theme.Colors.background.base};
        border-radius: 50%;
        animation: ${buttonLoadingSpinner} 1s ease infinite;
      }
    `}
`
