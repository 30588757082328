import React, { useEffect, useRef } from 'react'

export type ClickOutsideProps = {
  handleClickOutside: (e: Event) => void
  handleClick?: () => void
  ignoreElementRef?: React.RefObject<HTMLDivElement>
  children: React.ReactNode
}

export const ClickOutside = ({
  handleClickOutside,
  handleClick,
  ignoreElementRef,
  children,
}: ClickOutsideProps) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Escape') handleClickOutside(event)
      handleEvent(event)
    }

    const handleEvent = (e: Event) => {
      const target = e.target as Node
      if (container.current?.contains(target)) return

      if (ignoreElementRef?.current?.contains(target)) return

      handleClickOutside(e)
    }

    document.addEventListener('mousedown', handleEvent, false)
    document.addEventListener('touchend', handleEvent, false)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('mousedown', handleEvent, false)
      document.removeEventListener('touchend', handleEvent, false)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [handleClickOutside, ignoreElementRef])

  return (
    <div ref={container} onClick={handleClick}>
      {children}
    </div>
  )
}
