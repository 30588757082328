import { useDebouncedCallback } from 'use-debounce'
import {
  useEffect,
  forwardRef,
  HTMLAttributes,
  CSSProperties,
  useState,
} from 'react'

import { BREAKPOINTS_SIZES } from '../../helpers'
import { StyledBackdrop } from './Backdrop.styles'

type BackDropProps = HTMLAttributes<HTMLDivElement> & {
  mobileOnly?: boolean
  isLoading?: boolean
  position?: CSSProperties['position']
  zIndex?: CSSProperties['zIndex']
  delayMs?: number
}

export const Backdrop = forwardRef<HTMLDivElement, BackDropProps>(
  (
    {
      mobileOnly = false,
      isLoading = false,
      position = 'fixed',
      zIndex = 5,
      delayMs = 0,
      ...divProps
    },
    ref
  ) => {
    const [hide, setHide] = useState(delayMs > 0)

    const startLoading = useDebouncedCallback(
      () => setHide(false),
      delayMs > 0 ? delayMs : 0
    )

    useEffect(() => {
      if (!hide) return

      startLoading()
    }, [startLoading, hide])

    useEffect(() => {
      const bodyOverflowYOnMount = document.body.style.overflowY

      if (mobileOnly) {
        if (window.screen.width < BREAKPOINTS_SIZES.from.sm) {
          document.body.style.overflowY = 'hidden'
        }
      } else {
        document.body.style.overflowY = 'hidden'
      }

      return () => {
        document.body.style.overflowY = bodyOverflowYOnMount
      }
    }, [mobileOnly])

    if (hide) return null

    return (
      <StyledBackdrop
        $position={position}
        $zIndex={zIndex}
        $isMobileOnly={mobileOnly}
        ref={ref}
        $isLoading={isLoading}
        {...divProps}
      />
    )
  }
)
