import { useState, forwardRef, useEffect } from 'react'
import Dropzone, { DropzoneOptions, FileError } from 'react-dropzone'

import {
  imageUrlBuilder,
  emptyFunctionForOptionals,
} from '@web-apps/utils-shared'
import { ImageFormType, UploadedImageType } from '@web-apps/utils-types'
import {
  StyledLabelContainer,
  StyledAddIcon,
  StyledDropZoneContainer,
  StyledImageUploaderContainer,
  StyledImageUploaderMessageContainer,
  StyledImageUploaderWrapper,
  StyledInfoMessage,
  StyledRemoveIcon,
  StyledUploadedImagesContainer,
  StyledUplodedImage,
  StyledUploadControl,
} from './ImageUploader.styles'
import { StyledLabelText } from '../../styles'

import { Icon, LoadingSpinner } from '../../atoms'
import { Dialog } from '../../molecules'
import { ImageCropper } from '../../molecules/ImageCropper'

import { Theme } from '../../theme'

type ImageUploaderProps = {
  inputName: string
  image?: ImageFormType
  uploadControlText: string
  label?: string
  infoMessage?: string
  onClear?: () => void
  onImageChange?: (imageData: ImageFormType) => void
  acceptedExtensions?: string[]
  maxSizeInBytes?: number
  onFileRejected?: (error: FileError) => void
  onError?: React.ImgHTMLAttributes<HTMLImageElement>['onError']
  isInvalid?: boolean
  isLoading?: boolean
  cropperProps?: {
    dialogTitle: string
    discardButtonText: string
    cropButtonText: string
    forceSquare?: boolean
  }
}

export const ImageUploader = forwardRef<HTMLImageElement, ImageUploaderProps>(
  (
    {
      inputName,
      label,
      uploadControlText,
      infoMessage,
      image,
      onClear,
      onError = emptyFunctionForOptionals,
      onImageChange,
      acceptedExtensions = ['.png', '.jpg', '.jpeg', '.gif'],
      maxSizeInBytes,
      onFileRejected,
      isInvalid = false,
      isLoading = false,
      cropperProps,
    },
    ref
  ) => {
    const [imageToCrop, setImageToCrop] = useState<UploadedImageType>()
    const [errorLoadingImage, setErrorLoadingImage] = useState(false)

    const handleUploadImages: DropzoneOptions['onDrop'] = (
      acceptedFiles = [],
      fileRejections = []
    ) => {
      if (fileRejections.length > 0 && onFileRejected)
        return onFileRejected(fileRejections[0].errors[0])

      const reader = new FileReader()

      reader.onloadend = function () {
        if (typeof reader.result === 'string') {
          if (!cropperProps && onImageChange)
            onImageChange({
              href: reader.result,
              imageFile: acceptedFiles[0],
            })
          setImageToCrop({
            imageUrl: reader.result,
            imageFile: acceptedFiles[0],
          })
        }
      }

      reader.readAsDataURL(acceptedFiles[0])
    }

    const completeCropImage = ({ imageFile, imageUrl }: UploadedImageType) => {
      if (onImageChange) onImageChange({ imageFile, href: imageUrl })
      setImageToCrop(undefined)
    }

    useEffect(() => {
      setErrorLoadingImage(false)
    }, [image?.href])

    return (
      <StyledLabelContainer htmlFor={inputName}>
        {label && <StyledLabelText>{label}</StyledLabelText>}

        <Dropzone
          disabled={isLoading}
          accept={acceptedExtensions.join(', ')}
          maxSize={maxSizeInBytes}
          onDrop={handleUploadImages}
        >
          {({ getRootProps, getInputProps }) => (
            <StyledImageUploaderWrapper
              $hasError={isInvalid}
              {...getRootProps()}
            >
              <input name={inputName} {...getInputProps()} />

              <StyledUploadedImagesContainer>
                <StyledImageUploaderContainer>
                  {isLoading ? (
                    <LoadingSpinner
                      size={48}
                      color={Theme.Colors.typography.regular}
                    />
                  ) : image?.href && !errorLoadingImage ? (
                    <>
                      <StyledRemoveIcon
                        title="X"
                        icon="Remove"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()

                          if (onClear) onClear()
                        }}
                      />
                      <StyledUplodedImage
                        ref={ref}
                        src={imageUrlBuilder({ image, width: 160 })}
                        alt="image_for_upload"
                        onError={(e) => {
                          setErrorLoadingImage(true)

                          onError(e)
                        }}
                      />
                    </>
                  ) : (
                    <StyledDropZoneContainer>
                      <Icon.UploadImage width={24} height={24} />
                      <StyledAddIcon title="+" icon="Add" />
                    </StyledDropZoneContainer>
                  )}
                </StyledImageUploaderContainer>
              </StyledUploadedImagesContainer>

              <StyledImageUploaderMessageContainer>
                <StyledUploadControl type="button">
                  <Icon.UploadVideo />
                  {uploadControlText && <span>{uploadControlText}</span>}
                </StyledUploadControl>
                {infoMessage && (
                  <StyledInfoMessage $errored={isInvalid}>
                    {infoMessage}
                  </StyledInfoMessage>
                )}
              </StyledImageUploaderMessageContainer>
            </StyledImageUploaderWrapper>
          )}
        </Dropzone>

        {cropperProps && imageToCrop && (
          <Dialog
            isOpen
            title={cropperProps.dialogTitle}
            onDismiss={() => setImageToCrop(undefined)}
          >
            <ImageCropper
              imageData={imageToCrop}
              forceSquare={cropperProps.forceSquare || false}
              onCropComplete={completeCropImage}
              onDiscard={() => setImageToCrop(undefined)}
              discardButtonText={cropperProps.discardButtonText}
              cropButtonText={cropperProps.cropButtonText}
            />
          </Dialog>
        )}
      </StyledLabelContainer>
    )
  }
)
