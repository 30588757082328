import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import { api, reduxUnexpectedErrorsLogger } from '@web-apps/utils-shared'

export const makeStore = () =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
    },
    middleware: (gDM) =>
      gDM().concat(api.middleware, reduxUnexpectedErrorsLogger),
  })

export type AppStore = ReturnType<typeof makeStore>

export const wrapper = createWrapper<AppStore>(makeStore, {
  debug: process.env.NODE_ENV === 'development',
})
