export const isSafariIOS = (): boolean => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)

  return iOS && webkit && !ua.match(/CriOS/i)
}

export const isAppleDevice = () =>
  /iPad/i.test(navigator.userAgent) || /iPhone/i.test(navigator.userAgent)

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  )

export const isInAppBrowser = (): boolean => {
  const ua = window.navigator.userAgent
  const rules = ['WebView', '(iPhone|iPod|iPad|Instagram|FB_IAB)(?!.*Safari/)']
  const regex = new RegExp(`(${rules.join('|')})`, 'ig')

  return Boolean(ua.match(regex))
}

export const isInInstagramIosBrowser = () => {
  const userAgent = window.navigator.userAgent
  const isInstagram = !!userAgent.match(/instagram/i)
  const isIos = !!userAgent.match(/(iPad|iPhone|iPod)/g)

  return isInstagram && isIos
}

export const isInAndroid = () =>
  typeof window !== 'undefined' && navigator
    ? /Android/i.test(navigator.userAgent)
    : false
