import { ProfileImageShape } from '@web-apps/utils-types'
import { IconCatalogPaths } from '../Icon'

import { StyledAvatar, StyledAvatarWrapper } from './Avatar.styles'

type AvatarProps = {
  url?: string
  name?: string
  size?: number
  shape?: ProfileImageShape
}

export const Avatar = ({
  url,
  name,
  size = 96,
  shape = ProfileImageShape.CIRCLE,
}: AvatarProps) => {
  if (shape === ProfileImageShape.DIAMOND)
    return (
      <StyledAvatarWrapper $size={size}>
        <StyledAvatar
          data-cy="avatar"
          data-testid="avatar"
          $url={url || IconCatalogPaths.ImageProfilePlaceholder}
          title={name}
          $size={size}
          $shape={shape}
        />
      </StyledAvatarWrapper>
    )

  return (
    <StyledAvatar
      data-cy="avatar"
      data-testid="avatar"
      $url={url || IconCatalogPaths.ImageProfilePlaceholder}
      title={name}
      $size={size}
      $shape={shape}
    />
  )
}
