import styled from 'styled-components'

export const PlainButton = styled.button`
  border: none;
  background: inherit;
  padding: 0;
  outline: none;
`

export const StyledIconPlainButton = styled(PlainButton)`
  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: revert;
  }
`
