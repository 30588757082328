import styled from 'styled-components'
import { Flex, Typography } from '../../atoms'
import { Theme } from '../../theme'

export type TitledCardFooterProps = {
  title: string | JSX.Element
  description?: string | JSX.Element
}

const StyledDescription = styled.div`
  width: fit-content;
  margin-top: 4px;
  padding: 8px;
  background: ${Theme.Colors.background.alternate};
  border-radius: 8px;
`

export const TitledCardFooter = ({
  title,
  description,
}: TitledCardFooterProps) => (
  <Flex direction="column" gap={4}>
    {typeof title === 'string' ? (
      <Typography variant="hint" role="heading">
        {title}
      </Typography>
    ) : (
      title
    )}

    {typeof description === 'string' ? (
      <StyledDescription>
        <Typography variant="hint" fontWeight={600}>
          {description}
        </Typography>
      </StyledDescription>
    ) : (
      description
    )}
  </Flex>
)
