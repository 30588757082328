import { CSSProperties, HTMLProps, ReactNode } from 'react'

import styled, { AnyStyledComponent } from 'styled-components'

import { Theme } from '../../theme'

type TypographyColors = typeof Theme.Colors.typography
type TypographyColorsKeys = keyof TypographyColors
type TypographyVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body'
  | 'hint'
  | 'navigation'
  | 'note'
  | 'subTitleBig'
  | 'subTitleBold'
  | 'subTitle'
  | 'extraSmall'

export type TypographyProps = HTMLProps<HTMLElement> & {
  // We need to make the children prop not required in order to be able to use it in <Trans /> components
  children?: string | ReactNode | ReactNode[]
  as?: AnyStyledComponent['as']
  variant?: TypographyVariants
  color?: TypographyColorsKeys
  fontStyle?: CSSProperties['fontStyle']
  fontSize?: CSSProperties['fontSize']
  fontWeight?: CSSProperties['fontWeight']
  textAlign?: CSSProperties['textAlign']
  lineHeight?: CSSProperties['lineHeight']
  textTransform?: CSSProperties['textTransform']
  textDecoration?: CSSProperties['textDecoration']
}

const variantToHtmlTag: { [key in TypographyVariants]: string } = {
  body: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  hint: 'p',
  navigation: 'span', // TODO: universal component variants should not be tight to a context, therefore we need to find and replace option navigation to a universal one
  note: 'p',
  subTitleBig: 'p',
  subTitleBold: 'p',
  subTitle: 'p',
  extraSmall: 'span',
}

const variantToFontStyles: {
  [key in TypographyVariants]: {
    fontSize: string
    fontWeight: string
    lineHeight: string
    textTransform?: string
  }
} = {
  body: { fontSize: '16px', fontWeight: '400', lineHeight: '24px' },
  h1: { fontSize: '32px', fontWeight: '700', lineHeight: '48px' },
  h2: { fontSize: '24px', fontWeight: '700', lineHeight: '32px' },
  h3: { fontSize: '20px', fontWeight: '600', lineHeight: '24px' },
  h4: { fontSize: '16px', fontWeight: '600', lineHeight: '24px' },
  hint: { fontSize: '12px', fontWeight: '400', lineHeight: '16px' },
  navigation: { fontSize: '14px', fontWeight: '400', lineHeight: '24px' },
  note: { fontSize: '14px', fontWeight: '400', lineHeight: '24px' },
  subTitleBig: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  subTitleBold: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16px',
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  extraSmall: { fontSize: '10px', fontWeight: '400', lineHeight: '12px' },
}

const StyledTypography = styled.p<{
  $variant: TypographyVariants
  $color: TypographyColorsKeys
  $fontSize: CSSProperties['fontSize']
  $fontWeight: CSSProperties['fontWeight']
  $fontStyle: CSSProperties['fontStyle']
  $textAlign: CSSProperties['fontStyle']
  $textTransform: CSSProperties['textTransform']
  $lineHeight: CSSProperties['lineHeight']
  $textDecoration: CSSProperties['textDecoration']
}>`
  ${({ $color }) => {
    return `color: ${Theme.Colors.typography[$color]};`
  }}

  ${({ $variant }) => {
    return `
      font-size: ${variantToFontStyles[$variant].fontSize};
      font-weight: ${variantToFontStyles[$variant].fontWeight};
      line-height: ${variantToFontStyles[$variant].lineHeight};
      ${
        variantToFontStyles[$variant].textTransform
          ? `text-transform: ${variantToFontStyles[$variant].textTransform};`
          : ''
      }
    `
  }}

  ${({ $fontSize }) => {
    return $fontSize ? `font-size: ${$fontSize};` : ''
  }}

  ${({ $fontWeight }) => {
    return $fontWeight ? `font-weight: ${$fontWeight};` : ''
  }}

  ${({ $fontStyle }) => {
    return $fontStyle ? `font-style: ${$fontStyle};` : ''
  }}

  ${({ $textAlign }) => {
    return $textAlign ? `text-align: ${$textAlign};` : ''
  }}

  ${({ $textTransform }) => {
    return $textTransform ? `text-transform: ${$textTransform};` : ''
  }}

  ${({ $lineHeight }) => {
    return $lineHeight ? `line-height: ${$lineHeight};` : ''
  }}

  ${({ $textDecoration }) => {
    return $textDecoration ? `text-decoration: ${$textDecoration};` : ''
  }}
`

export const Typography = ({
  children,
  as: asComponent,
  color = 'regular',
  variant = 'body',
  fontStyle,
  fontSize,
  fontWeight,
  textAlign,
  textTransform,
  lineHeight,
  textDecoration,
  ...htmlProps
}: TypographyProps) => {
  return (
    <StyledTypography
      as={asComponent || variantToHtmlTag[variant]}
      $variant={variant}
      $color={color}
      $fontSize={fontSize}
      $fontStyle={fontStyle}
      $fontWeight={fontWeight}
      $textAlign={textAlign}
      $textTransform={textTransform}
      $lineHeight={lineHeight}
      $textDecoration={textDecoration}
      {...htmlProps}
    >
      {children}
    </StyledTypography>
  )
}
