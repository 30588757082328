import { withHttps } from '../common'
import { FormValidations } from '../formValidation'

import {
  embedProviders,
  EmbedProvidersCategory,
  EmbedProvidersEnum,
  MUSIC_PROVIDERS,
  VIDEO_PROVIDERS,
} from './providers'

const embedLinksCache = new Map<string, { title: string; url: string }>()

export const isUrlSupported = (
  url: string,
  validProviders?: EmbedProvidersEnum[]
) => {
  if (!FormValidations.isValidWebURL(url)) return false

  const providers = validProviders
    ? Object.values(embedProviders).filter(({ id }) =>
        validProviders.includes(id)
      )
    : Object.values(embedProviders)

  const match = providers
    .flatMap((provider) => provider.matches)
    .find((item) => item.pattern.test(url))

  return match && (match.fallback || true)
}

export const getEmbedProvider = (url: string) => {
  return Object.values(embedProviders).find((provider) =>
    provider.matches.some(
      ({ pattern, fallback }) => pattern.test(url) && !fallback
    )
  )
}

export const getEmbedUrlDetails = async (
  url: string
): Promise<{ title: string; url: string } | null> => {
  if (embedLinksCache.has(url)) {
    return embedLinksCache.get(url) as { title: string; url: string }
  }

  const provider = getEmbedProvider(url)

  if (!provider) {
    return null
  }

  const result = await provider.getUrlDetails(withHttps(url))
  embedLinksCache.set(url, result)

  return result
}

export const getEmbedCategoryTitle = (
  url: string
): { type: EmbedProvidersCategory; name?: string } => {
  const provider = getEmbedProvider(url)
  let type = EmbedProvidersCategory.LINK

  if (!provider) {
    return { type }
  }

  if (MUSIC_PROVIDERS.includes(provider.id)) {
    type = EmbedProvidersCategory.MUSIC
  } else if (VIDEO_PROVIDERS.includes(provider.id)) {
    type = EmbedProvidersCategory.VIDEO
  }

  return {
    name: provider.name,
    type,
  }
}
