import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { UpdateSettingsFlowBody } from '@ory/client'

import {
  Button,
  Icon,
  TextField,
  Typography,
  IconAssetsKeys,
  Flex,
  Form,
  ButtonSize,
} from '@web-apps/ui-shared'

import { NodeInputType, OryFormInputTypes } from '../../utils/types'
import { AuthProviderName } from '../../utils/constants'

type ConnectSocialOptionProps = {
  providerName: AuthProviderName
  onSubmit: (values: UpdateSettingsFlowBody) => void
  oryInputs: NodeInputType[]
  isSubmitting?: boolean
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
`

const StyledSocialInfoContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
`

const ICON_FOR_PROVIDER_NAME: { [key in AuthProviderName]: IconAssetsKeys } = {
  [AuthProviderName.GOOGLE]: 'Google',
  [AuthProviderName.APPLE]: 'Apple',
  [AuthProviderName.GITHUB]: 'GitHub',
  [AuthProviderName.TIKTOK]: 'TikTok',
  [AuthProviderName.TWITTER]: 'Twitter',
  [AuthProviderName.FACEBOOK]: 'Facebook',
}

export const ConnectSocialOption = ({
  providerName,
  onSubmit,
  oryInputs,
  isSubmitting = false,
}: ConnectSocialOptionProps) => {
  const { t } = useTranslation(['auth'])

  const iconAsset = ICON_FOR_PROVIDER_NAME[providerName]
  const IconComponent = Icon[iconAsset]

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <>
          {oryInputs.map(({ attributes: { name, value } }) => (
            <div key={name}>
              <TextField name={name} type="hidden" value={value} />
              {name === OryFormInputTypes.UNLINK ? (
                <StyledContainer>
                  <StyledSocialInfoContainer>
                    <IconComponent />
                    <Typography fontWeight="bold">{providerName}</Typography>
                  </StyledSocialInfoContainer>
                  <Button
                    inverse
                    size={ButtonSize.SMALL}
                    isLoading={isSubmitting}
                  >
                    {t('auth:settings.unlink_provider')}
                  </Button>
                </StyledContainer>
              ) : (
                <Button width="100%" inverse isLoading={isSubmitting}>
                  <Flex justify="center" gap="12">
                    <IconComponent />
                    {t('auth:settings.link_provider', {
                      provider: providerName,
                    })}
                  </Flex>
                </Button>
              )}
            </div>
          ))}
        </>
      )}
    </Form>
  )
}
