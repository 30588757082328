import { HTMLProps } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

import { Theme } from '../../theme'

type StyledChipProps = {
  $isActive: boolean
}

export type ChipProps = HTMLProps<HTMLElement> & {
  isActive?: boolean
  as?: AnyStyledComponent['as']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & { [propKey: string]: any }

const StyledChip = styled.a<StyledChipProps>`
  padding: 8px 16px;
  border: 1px solid ${Theme.Chip.borderColor};
  border-radius: 8px;
  color: inherit;
  background-color: inherit;
  cursor: pointer;

  font-weight: 400;

  &:hover {
    transform: scale(1.05);
  }

  ${({ $isActive }) =>
    $isActive &&
    `
    font-weight: 600;
    border: 1px solid ${Theme.Chip.activeBorderColor};
    color: ${Theme.Chip.activeBorderColor};
    background-color: ${Theme.Chip.activeBackgroundColor};
  `}
`

export const Chip: React.FC<ChipProps> = ({
  as = 'a',
  isActive = false,
  children,
  ...remainingProps
}) => {
  return (
    <StyledChip as={as} $isActive={isActive} {...remainingProps}>
      {children}
    </StyledChip>
  )
}
