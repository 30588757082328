import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'

import { CurrencyIdentifierEnum, PriceType } from '@web-apps/utils-types'

import { Localisation } from '../localisation'
import { OVERRIDE_LOCALE_COOKIE } from '../localisation/localisation.constants'

type FormatOptions = {
  freeSymbol?: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  maximumSignificantDigits?: number
  minimumSignificantDigits?: number
  locale?: string
}

const computeLocaleForNumberFormat = (
  options?: Pick<FormatOptions, 'locale'>
) =>
  options?.locale ||
  (typeof window !== 'undefined' &&
    localStorage.getItem(OVERRIDE_LOCALE_COOKIE)) ||
  Localisation.DEVICE_LOCALE

const CURRENCY_SYMBOL_FOR_IDENTIFIER: {
  [currencyIdentifier: string]: string
} = {
  EUR: '€',
  USD: '$',
  CAD: 'C$',
  GBP: '£',
}

const DEFAULT_CURRENCY = CurrencyIdentifierEnum.EUR.toString()

const PRICE_VALUE_REGEX = /^[0-9]+[.]{0,1}[0-9]*$/
const PRICE_DECIMALS_ALLOWED = 2

/* Currently this is the max decimals allowed as mentioned in the documentation:
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#maximumfractiondigits

    Using it for commissions allow us to show any number of decimals (up to 20) supplied by brands, even 0.0000001
*/
const MAX_COMMISSION_DECIMALS_ALLOWED = 20

export const isPriceValueValidForTextField = (value?: string): boolean =>
  !!value && PRICE_VALUE_REGEX.test(value)

export const formatPriceAmountForTextField = (
  value?: string | number
): string => {
  const priceValue = parseFloat(`${value}`)

  return !isNaN(priceValue)
    ? `${priceValue.toFixed(
        Number.isInteger(priceValue) ? 0 : PRICE_DECIMALS_ALLOWED
      )}`
    : ''
}

export const formatPrice = (price: PriceType, options?: FormatOptions) => {
  if (isFree(price) && options?.freeSymbol) return options?.freeSymbol

  const minimumFractionDigits =
    typeof options?.minimumFractionDigits === 'number'
      ? options.minimumFractionDigits
      : PRICE_DECIMALS_ALLOWED
  const maximumFractionDigits =
    typeof options?.maximumFractionDigits === 'number'
      ? options.maximumFractionDigits
      : PRICE_DECIMALS_ALLOWED

  const formatter = Intl.NumberFormat(computeLocaleForNumberFormat(options), {
    style: 'currency',
    currencyDisplay:
      price.currency === CurrencyIdentifierEnum.USD ? 'narrowSymbol' : 'symbol',
    currency: price.currency,
    ...(Number.isInteger(price.amount)
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : {
          minimumFractionDigits,
          maximumFractionDigits,
          minimumSignificantDigits: options?.minimumSignificantDigits,
          maximumSignificantDigits: options?.maximumSignificantDigits,
        }),
  })

  return formatter.format(price.amount)
}

export const formatPriceWithoutRounding = (
  price: PriceType,
  options?: FormatOptions
) =>
  formatPrice(price, {
    minimumFractionDigits: 0,
    maximumFractionDigits: MAX_COMMISSION_DECIMALS_ALLOWED,
    ...options,
  })

export const getCurrencySymbol = (currency: string) => {
  if (CURRENCY_SYMBOL_FOR_IDENTIFIER[currency])
    return CURRENCY_SYMBOL_FOR_IDENTIFIER[currency]

  return (0)
    .toLocaleString(Localisation.DEVICE_LOCALE, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const isFree = (price: PriceType): boolean => price.amount === 0

export const formatCurrencyOptionText = (currency: string): string => {
  const symbol = getCurrencySymbol(currency)

  return symbol && symbol !== currency ? `${currency} - ${symbol}` : currency
}

export const getCurrency = ({
  price,
  userPreference,
}: {
  price?: PriceType
  userPreference?: string
}): string => price?.currency || userPreference || DEFAULT_CURRENCY

// to avoid weird issues with javascript summing float values. Explanation here: https://stackoverflow.com/questions/588004/is-floating-point-math-broken/51723472#51723472
export const sumFloats = (amountOne: number, amountTwo: number) =>
  parseFloat((amountOne + amountTwo).toFixed(10))

export const mathOperationFloats = (
  valueOne: number,
  valueTwo: number,
  operation: 'sum' | 'subtract' | 'multiplication' | 'division'
) => {
  switch (operation) {
    case 'sum':
      return parseFloat((valueOne + valueTwo).toFixed(10))
    case 'subtract':
      return parseFloat((valueOne - valueTwo).toFixed(10))
    case 'multiplication':
      return parseFloat((valueOne * valueTwo).toFixed(10))
    case 'division':
      return parseFloat((valueOne / valueTwo).toFixed(10))
  }
}

type FormatCommissionFunctionType = (
  commission:
    | { type: 'money'; value: PriceType }
    | { type: 'percent'; value: number },
  options?: Pick<FormatOptions, 'locale'>
) => string

export const formatCommission: FormatCommissionFunctionType = (
  { type, value },
  options?: Pick<FormatOptions, 'locale'>
): string => {
  if (type === 'percent') {
    return new Intl.NumberFormat(computeLocaleForNumberFormat(options), {
      style: 'percent',
      /* requirements explained here: https://gitlab.com/zezam/frontend/web-apps/-/issues/986 */
      maximumSignificantDigits: value < 0.1 ? 1 : 2,
    }).format(value)
  }

  // Small amount of money should have a zero at the end of fraction part
  /* requirements explained here: https://gitlab.com/zezam/frontend/web-apps/-/issues/986 */
  if (
    value.amount > 1 &&
    value.amount < 10 &&
    value.amount !== Math.round(value.amount)
  ) {
    const roundedAmount =
      Math.round(
        parseFloat(value.amount.toFixed(PRICE_DECIMALS_ALLOWED)) * 10
      ) / 10

    return formatPrice(
      { amount: roundedAmount, currency: value.currency },
      options
    )
  } else {
    return formatPrice(value, {
      /* requirements explained here: https://gitlab.com/zezam/frontend/web-apps/-/merge_requests/1320#note_1300169594 */
      minimumSignificantDigits: value.amount < 0.1 ? undefined : 2,
      /* requirements explained here: https://gitlab.com/zezam/frontend/web-apps/-/issues/986 */
      maximumSignificantDigits: value.amount < 0.1 ? 1 : 2,
      ...options,
    })
  }
}

export const formatCommissionWithoutRounding: FormatCommissionFunctionType = ({
  type,
  value,
}): string => {
  if (type === 'percent') {
    return new Intl.NumberFormat(computeLocaleForNumberFormat(), {
      style: 'percent',
    }).format(value)
  }

  return Intl.NumberFormat(computeLocaleForNumberFormat(), {
    style: 'currency',
    currencyDisplay:
      value.currency === CurrencyIdentifierEnum.USD ? 'narrowSymbol' : 'symbol',
    currency: value.currency,
  }).format(value.amount)
}

export const MoneyHelpers = {
  isPriceValueValidForTextField,
  formatPriceAmountForTextField,
  isFree,
  currencySymbols: CURRENCY_SYMBOL_FOR_IDENTIFIER,
  defaultCurrency: DEFAULT_CURRENCY,
  getCurrency,
  getCurrencySymbol,
  formatCurrencyOptionText,
  formatPriceWithoutRounding,
  formatPrice,
  formatCommission,
  formatCommissionWithoutRounding,
  mathOperationFloats,
}
