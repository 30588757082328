import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { DropdownDirectionEnum } from './Dropdown'
import { Theme } from '../../theme'

export const StyledContainer = styled.div`
  position: relative;
`
export const StyledToggle = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  line-height: 24px;
  font-weight: 700;
`

export const StyledList = styled.div<{ $direction: DropdownDirectionEnum }>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 0;
  min-width: 100px;
  ${({ $direction }) => {
    return $direction === DropdownDirectionEnum.DOWN
      ? 'top: 100%'
      : 'bottom: calc(100% + 8px)'
  }}
`

export const StyledLinkItem = styled(NavLink)`
  padding: 8px;
  display: flex;

  &:hover {
    background-color: ${Theme.Colors.lightSand};
  }

  &.active {
    font-weight: 600;
  }
`

export const StyledButtonItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${Theme.Colors.lightSand};
  }
`
