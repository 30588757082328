export type HostsList = 'zezam.xyz' | 'zez.am'
export const PRODUCTION_HOST: HostsList = 'zez.am'
export const DEVELOPMENT_HOST: HostsList = 'zezam.xyz'

type ClientConfigs = {
  IFRAMELY_KEY: string
  CHARGEBEE_SITE: string
  AUTH_URL: string
  FACEBOOK_APP_ID?: string
  HOSTNAME: HostsList
}

export let clientConfig: ClientConfigs | undefined

export const getHostForEnvironment = (env: string): HostsList =>
  env === 'production' ? 'zez.am' : 'zezam.xyz'

export function initConfig(configs: ClientConfigs) {
  if (!clientConfig) {
    clientConfig = {
      ...configs,
    }
  }

  return clientConfig
}

type UrlConfigType = {
  [domain in HostsList]: {
    [entity in string]: string
  }
}

export const urlConfig: UrlConfigType = {
  'zezam.xyz': {
    publicAppDomain: 'zezam.xyz',
    creatorAppDomain: 'my.zezam.xyz',
  },
  'zez.am': {
    publicAppDomain: 'zez.am',
    creatorAppDomain: 'my.zez.am',
  },
}

export const urlAuthConfig: UrlConfigType = {
  'zezam.xyz': {
    login: 'https://my.zezam.xyz/login',
    register: 'https://my.zezam.xyz/register',
    logout: 'https://my.zezam.xyz/logout',
  },
  'zez.am': {
    login: 'https://my.zez.am/login',
    register: 'https://my.zez.am/register',
    logout: 'https://my.zez.am/logout',
  },
}
