import { clientConfig, PRODUCTION_HOST, urlConfig } from '../config'

type CreatorUrlBuilderProps = {
  creatorSlug: string | number
  useRelativePath?: boolean
  overrideHostOrigin?: string
}

export const calculatePlatformRootURL = (options?: {
  overrideHostOrigin?: string
}) => {
  if (options?.overrideHostOrigin) return options.overrideHostOrigin

  const host = clientConfig?.HOSTNAME || PRODUCTION_HOST
  return `https://${urlConfig[host].publicAppDomain}`
}

export const calculateCreatorPlatformRootURL = () => {
  const host = clientConfig?.HOSTNAME || PRODUCTION_HOST
  return `https://${urlConfig[host].creatorAppDomain}`
}

export const creatorUrlBuilder = ({
  creatorSlug,
  useRelativePath = false,
  overrideHostOrigin = '',
}: CreatorUrlBuilderProps): string =>
  `${
    useRelativePath ? '' : calculatePlatformRootURL({ overrideHostOrigin })
  }/${creatorSlug}`

export const registerPageURLBuilder = ({
  slug,
  partner,
  email,
}: {
  slug?: string
  email?: string
  partner?: string
  useRelativePath?: boolean
}): string => {
  const baseUrl = `${calculateCreatorPlatformRootURL()}/register`

  const urlParams = new URLSearchParams()
  if (slug) urlParams.append('slug', slug)
  if (partner) urlParams.append('partner', partner)
  if (email) urlParams.append('email', email)

  const queryStringParams = urlParams.toString()

  return `${baseUrl}${queryStringParams ? `?${queryStringParams}` : ''}`
}

export const addParametersToUrl = ({
  originalUrl,
  params,
}: {
  originalUrl: string
  params: { [key in string]: string }
}): string => {
  const originalUrlParams = originalUrl.split('?')[1]
  const url = originalUrl.split('?')[0]
  let newUrlParams

  if (originalUrlParams) {
    const oldParams = originalUrlParams.split('&').reduce((acc, param) => {
      const [key, value] = param.split('=')
      return { ...acc, [key]: value }
    }, {})

    newUrlParams = new URLSearchParams({ ...oldParams, ...params })
    return `${url}?${newUrlParams.toString()}`
  }

  newUrlParams = new URLSearchParams(params)
  return `${url}?${newUrlParams.toString()}`
}

export const generatePublicCreatorHandle = ({ slug }: { slug: string }) =>
  `zez.am/${slug}`

export const generateAffiliateLinkForInAppBrowserOnAndroid = (
  affiliateLink: string
) => `intent:http://sinsay-pl.mtpc.se/3882422#Intent;end`

export const generateUtmShareURL = ({
  originalURL,
}: {
  originalURL: string
}): string => {
  try {
    const shareUrl = new URL(originalURL)
    if (shareUrl.searchParams.has('utm_source')) {
      shareUrl.searchParams.delete('utm_source')
    }
    shareUrl.searchParams.append('utm_source', 'share')
    return shareUrl.toString()
  } catch {
    return originalURL
  }
}

export const generateClickoutPreviewUrl = (
  props: CreatorUrlBuilderProps & {
    previewToken: string
    affiliateUrl: string
    linkId: string
  }
) => {
  return `${creatorUrlBuilder(props)}/out/preview?token=${
    props.previewToken
  }&url=${encodeURIComponent(props.affiliateUrl)}&linkId=${props.linkId}`
}

export const generateAnalyticsUrl = () => {
  return `${calculateCreatorPlatformRootURL()}/analytics/earnings`
}
