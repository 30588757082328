// Order is being used for selects, be careful if changing order here
export enum CurrencyIdentifierEnum {
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  GBP = 'GBP',
}

export type PriceType = {
  amount: number
  currency: string
}
