import React, { useState } from 'react'
import styled from 'styled-components'
import sanitizeHtml from 'sanitize-html'

import 'react-quill/dist/quill.snow.css'

import { FormValidations } from '@web-apps/utils-shared'

import { Box } from '../../atoms'
import { Theme } from '../../theme'
import {
  StyledLabelText,
  StyledError,
  StyledLengthFieldIndicator,
} from '../../styles/form.styles'

type ReactQuillProps = React.ComponentProps<typeof ReactQuill>
type RichTextEditorProps = ReactQuillProps & {
  label?: string
  errorMessages?: string[]
  maxLength?: number
  extendedToolbar?: boolean
}

// to solve ssr issue https://github.com/zenoamaro/react-quill/issues/292#issuecomment-790198764
const ReactQuill =
  typeof window === 'object' ? require('react-quill') : () => false

// https://quilljs.com/docs/formats/
const formats = ['bold', 'italic', 'underline']
const modules = {
  toolbar: [['bold', 'italic', 'underline']],
  clipboard: {
    matchVisual: false,
  },
}

const extendedFormats = [...formats, 'list']
const extendedModules = {
  toolbar: [
    ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const StyledContainer = styled.div`
  position: relative;
`

const StyledQuill = styled(ReactQuill)<{ $hasError: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  position: relative;

  .ql-container {
    flex: 1;
  }

  .ql-toolbar,
  .ql-container {
    border-color: ${({ $hasError }) =>
      $hasError ? Theme.Colors.typography.error : Theme.Colors.line};
  }

  .ql-editor {
    min-height: 200px;
    width: 100%;
    word-break: break-word;
  }

  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ql-tooltip {
    z-index: 9999;
  }

  * {
    font-size: inherit;
    color: inherit;
  }
`

const calculateRemainingCharactersForHtmlText = (
  htmlText: string,
  maxLength?: number
) => {
  if (!maxLength) return 0

  return maxLength - FormValidations.getHtmlTextOnlyLength(htmlText)
}

export const RichTextEditor = React.forwardRef<
  HTMLTextAreaElement,
  RichTextEditorProps
>(
  (
    { label, errorMessages = [], maxLength, extendedToolbar, ...quilProps },
    ref
  ) => {
    const [remainingAllowedChars, setRemainingAllowedChars] = useState(
      calculateRemainingCharactersForHtmlText(quilProps.value, maxLength)
    )

    const showMaxLengthIndicator =
      maxLength && typeof remainingAllowedChars === 'number'
    const hasErrors =
      errorMessages.length > 0 ||
      (showMaxLengthIndicator && remainingAllowedChars < 0)

    /*
      translate="no" to fix issue reported here: https://github.com/quilljs/quill/issues/2286
      More about this property here: https://stackoverflow.com/a/21853147
      Tested on Google Chrome and Safari
    */
    return (
      <div translate="no">
        <StyledContainer>
          {label && (
            <Box mb={Theme.Form.fieldLabelSpacingInPx}>
              <StyledLabelText hasError={hasErrors}>{label}</StyledLabelText>
            </Box>
          )}
          <StyledQuill
            ref={ref}
            preserveWhitespace
            $hasError={hasErrors}
            modules={extendedToolbar ? extendedModules : modules}
            formats={extendedToolbar ? extendedFormats : formats}
            {...quilProps}
            // To prevent from the form failing with error if initial values is not initialised before this component
            // https://github.com/zenoamaro/react-quill/issues/498#issuecomment-894023748
            value={quilProps.value ?? ''}
            onChange={(value: string) => {
              quilProps.onChange && quilProps.onChange(value)
              if (maxLength) {
                const remainingChars = calculateRemainingCharactersForHtmlText(
                  value,
                  maxLength
                )

                setRemainingAllowedChars(remainingChars)
              }
            }}
          />

          {showMaxLengthIndicator && (
            <StyledLengthFieldIndicator $hasError={hasErrors}>
              {remainingAllowedChars}
            </StyledLengthFieldIndicator>
          )}
        </StyledContainer>
        {hasErrors && <StyledError>{errorMessages[0]}</StyledError>}
      </div>
    )
  }
)

export const StyledRichTextHtml = styled.div`
  color: inherit;
  word-break: break-word;
  overflow: hidden;
  font-size: 1rem;

  a,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: revert;
  }

  p {
    all: revert;
    margin: 0;
  }

  li {
    all: revert;
  }

  ul,
  ol {
    all: revert;
    margin: 0 0 0 24px;
    padding-left: 0;
  }
`

type RichTextHtmlProps = React.HTMLAttributes<HTMLDivElement> & {
  htmlText: string
}

export const richTextEditorSanitizer = (htmlText: string) =>
  sanitizeHtml(htmlText, {
    allowedTags: ['br', 'i', 'em', 'strong', 'p', 'b', 'u', 'ul', 'ol', 'li'],
  })

export const RichTextHtml = ({ htmlText, ...divProps }: RichTextHtmlProps) => {
  return (
    <StyledRichTextHtml
      dangerouslySetInnerHTML={{ __html: richTextEditorSanitizer(htmlText) }}
      {...divProps}
    />
  )
}
