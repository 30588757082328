import styled from 'styled-components'
import { media, BREAKPOINTS } from '../../helpers'
import { Theme } from '../../theme'

export const StyledNotificationBannerContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  background-color: ${Theme.Colors.background.alternate};
  border: 1px solid ${Theme.Colors.line};
  padding: 16px;
  border-radius: 8px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 24px;
    `
  )}

  word-break: break-word;
  white-space: normal;
`

export const StyledNotificationBannerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  ${media.from(
    BREAKPOINTS.md,
    `
      gap: 16px;
    `
  )}
`

export const StyledCloseButton = styled.button`
  background: unset;
  border: unset;

  position: absolute;
  right: -12px;
  top: -12px;
`

export const StyledCustomImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`
