import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { Typography } from '../../atoms'
import { BREAKPOINTS, media } from '../../helpers'
import { Theme } from '../../theme'

type CountdownProps = {
  days: number
  hours: number
  minutes: number
  seconds: number
  translator: (
    timePart: 'days' | 'hours' | 'minutes' | 'seconds',
    count: number
  ) => string
} & HTMLAttributes<HTMLDivElement>

const StyledCountdownContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  ${media.from(
    BREAKPOINTS.sm,
    `
      gap: 8px;
      flex-wrap: revert;
    `
  )}
`

const StyledCountdownPartCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;

  background: ${Theme.Colors.background.alternate};

  min-width: 60px;
  ${media.from(
    BREAKPOINTS.sm,
    `
      min-width: 72px;
    `
  )}

  padding-block: 8px;
`

const CountdownPart = ({ count, label }: { count: number; label: string }) => (
  <StyledCountdownPartCard>
    <Typography as="p" variant="h2" lineHeight="24px">
      {count}
    </Typography>
    <Typography as="p" variant="subTitle" fontWeight="500" fontSize="10px">
      {label}
    </Typography>
  </StyledCountdownPartCard>
)

export const Countdown = ({
  days,
  hours,
  minutes,
  seconds,
  translator,
  ...divProps
}: CountdownProps) => (
  <StyledCountdownContainer {...divProps}>
    <CountdownPart count={days} label={translator('days', days)} />
    <CountdownPart count={hours} label={translator('hours', hours)} />
    <CountdownPart count={minutes} label={translator('minutes', minutes)} />
    <CountdownPart count={seconds} label={translator('seconds', seconds)} />
  </StyledCountdownContainer>
)
