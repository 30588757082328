import React, { useState, useEffect, useCallback } from 'react'
import { RegistrationFlow, UpdateRegistrationFlowBody } from '@ory/client'
import { useTranslation, Trans } from 'react-i18next'
import { useFlag } from '@unleash/proxy-client-react'

import {
  FormValidations,
  METAPIC_PARTNER_IDENTIFIER,
  usePasswordHider,
} from '@web-apps/utils-shared'
import {
  Box,
  Button,
  Flex,
  Form,
  Icon,
  IconButton,
  StyledError,
  StyledCrossLine,
  TextField,
  Typography,
  LoadingSpinner,
  Theme,
} from '@web-apps/ui-shared'

import { useValidateEmailMutation } from '../../api'

import { useGetRegistrationFlow } from '../../utils/hooks'
import { submitRegistrationFlow } from '../../utils/flows'
import { AuthProviderName } from '../../utils/constants'
import { StyledLink, StyledTextFieldEmail } from './Register.styles'
import {
  FLAG_FACEBOOK_AUTH,
  FLAG_GITHUB_AUTH,
  FLAG_GOOGLE_AUTH,
} from '../../utils/flags.constants'

type EmailPasswordFormDataType = UpdateRegistrationFlowBody & {
  email: string
}
type RegisterProps = {
  onRegister: (values?: UpdateRegistrationFlowBody & { slug: string }) => void
  flowId?: string
  returnToUrl?: string
  slug: string
  predefinedEmail?: string | null
  partner?: string | null
}
export const Register = ({
  onRegister,
  flowId,
  returnToUrl,
  slug,
  predefinedEmail,
  partner,
}: RegisterProps) => {
  const isMetapicPartnership = Boolean(partner === METAPIC_PARTNER_IDENTIFIER)

  const isGoogleAuthEnabled = useFlag(FLAG_GOOGLE_AUTH) && !isMetapicPartnership
  const isGithubAuthEnabled = useFlag(FLAG_GITHUB_AUTH) && !isMetapicPartnership
  const isFacebookAuthEnabled =
    useFlag(FLAG_FACEBOOK_AUTH) && !isMetapicPartnership
  const isThereSocialAuth =
    isGoogleAuthEnabled ||
    isGithubAuthEnabled ||
    isFacebookAuthEnabled

  const { t } = useTranslation(['auth'])
  const [
    validateEmail,
    {
      isLoading: isValidateEmailLoading,
      isError: isValidateEmailError,
      isSuccess: isValidateEmailSuccess,
    },
  ] = useValidateEmailMutation()

  const {
    flow,
    isFlowLoading,
    fields,
    error: flowError,
    failedProvider,
    failedEmail,
  } = useGetRegistrationFlow({
    flowId,
    returnToUrl,
  })
  const { isPasswordVisible, togglePasswordVisibility } = usePasswordHider()

  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(
    null
  )
  const [isLoading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState<UpdateRegistrationFlowBody>()

  const onSubmit = useCallback(
    async (values: UpdateRegistrationFlowBody) => {
      setSubmitErrorMessage(null)
      setFormValue(undefined)
      setLoading(true)

      const response = await submitRegistrationFlow(
        flow as RegistrationFlow,
        values
      )

      setLoading(false)
      if (response?.error) {
        setSubmitErrorMessage(response.error)
      } else {
        onRegister({ ...values, ...{ slug: slug } })
      }
    },
    [flow, onRegister, slug]
  )

  const handleFormSubmit = (values: EmailPasswordFormDataType) => {
    setSubmitErrorMessage(null)
    const { email, ...data } = values
    setFormValue({ ...data, traits: { email } })
    validateEmail({ email })
  }

  useEffect(() => {
    if (isValidateEmailSuccess && formValue) {
      onSubmit(formValue)
    }
  }, [onSubmit, formValue, isValidateEmailSuccess])

  if (isFlowLoading)
    return (
      <Flex align="center" direction="column">
        <Box py={24}>
          <LoadingSpinner size={42} color={Theme.Colors.background.dark} />
        </Box>
      </Flex>
    )

  // Use case when a user trying to register with an oid that has an email
  // already present in the ory database
  if (flowId && flowError && failedEmail && failedProvider)
    return (
      <Flex align="center" justify="center">
        <Box mb={16}>
          <StyledError $size="regular">
            {t(`auth:errors.${flowError}`, {
              provider: failedProvider,
              email: failedEmail,
            })}
          </StyledError>
        </Box>
      </Flex>
    )

  return (
    <div>
      <Box mb={24}>
        <Flex direction="column" align="center" gap={16}>
          <Typography textAlign="center" variant="h2">
            {t('auth:register.title')}
          </Typography>
          <Typography textAlign="center">
            <b>{t('auth:register.domain_name')}/</b>
            {slug}
          </Typography>
        </Flex>
      </Box>
      {fields && (
        <>
          {Boolean(fields.google.length) && isGoogleAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.google.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Google />
                        <span>
                          {t('auth:register.register_with_provider', {
                            provider: AuthProviderName.GOOGLE,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.facebook.length) && isFacebookAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.facebook.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Facebook />
                        <span>
                          {t('auth:register.register_with_provider', {
                            provider: AuthProviderName.FACEBOOK,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.apple.length) && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.apple.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.Apple />
                        <span>
                          {t('auth:register.register_with_provider', {
                            provider: AuthProviderName.APPLE,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.tiktok.length) && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.tiktok.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.TikTok />
                        <span>
                          {t('auth:register.register_with_provider', {
                            provider: AuthProviderName.TIKTOK,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.github.length) && isGithubAuthEnabled && (
            <Form onSubmit={onSubmit}>
              {() => {
                return (
                  <Box mb={16}>
                    {fields.github.map(({ attributes }) => (
                      <TextField
                        key={attributes.name}
                        name={attributes.name}
                        type="hidden"
                        value={attributes.value}
                      />
                    ))}
                    <Button width="100%" inverse>
                      <Flex gap={8} justify="center">
                        <Icon.GitHub />
                        <span>
                          {t('auth:register.register_with_provider', {
                            provider: AuthProviderName.GITHUB,
                          })}
                        </span>
                      </Flex>
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}

          {Boolean(fields.email.length) && (
            <Form
              initialValues={
                {
                  email: predefinedEmail || '',
                } as EmailPasswordFormDataType
              }
              onSubmit={handleFormSubmit}
              initialWatchableFields={['password']}
              validations={{
                descriptors: {
                  email: {
                    required: t('auth:errors.email_required'),
                    pattern: {
                      value: FormValidations.emailRegex,
                      message: t('auth:errors.email_wrong_format'),
                    },
                  },
                  password: {
                    required: t('auth:errors.password_required'),
                    minLength: {
                      value: FormValidations.passwordMinLength,
                      message: t('auth:errors.password_is_short', {
                        number: FormValidations.passwordMinLength,
                      }),
                    },
                  },
                },
              }}
            >
              {() => {
                return (
                  <Box mb={16}>
                    {isThereSocialAuth && (
                      <Box my={24}>
                        <StyledCrossLine>
                          <Typography
                            variant="hint"
                            as="span"
                            color="inactive"
                            fontWeight={600}
                          >
                            {t('auth:register.second_title')}
                          </Typography>
                        </StyledCrossLine>
                      </Box>
                    )}
                    {[...fields.csrfToken, ...fields.method].map(
                      ({ attributes }) => (
                        <TextField
                          key={attributes.name}
                          name={attributes.name}
                          type="hidden"
                          value={attributes.value}
                        />
                      )
                    )}
                    {fields.email.map(({ attributes }) => (
                      <Box mb={16} key={attributes.name}>
                        <StyledTextFieldEmail
                          name="email"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          placeholder={t('auth:input_placeholders.email')}
                          readOnly={Boolean(
                            isMetapicPartnership && predefinedEmail
                          )}
                          errorMessages={
                            (isValidateEmailError && [
                              t('auth:errors.email_already_taken'),
                            ]) ||
                            undefined
                          }
                          notes={
                            isMetapicPartnership && predefinedEmail
                              ? [t('auth:register.metapic_integration_message')]
                              : []
                          }
                        />
                      </Box>
                    ))}
                    {fields.password.map(({ attributes }) => (
                      <Box mb={16} key={attributes.name}>
                        <TextField
                          name="password"
                          placeholder={t('auth:input_placeholders.password')}
                          type={isPasswordVisible ? 'text' : 'password'}
                          maxLength={FormValidations.passwordMaxLength}
                          rightDecorator={
                            <IconButton
                              icon={
                                isPasswordVisible
                                  ? 'PasswordHide'
                                  : 'PasswordShow'
                              }
                              onClick={togglePasswordVisibility}
                            />
                          }
                        />
                      </Box>
                    ))}
                    <Button
                      width="100%"
                      isLoading={isValidateEmailLoading || isLoading}
                    >
                      {t('auth:register.register_button')}
                    </Button>
                  </Box>
                )
              }}
            </Form>
          )}
        </>
      )}
      <Box my={24}>
        {(submitErrorMessage || flowError) && (
          <Flex align="center" justify="center">
            <Box mb={16}>
              <StyledError $size="regular">
                {t(`auth:errors.${submitErrorMessage || flowError}`)}
              </StyledError>
            </Box>
          </Flex>
        )}
      </Box>

      <Flex direction="column" gap={16}>
        <Typography variant="navigation" as="p" textAlign="center">
          <Trans
            i18nKey="auth:register.accept_terms"
            /* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
            components={{ a: <StyledLink /> }}
          />
        </Typography>
      </Flex>
    </div>
  )
}
