import { FlattenSimpleInterpolation, css } from 'styled-components'

type BreakpointType = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'

type BreakpointsType = {
  [breakpoint in BreakpointType]: BreakpointType
}
export const BREAKPOINTS: BreakpointsType = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
}

type BreakpointsSizesType = {
  from: { [breakpoint in BreakpointType]: number }
  to: { [breakpoint in BreakpointType]: number }
}

export const BREAKPOINTS_SIZES: BreakpointsSizesType = {
  from: {
    xxs: 0,
    xs: 376,
    sm: 551,
    md: 768,
    lg: 993,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
  },
  to: {
    xxs: 375,
    xs: 550,
    sm: 767,
    md: 992,
    lg: 1199,
    xl: 1399,
    xxl: 1599,
    xxxl: 9999,
  },
}

type mediaTypes = {
  from: (
    breakpoint: BreakpointType,
    content: string | FlattenSimpleInterpolation
  ) => FlattenSimpleInterpolation
  to: (
    breakpoint: BreakpointType,
    content: string | FlattenSimpleInterpolation
  ) => FlattenSimpleInterpolation
}
export const media: mediaTypes = {
  from: (breakpoint, content) => {
    return css`
      @media (min-width: ${BREAKPOINTS_SIZES.from[breakpoint]}px) {
        ${content}
      }
    `
  },
  to: (breakpoint, content) => {
    return css`
      @media (max-width: ${BREAKPOINTS_SIZES.to[breakpoint]}px) {
        ${content}
      }
    `
  },
}
