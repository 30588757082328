import styled, { css, CSSProperties } from 'styled-components'

import { Typography } from '../atoms'
import { TypographyProps } from '../atoms/Typography/Typography'
import { hexToRgb } from '../helpers'
import { Theme } from '../theme'

export const StyledLengthFieldIndicator = styled.span<{ $hasError?: boolean }>`
  position: absolute;
  right: 1px;
  bottom: 1px;
  font-size: 14px;
  line-height: 17px;
  border-left: solid 1px
    ${({ $hasError }) =>
      $hasError ? Theme.Colors.typography.error : Theme.Colors.line};
  border-top: solid 1px
    ${({ $hasError }) =>
      $hasError ? Theme.Colors.typography.error : Theme.Colors.line};
  border-radius: 8px 0 8px 0;
  color: ${({ $hasError = false }) =>
    $hasError
      ? Theme.Colors.typography.error
      : Theme.Colors.typography.inactive};
  padding: 8px;
  background-color: ${Theme.Colors.background.base};
`

export const StyledLabelText: React.FC<
  TypographyProps & { hasError?: boolean }
> = ({ children, hasError, ...props }) => (
  <Typography
    fontWeight="600"
    variant="h4"
    as="label"
    color={hasError ? 'error' : 'regular'}
    {...props}
  >
    {children}
  </Typography>
)

export const StyledFieldDescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Theme.Colors.typography.inactive};
`

export const StyledCrossLine = styled.div<{
  $overrideColor?: CSSProperties['color']
}>`
  display: flex;
  flex-direction: row;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: ${({ $overrideColor = Theme.Colors.typography.inactive }) =>
      `1px solid ${$overrideColor}`};
    margin: auto;
  }

  &:before {
    margin-right: 8px;
  }

  &:after {
    margin-left: 8px;
  }
`

export const StyledError = styled.span<{ $size?: 'small' | 'regular' }>`
  ${({ $size = 'small' }) => `
    font-size: ${$size === 'small' ? 12 : 16}px;
  `}

  line-height: 16px;
  color: ${Theme.Colors.typography.error};
`

export const StyledInfoText = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`

export const StyledNoteSmall = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${Theme.Colors.typography.inactive};
`

export const BaseFieldCSS = (props?: { hasError?: boolean }) => {
  const hasError = props?.hasError || false
  const errorColorRgb = hexToRgb(Theme.Colors.typography.error)

  return css`
    width: 100%;
    padding: 16px;
    border: 1px solid
      ${hasError ? Theme.Colors.typography.error : Theme.Colors.line};
    border-radius: 8px;
    line-height: 20px;
    background-color: ${hasError
      ? `rgba(${errorColorRgb?.r}, ${errorColorRgb?.g}, ${errorColorRgb?.b}, 0.04)}`
      : Theme.Colors.background.base};
    outline: none;
    color: ${hasError
      ? Theme.Colors.typography.error
      : Theme.Colors.typography.regular};
  `
}

export const HasErrorCSS = (props?: { hasError?: boolean }) => {
  const hasError = props?.hasError || false
  const errorColorRgb = hexToRgb(Theme.Colors.typography.error)

  return css`
    border: 1px solid
      ${hasError ? Theme.Colors.typography.error : Theme.Colors.line};
    background-color: ${hasError
      ? `rgba(${errorColorRgb?.r}, ${errorColorRgb?.g}, ${errorColorRgb?.b}, 0.04)}`
      : Theme.Colors.background.base};
    outline: none;
    color: ${hasError
      ? Theme.Colors.typography.error
      : Theme.Colors.typography.regular};
  `
}
