import styled from 'styled-components'

import { Theme } from '../../theme'
import { BaseFieldCSS } from '../../styles'
import { IconCatalogPaths } from '../../atoms'

export const StyledContainer = styled.label`
  display: block;
  margin: 0;
`

export const StyledSelect = styled.select<{ $hasError: boolean }>`
  ${({ $hasError }) => BaseFieldCSS({ hasError: $hasError })}

  &:focus {
    outline: none;
  }

  margin: ${Theme.Form.fieldLabelSpacingInPx}px 0;
  background-image: url('${IconCatalogPaths.Chevron}');
  background-position: right 8px center;
  background-repeat: no-repeat;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`
