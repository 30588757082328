import React, { ButtonHTMLAttributes } from 'react'

import { Icon, StyledIconPlainButton } from '../../atoms'

type IconProps = React.ComponentProps<typeof Icon.RoundedCheck>
type IconsFC = keyof typeof Icon

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: IconsFC
  iconProps?: Omit<IconProps, 'children'>
}

export const IconButton = ({
  icon,
  iconProps,
  ...buttonProps
}: IconButtonProps) => {
  const IconComponent = Icon[icon]

  return (
    <StyledIconPlainButton type="button" {...buttonProps}>
      <IconComponent {...(iconProps || {})} />
    </StyledIconPlainButton>
  )
}
