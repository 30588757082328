import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import { Card, Flex, Icon, Typography } from '../../atoms'
import { Theme } from '../../theme'

type ActionItemCardProps = {
  leftDecorator: JSX.Element
  label: string
  isLoading?: boolean
  count?: number
  target?: number
  isCompleted?: boolean
  disableLineThroughWhenCompleted?: boolean
}

const StyledItemCard = styled(Card)<{ $isProgressAction?: boolean }>`
  padding: 16px;
  position: relative;

  ${({ $isProgressAction = false }) =>
    $isProgressAction
      ? `border-bottom: 4px solid ${Theme.Colors.background.alternate};`
      : ''}
`

const StyledItemProgressBar = styled.div<{
  $count?: number
  $target?: number
  $isCompleted?: boolean
}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;

  ${({ $isCompleted = false, $count, $target }) =>
    typeof $count === 'number' && typeof $target === 'number'
      ? `
          border-bottom-left-radius: 8px;
          background: ${Theme.Colors.typography.valid};
          height: 4px;
          border-bottom-right-radius: ${$isCompleted ? '8px' : '0'};
          width: ${($count / $target) * 100}%;
          max-width: 100%;
        `
      : ''}
`

const StyledItemRightDecoratorInfo = styled.div`
  margin-left: auto;
`

const StyledProgressInfoContainer = styled.div`
  min-width: max-content;
`

export const ActionItemCard = ({
  leftDecorator,
  label,
  isLoading = false,
  isCompleted = false,
  count,
  target,
  disableLineThroughWhenCompleted = false,
}: ActionItemCardProps) => {
  const isProgressAction =
    typeof count === 'number' && typeof target === 'number'

  return (
    <StyledItemCard $isProgressAction={isProgressAction}>
      {isLoading ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <>
          <Flex gap={8} align="center">
            {leftDecorator}
            <Typography
              as="p"
              variant="navigation"
              fontWeight={500}
              style={{
                textDecoration:
                  isCompleted && !disableLineThroughWhenCompleted
                    ? 'line-through'
                    : undefined,
              }}
            >
              {label}
            </Typography>
            <StyledItemRightDecoratorInfo>
              {isCompleted && <Icon.PlainCheck width={24} height={24} />}
              {isProgressAction && !isCompleted && (
                <StyledProgressInfoContainer>
                  <Typography as="span" fontWeight={600}>
                    {count}
                  </Typography>
                  <Typography variant="navigation"> /{target}</Typography>
                </StyledProgressInfoContainer>
              )}
            </StyledItemRightDecoratorInfo>
          </Flex>
          <StyledItemProgressBar
            $isCompleted={isCompleted}
            $count={count}
            $target={target}
          />
        </>
      )}
    </StyledItemCard>
  )
}
