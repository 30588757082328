import React, { InputHTMLAttributes } from 'react'
import {
  StyledLabel,
  StyledInputCheckbox,
  StyledCheckbox,
  StyledLabelContent,
} from './CheckboxField.styles'

export type CheckboxFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  children: React.ReactNode
  name: string
  errorMessages?: string[]
}

export const CheckboxField = React.forwardRef<
  HTMLInputElement,
  CheckboxFieldProps
>(
  (
    { children, name, errorMessages, ...otherProps }: CheckboxFieldProps,
    ref
  ) => {
    return (
      <StyledLabel>
        <StyledInputCheckbox
          ref={ref}
          name={name}
          type="checkbox"
          {...otherProps}
        />
        <StyledLabelContent>{children}</StyledLabelContent>
        <StyledCheckbox $hasError={Boolean(errorMessages?.length)} />
      </StyledLabel>
    )
  }
)
