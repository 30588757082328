// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: publicRuntimeConfig.sentry.dsn,
    release: publicRuntimeConfig.sentry.release,
    environment: publicRuntimeConfig.sentry.environment,
    sampleRate: publicRuntimeConfig.sentry.errorsSampleRate,
    tracesSampleRate: publicRuntimeConfig.sentry.tracesSampleRate,
    // The list of errors was based on a suggestion here: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // TBU based on fantom errors
      'Network Error',
      // https://github.com/getsentry/sentry-javascript/issues/7993
      'webkit-masked-url',
    ],
    denyUrls: [
      /plausible\.io\/api\/event/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /chrome-extension/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /C:\\Users/i,
    ],
  })
}
