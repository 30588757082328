import styled from 'styled-components'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { BaseFieldCSS } from '../../styles'
import { Theme } from '../../theme'

export const StyledPhoneInput = styled(PhoneInput)<{ $hasError: boolean }>`
  ${({ $hasError }) =>
    BaseFieldCSS({
      hasError: $hasError,
    })} /* due to some browsers applying an auto background we need to force the regular color, even when hasError */ background-color: ${Theme
    .Colors.background.base};

  position: relative;
  margin-block-start: ${Theme.Form.fieldLabelSpacingInPx}px;

  input {
    border: none;
    outline: none;

    font-size: inherit;
    line-height: inherit;
    color: inherit;

    padding-inline-start: 24px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${Theme.Colors.background.base} inset !important;
  }

  .PhoneInputCountrySelectArrow {
    margin-inline-start: 8px;
    height: 8px;
    width: 8px;
    opacity: revert;

    border-bottom-width: 2px;
    border-right-width: 2px;
  }

  .PhoneInputCountry:before {
    content: '';
    position: absolute;
    font-weight: 600;
    top: -16px;
    bottom: -16px;
    right: -16px;
    z-index: 1;
    width: 1px;
    background-color: ${Theme.Colors.line};
  }
`
