import { CountryISOCodeType, CountryType } from '@web-apps/utils-types'
import { LocalisationConstants } from './localisation.constants'

export const getCountryNameFromCountryISOCode = ({
  countryISOCode,
  language,
  notFoundMessage,
}: {
  countryISOCode: CountryISOCodeType
  language?: string
  notFoundMessage?: string
}) => {
  let countryName

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const IntlInstance = Intl as any
  if (IntlInstance?.DisplayNames) {
    const regionNames = new IntlInstance.DisplayNames(
      [language || LocalisationConstants.DEFAULT_LANGUAGE],
      {
        type: 'region',
      }
    )

    try {
      countryName = regionNames.of(countryISOCode)
    } catch (e) {
      if (notFoundMessage) countryName = notFoundMessage
    }
  }

  return countryName
}

export const getCountriesList = ({
  language,
  countriesISOCodes,
}: {
  language?: string
  countriesISOCodes: CountryISOCodeType[]
}): CountryType[] =>
  countriesISOCodes
    .map((countryISOCode) => ({
      countryISOCode,
      name: getCountryNameFromCountryISOCode({ countryISOCode, language }),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

export const LocalisationHelpers = {
  getCountryNameFromCountryISOCode,
  getCountriesList,
}
