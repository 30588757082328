import { useState, ReactNode } from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'

import {
  copyLinkToClipboard,
  isInAndroid,
  isInAppBrowser,
} from '@web-apps/utils-shared'

import { Theme } from '../../theme'
import { ClickOutside } from '../../atoms'
import {
  ButtonProps,
  Button,
  ButtonVariant,
  ButtonSize,
} from '../Button/Button'

const StyledWrapper = styled.div`
  position: relative;
`

const StyledTooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  padding: 8px;
  border-radius: 3px;
  font-size: 14px;
  background: ${Theme.Button.backgroundColor};
  color: ${Theme.Text.inverted};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 6px);
    border: 6px solid transparent;
    border-top: 5px solid ${Theme.Button.backgroundColor};
  }
`

export type CopyButtonProps = {
  value: string
  buttonSize?: ButtonSize
  buttonVariant?: ButtonVariant
  messages: {
    buttonText: string | ReactNode
    copiedTooltipText: string
  }
  onClick?: ButtonProps['clickHandler']
}

export const CopyButton = ({
  value,
  buttonSize = ButtonSize.SMALL,
  buttonVariant = ButtonVariant.PRIMARY,
  messages,
  onClick,
}: CopyButtonProps) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  const copy = async () => {
    if (isInAndroid() && isInAppBrowser()) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.value = value
      input.focus()
      input.select()

      try {
        setIsTooltipShown(document.execCommand('copy'))
      } catch (error) {
        Sentry.configureScope((scope) =>
          scope
            .setFingerprint(['copy-clipboard-handled'])
            .setTags({ operations: 'copy-clipboard' })
            .setLevel('error')
        )
        Sentry.captureException(error)
      }

      input.blur()
    } else {
      const wasCopied = await copyLinkToClipboard(value)
      setIsTooltipShown(wasCopied)
    }
  }

  return (
    <StyledWrapper>
      <ClickOutside handleClickOutside={() => setIsTooltipShown(false)}>
        <Button
          clickHandler={(e) => {
            copy()

            if (onClick) onClick(e)
          }}
          size={buttonSize}
          variant={buttonVariant}
          type="button"
        >
          {messages.buttonText}
        </Button>
      </ClickOutside>
      {isTooltipShown && (
        <StyledTooltip>{messages.copiedTooltipText}</StyledTooltip>
      )}
    </StyledWrapper>
  )
}
