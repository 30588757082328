import { useEffect, useState } from 'react'

export type ImageErrorHandlerWrapperProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export const ImageErrorHandlerWrapper = ({
  alt,
  ...props
}: ImageErrorHandlerWrapperProps) => {
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)

  useEffect(() => {
    setErrorLoadingImage(false)
  }, [props.src])

  return errorLoadingImage ? null : (
    <img
      {...props}
      alt={alt}
      onError={(e) => {
        if (props.onError) {
          props.onError(e)
          return
        }

        setErrorLoadingImage(true)
      }}
    />
  )
}
