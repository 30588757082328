import { FC, PropsWithChildren, Children } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'

import { StyledArrowContainer, StyledArrowWrapper } from './Carousel.styles'
import { Icon } from '../../atoms'

const CarouselArrow = ({
  onClick,
  isRight,
  slidesToShow = 1,
  currentSlide = 0,
  slideCount = Infinity,
}: {
  onClick?: () => void
  isRight?: boolean
  currentSlide?: number
  slidesToShow?: number
  slideCount?: number
}) => {
  if (Math.floor(currentSlide + slidesToShow) === slideCount && isRight)
    return null
  if (!currentSlide && !isRight) return null

  return (
    <StyledArrowContainer $isRightArrow={isRight}>
      <StyledArrowWrapper onClick={onClick}>
        <Icon.Chevron
          style={{ transform: `rotate(${isRight ? -90 : 90}deg)` }}
        />
      </StyledArrowWrapper>
    </StyledArrowContainer>
  )
}

export const Carousel: FC<
  PropsWithChildren<{
    slidesToShow: number
    slidesToScroll: number
    responsive?: {
      breakpoint: number
      settings: {
        slidesToShow: number
        slidesToScroll: number
      }
    }[]
  }>
> = ({ children, slidesToShow, slidesToScroll, responsive = [] }) => {
  const arrayChildren = Children.toArray(children)

  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    responsive,
    nextArrow: <CarouselArrow isRight slidesToShow={slidesToShow} />,
    prevArrow: <CarouselArrow slidesToShow={slidesToShow} />,
  }

  return (
    <Slider {...settings}>
      {Children.map(arrayChildren, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </Slider>
  )
}

export const StyledCarouselWrapper = styled.div`
  .slick-list {
    margin-inline: -8px;
  }

  .slick-slide {
    padding-inline: 8px;
  }
`
