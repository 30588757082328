import React from 'react'
import styled from 'styled-components'
import { TFunction } from 'next-i18next'
import * as Sentry from '@sentry/browser'
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

import {
  clientConfig,
  copyLinkToClipboard,
  getSocialData,
  isInAndroid,
  isInAppBrowser,
} from '@web-apps/utils-shared'
import { SocialNetworksEnum } from '@web-apps/utils-types'

import { Icon, IconAssetsKeys } from '../../atoms'
import { Button, ButtonVariant } from '../../molecules'

const StyledButton = styled(Button)`
  font-weight: normal;
`
type InstagramShareButtonPops = {
  url: string
  children: React.ReactNode
  onClick?: () => void
}
export const InstagramShareButton: React.FunctionComponent<
  InstagramShareButtonPops
> = ({ url, children, onClick }: InstagramShareButtonPops) => {
  const copy = async () => {
    if (onClick) onClick()
    if (isInAndroid() && isInAppBrowser()) {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.value = url
      input.focus()
      input.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        Sentry.configureScope((scope) =>
          scope
            .setFingerprint(['copy-clipboard-handled'])
            .setTags({ operations: 'copy-clipboard' })
            .setLevel('error')
        )
        Sentry.captureException(error)
      }

      window.open('https://www.instagram.com/')
      input.blur()
    } else {
      await copyLinkToClipboard(url)
      window.open('https://www.instagram.com/')
    }
  }

  return (
    <StyledButton variant={ButtonVariant.PLAIN} clickHandler={copy}>
      {children}
    </StyledButton>
  )
}

type ShareToSocialConfigType = {
  translation: string
  shareComponentsProps: {
    url: string
    [propertyKey: string]: string
  }
  ShareComponent:
    | // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.ForwardRefExoticComponent<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.FunctionComponent<any>
  IconComponent: React.FC<React.PropsWithChildren<unknown>>
}

export const shareToSocialConfig = (
  url: string,
  t: TFunction,
  variant: 'main' | 'link' = 'main',
  translationsNamespace = 'app'
): ShareToSocialConfigType[] => {
  return [
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_instagram`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.Instagram).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.Instagram).title,
        url,
        quote: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message`,
          {
            url,
          }
        ),
      },
      ShareComponent: InstagramShareButton,
      IconComponent:
        Icon[
          getSocialData(SocialNetworksEnum.Instagram).icon as IconAssetsKeys
        ],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.Facebook).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.Facebook).title,
        url,
        quote: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message`,
          {
            url,
          }
        ),
      },
      ShareComponent: FacebookShareButton,
      IconComponent:
        Icon[getSocialData(SocialNetworksEnum.Facebook).icon as IconAssetsKeys],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.LinkedIn).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.LinkedIn).title,
        url,
        title: url,
        summary: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message`,
          {
            url,
          }
        ),
      },
      ShareComponent: LinkedinShareButton,
      IconComponent:
        Icon[getSocialData(SocialNetworksEnum.LinkedIn).icon as IconAssetsKeys],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.Twitter).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.Twitter).title,
        url,
        title: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message_when_url_prepended`,
          { url: '' }
        ),
      },
      ShareComponent: TwitterShareButton,
      IconComponent:
        Icon[getSocialData(SocialNetworksEnum.Twitter).icon as IconAssetsKeys],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_via_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.Email).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.Email).title,
        url,
        subject: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_title`
        ),
        separator: '',
        body: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message_when_url_prepended`,
          { url: '' }
        ),
      },
      ShareComponent: EmailShareButton,
      IconComponent:
        Icon[
          getSocialData(SocialNetworksEnum.Email).monoIcon as IconAssetsKeys
        ],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_via_link`,
        {
          // We can't add messenger to the social links enum because we don't support it
          socialNetworkName: 'Messenger',
        }
      ),
      shareComponentsProps: {
        name: 'Messenger',
        url,
        appId: clientConfig?.FACEBOOK_APP_ID || '',
      },
      ShareComponent: FacebookMessengerShareButton,
      IconComponent: Icon.Messenger,
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_via_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.Telegram).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.Telegram).title,
        url,
        title: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message`,
          {
            url,
          }
        ),
      },
      ShareComponent: TelegramShareButton,
      IconComponent:
        Icon[getSocialData(SocialNetworksEnum.Telegram).icon as IconAssetsKeys],
    },
    {
      translation: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_via_link`,
        {
          socialNetworkName: getSocialData(SocialNetworksEnum.WhatsApp).title,
        }
      ),
      shareComponentsProps: {
        name: getSocialData(SocialNetworksEnum.WhatsApp).title,
        url,
        title: t(
          `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message_when_url_prepended`
        ),
      },
      ShareComponent: WhatsappShareButton,
      IconComponent:
        Icon[getSocialData(SocialNetworksEnum.WhatsApp).icon as IconAssetsKeys],
    },
  ]
}

export const shareAffiliateToSocialConfig = (
  url: string,
  t: TFunction,
  variant: 'main' | 'link' = 'main',
  translationsNamespace = 'app'
): ShareToSocialConfigType[] => [
  {
    translation: t(
      `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_instagram`,
      {
        socialNetworkName: getSocialData(SocialNetworksEnum.Instagram).title,
      }
    ),
    shareComponentsProps: {
      url,
      quote: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message`,
        {
          url,
        }
      ),
    },
    ShareComponent: InstagramShareButton,
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.Instagram).icon as IconAssetsKeys],
  },
  {
    translation: t(
      `${translationsNamespace}:share_popup.sections.socials.${variant}.share_on_link`,
      {
        socialNetworkName: getSocialData(SocialNetworksEnum.Twitter).title,
      }
    ),
    shareComponentsProps: {
      url,
      title: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message_when_url_prepended`,
        { url: '' }
      ),
    },
    ShareComponent: TwitterShareButton,
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.Twitter).icon as IconAssetsKeys],
  },
  {
    translation: t(
      `${translationsNamespace}:share_popup.sections.socials.${variant}.share_via_link`,
      {
        socialNetworkName: getSocialData(SocialNetworksEnum.WhatsApp).title,
      }
    ),
    shareComponentsProps: {
      url,
      title: t(
        `${translationsNamespace}:share_popup.sections.socials.${variant}.share_message_when_url_prepended`
      ),
    },
    ShareComponent: WhatsappShareButton,
    IconComponent:
      Icon[getSocialData(SocialNetworksEnum.WhatsApp).icon as IconAssetsKeys],
  },
]
