import { ImageResponseType } from '@web-apps/utils-types'

type ColorThiefInfo = [number, number, number]
type ColorThiefInstance = {
  getColor: (
    img: HTMLImageElement | string,
    quality?: number
  ) => Promise<ColorThiefInfo>
  getPalette: (
    img: HTMLImageElement | string,
    colorCount?: number,
    quality?: number
  ) => Promise<ColorThiefInfo[]>
}

type ImageColorsExtractorOptions = {
  image: HTMLImageElement | string
  quality?: number
  colorCount?: number
}

export type Color = {
  hexValue: string
}

export type ExtractedColors = {
  dominantColor: Color
  paletteColors: Color[]
}

const isClientEnv = typeof window !== 'undefined'

const rgbToHex = (r: number, g: number, b: number): string =>
  '#' +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })
    .join('')

const getColorThiefInstance = async (): Promise<ColorThiefInstance> => {
  if (isClientEnv) {
    const ColorThiefModule = await (async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return await import('colorthief')
    })()
    return new ColorThiefModule.default()
  } else {
    return require('colorthief')
  }
}

const processImage = ({
  image,
  colorCount,
  quality,
}: ImageColorsExtractorOptions): Promise<ExtractedColors> => {
  return new Promise((resolve, reject) => {
    getColorThiefInstance()
      .then((colorThiefInstance) => {
        Promise.all([
          colorThiefInstance.getPalette(image, colorCount, quality),
          colorThiefInstance.getColor(image, quality),
        ])
          .then(([paletteData, dominantColor]) => {
            const hexValueColors = new Set(
              paletteData.map(([r, g, b]) => rgbToHex(r, g, b))
            )

            resolve({
              dominantColor: {
                hexValue: rgbToHex(
                  dominantColor[0],
                  dominantColor[1],
                  dominantColor[2]
                ),
              },
              paletteColors: Array.from(hexValueColors).map((hexValue) => ({
                hexValue,
              })),
            })
          })
          .catch(reject)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

/**
 *
 * Request parameters:
 *  image: mandatory, HTMLImageElement | string
 *    in client side it could an HTMLImageElement or a base 64 url
 *    in server side it could be an url
 *  quality: is an optional argument that must be an Integer of value 1 or greater, and defaults to 10.
 *           The number determines how many pixels are skipped before the next one is sampled.
 *           We rarely need to sample every single pixel in the image to get good results. The bigger the number, the faster a value will be returned.
 * colorCount: max number of colors to extract
 *
 * Returns
 *  dominantColor: object with hexValue property for dominant color in the image being processed
 *  colors: array of objects with hexValue property for colors extracted from image being processed
 *
 */
export const extractColorsFromImage = async ({
  image,
  quality = 10,
  colorCount = 4,
}: ImageColorsExtractorOptions): Promise<ExtractedColors> => {
  return new Promise((resolve, reject) => {
    // short circuit invalid case: when in node image must be a string
    if (!isClientEnv && typeof image !== 'string') {
      reject('Not valid')
      return
    }

    // process server case
    if (!isClientEnv) {
      processImage({ image, quality, colorCount }).then(resolve).catch(reject)
      return
    }

    let imageToBeProcessed: HTMLImageElement
    if (typeof image === 'string') {
      imageToBeProcessed = document.createElement('img')
      imageToBeProcessed.src = image
      imageToBeProcessed.crossOrigin = 'Anonymous'
    } else {
      imageToBeProcessed = image
    }

    // need to load image if not ready
    if (!imageToBeProcessed.complete) {
      const onLoad = () => {
        processImage({ image: imageToBeProcessed, quality, colorCount })
          .then(resolve)
          .catch(reject)

        imageToBeProcessed.removeEventListener('load', onLoad)
      }

      imageToBeProcessed.addEventListener('load', onLoad)
    } else {
      // can process it
      processImage({ image: imageToBeProcessed, quality, colorCount })
        .then(resolve)
        .catch(reject)
    }
  })
}

export const isImageFromZezamStorage = (url: string): boolean => {
  const regex = new RegExp('https://media.zezam.(io|xyz)')

  return regex.test(url)
}

// idea from here https://stackoverflow.com/a/38935990/1085387
export const dataURLtoFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',')

  if (arr.length < 2) return

  const mimeMatch = arr[0].match(/:(.*?);/)

  if (!mimeMatch) return

  const mime = mimeMatch[1]
  const bstr = atob(arr[1])

  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const ZEZAM_THUMBNAIL_IMAGE_URL =
  'https://media.zezam.io/static/themes/zezam-logo.png'

export const getDefaultImageFromAPIResponse = (
  imageObj: ImageResponseType | ImageResponseType[] | undefined
): ImageResponseType | undefined => {
  if (!Array.isArray(imageObj)) {
    return imageObj
  }

  const image = imageObj.find((item) => item.name === 'default')

  return image || imageObj[0]
}
