import styled from 'styled-components'
import { Box, Flex, Icon, Typography } from '../../atoms'
import { Theme } from '../../theme'
import { CustomCard, CustomCardProps } from './CustomCard'

type ListItemCardProps = CustomCardProps & {
  title: string | JSX.Element
  description?: string | JSX.Element
  imageUrl?: string
  rightDecoratorOverride?: JSX.Element
  boxPadding?: number
}

const StyledRightArrow = styled(Icon.Chevron)`
  transform: rotate(-90deg);
`

const StyledLeftIcon = styled.div<{ $src: string }>`
  width: 48px;
  height: 48px;
  border: 1px solid ${Theme.Card.borderColor};
  background-image: url(${({ $src }) => $src});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  flex-shrink: 0;
`

export const ListItemCard = ({
  title,
  description,
  imageUrl,
  rightDecoratorOverride,
  boxPadding = 16,
  ...props
}: ListItemCardProps) => {
  return (
    <CustomCard {...props}>
      <Box p={boxPadding}>
        <Flex gap={8}>
          {imageUrl && <StyledLeftIcon $src={imageUrl} />}
          <Flex
            justify="space-between"
            align="center"
            flex={1}
            style={{ minWidth: 0 }}
          >
            <div style={{ minWidth: 0 }}>
              {typeof title === 'string' ? (
                <Typography variant="h4" as="p" fontWeight={500}>
                  {title}
                </Typography>
              ) : (
                title
              )}
              {typeof description === 'string' ? (
                <Typography variant="hint" color="inactive">
                  {description}
                </Typography>
              ) : (
                description
              )}
            </div>
            <Box ml={8}>{rightDecoratorOverride || <StyledRightArrow />}</Box>
          </Flex>
        </Flex>
      </Box>
    </CustomCard>
  )
}
