import dayjs from 'dayjs'

import { ImageResponseAPIType, ImageResponseType } from './image.types'

export type UserIdType = string
export type PageIdType = string
export type SlugType = string
export type DeprecatedCoachId = string

type LinkResourceFromAPIBaseType = {
  href: string
  methods?: RequestMethodsEnum[]
}

export type ResourceAPIType = {
  [key: string]: unknown
  _links: {
    self: LinkAPIType
    'self/image'?: ImageResponseAPIType
    [key: string]:
      | LinkAPIType
      | LinkAPIType[]
      | ImageResponseAPIType
      | undefined
  }
}

export type LinkAPIType = LinkResourceFromAPIBaseType & {
  [key: string]: string | boolean | number | unknown[]
}

export enum RequestMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type SelfImageProps = {
  'self/image'?: ImageResponseAPIType
  'self/edit-image': {
    href: string
    name?: string
    methods?: RequestMethodsEnum[]
  }
  'self/delete-image'?: ImageResponseType[]
}

export type ResponseList<T> = {
  content: T[]
  page: {
    number: number
    size: number
    total_elements: number
    total_pages: number
  }
  _links: {
    self: { href: string }
    prev?: { href: string }
    next?: { href: string }
    first?: { href: string }
    last?: { href: string }
  }
}

export type CountryISOCodeType = string

export type CountryType = {
  countryISOCode: CountryISOCodeType
  name: string
}

export enum ErrorCodes {
  GENERIC = 'unexpected',
  NOT_FOUND = 'not_found',
}

export type ContactTypeApi = {
  href: string
  name: string
}

export type ContactType = {
  href: string
  value: string
}

export type DateType = string | Date | number | dayjs.Dayjs

export type TimePartsType = {
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
}
