import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Box, Chip, Flex, Typography } from '../../atoms'
import { Theme } from '../../theme'

import { CustomCard, CustomCardProps } from './CustomCard'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
type BannerCardProps = CustomCardProps & {
  tagText: string
  title: string
  description: React.ReactNode
  callToActionButtonProps: Omit<ButtonProps, 'children'> & { text: string }
}

const StyledUpperText = styled.span`
  text-transform: uppercase;
`

export const BannerCard = ({
  tagText,
  title,
  description,
  callToActionButtonProps: {
    text: callToActionButtonText,
    style: callToActionButtonOverrideStyles,
    ...callToActionButtonProps
  },
  ...props
}: BannerCardProps) => {
  return (
    <CustomCard {...props}>
      <Box py={48} px={24}>
        <Flex direction="column" gap={8}>
          <Typography color="inverse" fontWeight="bold">
            <StyledUpperText>{tagText}</StyledUpperText>
          </Typography>
          <Typography variant="h2" color="inverse">
            {title}
          </Typography>
        </Flex>
        <Box mt={24}>
          <Typography color="inverse">{description}</Typography>
        </Box>
        <Box mt={24}>
          <Chip
            as="button"
            {...callToActionButtonProps}
            style={
              // FIXME: tried to use styled(Chip)``, however styles are not passed down for some reason, that I do not understand
              callToActionButtonOverrideStyles || {
                fontWeight: 'bold',
                backgroundColor: Theme.Colors.background.base,
              }
            }
          >
            {callToActionButtonText}
          </Chip>
        </Box>
      </Box>
    </CustomCard>
  )
}
