export enum AuthProviderName {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  GITHUB = 'Github',
  APPLE = 'Apple',
  TIKTOK = 'TikTok',
}

export const REDIRECT_PARAM_KEY = 'redirect'
