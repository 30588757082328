import styled from 'styled-components'
import { BaseCardCSS, Card } from '../../atoms'
import { Theme } from '../../theme'

export const StyledBaseCard = styled(Card)<{ $enableHoverEffect: boolean }>`
  /* this should not be needed but when using as="a" for some reason the Card styles are not being passed down */
  ${BaseCardCSS};

  display: flex;
  flex-direction: column;

  /* !important should not be needed but when using as="a" for some reason those styles are not overriding */
  padding: 0 !important;

  /* only when as="a" we apply hover effect */

  &:hover {
    ${({ $enableHoverEffect }) =>
      $enableHoverEffect
        ? `border: 1px solid ${Theme.Card.borderHoverColor}`
        : ''};
  }

  cursor: ${({ $enableHoverEffect }) =>
    $enableHoverEffect ? 'pointer' : 'initial'};
`
export const StyledBodyContainer = styled.div`
  position: relative;

  /* needed for button variant */
  width: 100%;
  text-align: left;
`
export const StyledContentContainer = styled.div<{ $isSquare?: boolean }>`
  position: relative;
  z-index: 2;
  ${({ $isSquare }) => $isSquare && 'padding-top: 100%;'}
`
export const StyledFooterContainer = styled.div`
  margin-top: auto;
  padding: 16px;

  border-top: 1px solid ${Theme.Card.borderColor};

  /* needed for button variant */
  width: 100%;
  text-align: left;
`
export const StyledOverlayImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;

  border-radius: ${Theme.Card.borderRadiusInPx}px;
`
export const StyledOverlayImage = styled.img<{
  $needsFullBorderRadius: boolean
}>`
  width: 100%;
  height: 100%;

  border-radius: ${Theme.Card.borderRadiusInPx}px;

  ${({ $needsFullBorderRadius }) =>
    $needsFullBorderRadius
      ? ''
      : `  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;`}

  object-fit: contain;
`
