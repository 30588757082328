import styled from 'styled-components'

import { Theme } from '../../theme'
import { Card, Icon } from '../../atoms'
import { HTMLAttributes } from 'react'
import { BREAKPOINTS, media } from '../../helpers'

export const StyledDragIcon = styled(Icon.DragDots)`
  padding-inline: 16px;
  align-self: stretch;

  svg,
  path {
    fill: ${Theme.Colors.typography.inactive};
  }

  &:hover svg,
  &:active svg,
  &:hover path,
  &:active path {
    fill: ${Theme.Colors.typography.regular};
  }
`

export const StyledDragIconContainer = styled.div<{ $isDragging: boolean }>`
  display: flex;
  align-items: center;
  align-self: stretch;

  border-radius: ${Theme.Card.borderRadiusInPx}px 0 0
    ${Theme.Card.borderRadiusInPx}px;

  &:active {
    background-color: ${Theme.Colors.background.alternate};
  }

  background-color: ${({ $isDragging }) =>
    $isDragging ? Theme.Colors.background.alternate : 'initial'};

  ${media.from(
    BREAKPOINTS.sm,
    `
      &:hover {
        background-color: ${Theme.Colors.background.alternate};
      }  
    `
  )}
`

export const StyledVerticalSeparator = styled.div`
  width: 1px;
  background: ${Theme.Colors.background.alternate};
  align-self: stretch;
`

export const StyledDraggableCard = styled(Card)<{ $isDragging: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  box-shadow: ${({ $isDragging }) =>
    $isDragging ? 'rgb(0 0 0 / 16%) 3px 6px 13px' : 'none'};
`

export const StyledContentContainer = styled.div`
  flex: 1;

  /* Needed for ellipsis to have effect on children - https://stackoverflow.com/a/66689926/1085387 */
  min-width: 0;

  /* when used inside drag and drop container we only want drag cursor for drag icon */

  & > * {
    cursor: initial;
  }
`

export const DraggableCard = ({
  children,
  isDragging = false,
  ...props
}: HTMLAttributes<HTMLDivElement> & { isDragging?: boolean }) => (
  <StyledDraggableCard {...props} $isDragging={isDragging}>
    <StyledDragIconContainer $isDragging={isDragging}>
      <StyledDragIcon />
    </StyledDragIconContainer>
    <StyledVerticalSeparator />
    <StyledContentContainer>{children}</StyledContentContainer>
  </StyledDraggableCard>
)
