import { useState, ReactNode } from 'react'

import { ClickOutside, Icon } from '../../atoms'

import {
  StyledContainer,
  StyledToggle,
  StyledList,
  StyledLinkItem,
  StyledButtonItem,
} from './Dropdown.styles'

type ItemLink = {
  key: string
  value: string | ReactNode
  to: string
  onClick?: () => void
}

type ItemButton = {
  key: string
  value: string | ReactNode
  onClick: () => void
}

export enum DropdownDirectionEnum {
  TOP = 'top',
  DOWN = 'down',
}

type Props = {
  items: (ItemLink | ItemButton)[]
  isCaret: boolean
  title: string | ReactNode
  direction?: DropdownDirectionEnum
}

export const Dropdown = ({ items, title, isCaret, direction }: Props) => {
  const [isOpen, toggle] = useState(false)

  return (
    <ClickOutside
      handleClickOutside={() => toggle(false)}
      handleClick={() => toggle(!isOpen)}
    >
      <StyledContainer>
        <StyledToggle>
          {title}
          {isCaret && <Icon.Chevron />}
        </StyledToggle>
        {isOpen && (
          <StyledList $direction={direction || DropdownDirectionEnum.DOWN}>
            {items.map((item) => {
              if ('to' in item) {
                return (
                  <StyledLinkItem
                    key={item.key}
                    to={item.to}
                    onClick={item?.onClick}
                  >
                    {item.value}
                  </StyledLinkItem>
                )
              }

              return (
                <StyledButtonItem key={item.key} onClick={item.onClick}>
                  {item.value}
                </StyledButtonItem>
              )
            })}
          </StyledList>
        )}
      </StyledContainer>
    </ClickOutside>
  )
}
