import {
  Session,
  UiNode,
  UpdateLoginFlowBody,
  LoginFlow,
  RegistrationFlow,
  RecoveryFlow,
  UpdateRecoveryFlowBody,
  SettingsFlow,
  UpdateSettingsFlowBody,
  VerificationFlow,
  UpdateVerificationFlowBody,
} from '@ory/client'

import { retryOryAction } from '@web-apps/utils-shared'
import { ory } from './configuration'
import { handleGetFlowError, mapOryFormSubmissionErrorCodes } from './errors'
import {
  UiContainer,
  UiNodeInputAttributes,
  UpdateRegistrationFlowBody,
} from '@ory/client/api'
import { AxiosResponse } from 'axios'

export const getUserAuthSession = async (): Promise<Session | null> => {
  const session = await retryOryAction(() => ory.frontend.toSession())
  return session.data
}

export const initiateLogoutFlow = async (): Promise<{
  url?: string
  token?: string
  error?: {
    message?: string
    status: number
  }
}> => {
  try {
    const { data } = await retryOryAction(() =>
      ory.frontend.createBrowserLogoutFlow()
    )
    return { url: data.logout_url, token: data.logout_token }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      error: {
        message: handleGetFlowError(error, 'initiateLogoutFlow'),
        status: error?.response?.status,
      },
    }
  }
}

export const submitLogoutFlow = async ({
  token,
}: {
  token?: string
}): Promise<null | { error?: string }> => {
  try {
    await retryOryAction(() => ory.frontend.updateLogoutFlow({ token }))
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: handleGetFlowError(error, 'submitLogoutFlow') }
  }
}

export const initiateLoginFlow = async (
  flowId?: string,
  refresh?: boolean,
  returnToUrl?: string
): Promise<{
  flow?: LoginFlow
  error?: string
}> => {
  // If a flow already exists, we proceed to creating a login flow
  if (flowId) {
    try {
      const flow = await retryOryAction(() =>
        ory.frontend.getLoginFlow({ id: flowId })
      )
      return { flow: flow.data }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        error: handleGetFlowError(error, 'initiateLoginFlow:getLoginFlow'),
      }
    }
  }

  // Otherwise, we initialize the flow manually
  try {
    const flow = await retryOryAction(() =>
      ory.frontend.createBrowserLoginFlow({
        refresh: true,
        returnTo: returnToUrl,
      })
    )
    return { flow: flow.data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      error: handleGetFlowError(
        error,
        'initiateLoginFlow:createBrowserLoginFlow'
      ),
    }
  }
}

export const submitLoginFlow = async (
  flow: LoginFlow,
  formValues: UpdateLoginFlowBody,
  onLogin?: () => void
): Promise<{ error?: string } | null> => {
  try {
    await retryOryAction(() =>
      ory.frontend.updateLoginFlow({
        flow: flow?.id,
        updateLoginFlowBody: formValues,
      })
    )
    onLogin?.()
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessages: { id: number; type: 'error' }[] =
      error?.response?.data?.ui?.messages
    if (errorMessages && errorMessages.length)
      // Showing only one error at a time
      return {
        error: mapOryFormSubmissionErrorCodes({
          error: errorMessages,
          errorCode: errorMessages[0].id,
          action: 'submitLoginFlow',
          config: error?.config,
        }),
      }
    return { error: handleGetFlowError(error, 'submitLoginFlow') }
  }
}

export const initiateRegistrationFlow = async ({
  flowId,
  returnToUrl,
}: {
  flowId?: string
  returnToUrl?: string
}): Promise<{
  flow?: RegistrationFlow
  error?: string
  failedEmail?: string
  failedProvider?: string
}> => {
  // If a flow already exists, we proceed to creating a register flow
  if (flowId) {
    try {
      const flow = await retryOryAction(() =>
        ory.frontend.getRegistrationFlow({ id: flowId })
      )
      const oryUiResponse: UiContainer = flow?.data?.ui

      // If user tries to register with an oid that is associated with an email
      // that is already taken, we need to show the error and proceed with the
      // new flow
      let errorMessages,
        failedEmail:
          | (UiNode & { attributes: UiNodeInputAttributes })
          | undefined,
        failedProvider:
          | (UiNode & { attributes: UiNodeInputAttributes })
          | undefined
      if (oryUiResponse) {
        errorMessages = oryUiResponse.messages
        const nodes = oryUiResponse.nodes as (UiNode & {
          attributes: UiNodeInputAttributes
        })[]
        failedEmail = nodes.find((node: UiNode) => {
          if ('name' in node.attributes) {
            return node.attributes.name === 'traits.email'
          }
          return undefined
        })
        failedProvider = nodes.find((node: UiNode) => {
          if ('name' in node.attributes) {
            return node.attributes.name === 'provider'
          }
          return undefined
        })
      }

      return {
        flow: flow.data,
        error:
          errorMessages && errorMessages.length
            ? mapOryFormSubmissionErrorCodes({
                error: errorMessages,
                errorCode: errorMessages[0].id,
                action: 'initiateRegistrationFlow:getRegistrationFlow',
              })
            : undefined,
        failedEmail: failedEmail?.attributes?.value,
        failedProvider: failedProvider?.attributes?.value,
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        error: handleGetFlowError(
          error,
          'initiateRegistrationFlow:getRegistrationFlow'
        ),
      }
    }
  }

  // Otherwise, we initialize the flow manually
  try {
    const flow = await retryOryAction(() =>
      ory.frontend.createBrowserRegistrationFlow({ returnTo: returnToUrl })
    )
    return { flow: flow.data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      error: handleGetFlowError(
        error,
        'initiateRegistrationFlow:createBrowserRegistrationFlow'
      ),
    }
  }
}

export const submitRegistrationFlow = async (
  flow: RegistrationFlow,
  formValues: UpdateRegistrationFlowBody
): Promise<{ error?: string } | null> => {
  try {
    await retryOryAction(() =>
      ory.frontend.updateRegistrationFlow({
        flow: flow?.id,
        updateRegistrationFlowBody: formValues,
      })
    )
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const oryUiResponse = error?.response?.data?.ui

    // For registration flow ory can send errors in 2 variants: in the `messages`
    // array or separately in the nodes array. We have to handle both here
    const errorMessages: { id: number; type: 'error' }[] =
      oryUiResponse?.messages
    if (errorMessages && errorMessages.length)
      return {
        error: mapOryFormSubmissionErrorCodes({
          error: errorMessages,
          errorCode: errorMessages[0].id,
          action: 'submitRegistrationFlow',
          config: error?.config,
        }),
      }
    if (oryUiResponse?.nodes) {
      const errorMessage = oryUiResponse.nodes.find(
        (node: UiNode) => node.messages?.length
      )

      if (errorMessage) {
        return {
          error: mapOryFormSubmissionErrorCodes({
            error: errorMessages,
            errorCode: errorMessages[0].id,
            action: 'submitRegistrationFlow',
            config: error?.config,
          }),
        }
      }
    }
    return { error: handleGetFlowError(error, 'submitRegistrationFlow') }
  }
}

export const initiateRecoverFlow = async (
  flowId?: string
): Promise<{ flow?: RecoveryFlow; error?: string }> => {
  // If a flow already exists, we proceed to creating a recovery flow
  if (flowId) {
    try {
      const flow: AxiosResponse = await retryOryAction(() =>
        ory.frontend.getRecoveryFlow({ id: flowId })
      )
      return { flow: flow.data }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        error: handleGetFlowError(
          error,
          'initiateRecoverFlow:createBrowserRecoveryFlow'
        ),
      }
    }
  }

  // Otherwise, we initialize the flow manually
  try {
    const flow = await retryOryAction(() =>
      ory.frontend.createBrowserRecoveryFlow()
    )
    return { flow: flow.data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return {
      error: handleGetFlowError(
        error,
        'initiateRecoverFlow:createBrowserRecoveryFlow'
      ),
    }
  }
}

export const submitRecoverFlow = async (
  flow: RecoveryFlow,
  formValues: UpdateRecoveryFlowBody,
  onLogin: () => void
): Promise<null | { error?: string }> => {
  try {
    await retryOryAction(() =>
      ory.frontend.updateRecoveryFlow({
        flow: flow?.id,
        updateRecoveryFlowBody: formValues,
      })
    )
    onLogin()
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const oryUiResponse = error?.response?.data?.ui
    if (oryUiResponse?.nodes) {
      const errorMessage = oryUiResponse.nodes.find(
        (node: UiNode) => node.messages?.length
      )

      if (errorMessage) {
        return {
          error: mapOryFormSubmissionErrorCodes({
            error: errorMessage,
            errorCode: errorMessage.messages[0].id,
            action: 'submitRecoverFlow',
            config: error?.config,
          }),
        }
      }
    }

    return { error: handleGetFlowError(error, 'submitRecoverFlow') }
  }
}

export const initiateSettingsFlow = async (): Promise<{
  flow?: SettingsFlow
  error?: string
}> => {
  try {
    const flow = await retryOryAction(() =>
      ory.frontend.createBrowserSettingsFlow()
    )
    return { flow: flow.data }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: handleGetFlowError(error, 'initiateSettingsFlow') }
  }
}

export const submitSettingsFlow = async (
  flow: SettingsFlow,
  formValues: UpdateSettingsFlowBody
): Promise<null | { error?: string }> => {
  try {
    await retryOryAction(() =>
      ory.frontend.updateSettingsFlow({
        flow: flow?.id,
        updateSettingsFlowBody: formValues,
      })
    )
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const oryUiResponse = error?.response?.data?.ui
    if (oryUiResponse?.nodes) {
      const errorMessage = oryUiResponse.nodes.find(
        (node: UiNode) => node.messages?.length
      )

      if (errorMessage) {
        return {
          error: mapOryFormSubmissionErrorCodes({
            error: errorMessage,
            errorCode: errorMessage.messages[0].id,
            action: 'submitSettingsFlow',
            config: error?.config,
          }),
        }
      }
    }

    return { error: handleGetFlowError(error, 'submitSettingsFlow') }
  }
}

export const initiateVerifyEmailFlow = async (
  options?: {
    flowId: string
  } | void
): Promise<{
  flow?: VerificationFlow
  error?: string
}> => {
  let flow: VerificationFlow
  if (options?.flowId) {
    try {
      const response = await retryOryAction(() =>
        ory.frontend.getVerificationFlow({ id: flow.id })
      )
      flow = response.data

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        error: handleGetFlowError(
          error,
          'initiateVerifyEmailFlow:getSelfServiceVerificationFlow'
        ),
      }
    }
  } else {
    try {
      const response = await retryOryAction(() =>
        ory.frontend.createBrowserVerificationFlow()
      )
      flow = response.data

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return {
        error: handleGetFlowError(
          error,
          'initiateVerifyEmailFlow:initializeSelfServiceVerificationFlowForBrowsers'
        ),
      }
    }
  }

  if (flow.ui.messages?.length)
    return {
      error: mapOryFormSubmissionErrorCodes({
        error: flow.ui.messages,
        errorCode: flow.ui.messages[0].id,
        action:
          'initiateVerifyEmailFlow:initializeSelfServiceVerificationFlowForBrowsers',
      }),
    }

  return { flow }
}

export const submitVerifyEmailFlow = async (
  flow: VerificationFlow,
  formValues: UpdateVerificationFlowBody
): Promise<null | { error?: string }> => {
  try {
    await retryOryAction(() =>
      ory.frontend.updateVerificationFlow({
        flow: flow?.id,
        updateVerificationFlowBody: formValues,
      })
    )
    return null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const oryUiResponse = error?.response?.data?.ui
    if (oryUiResponse?.nodes) {
      const errorMessage = oryUiResponse.nodes.find(
        (node: UiNode) => node.messages?.length
      )

      if (errorMessage) {
        return {
          error: mapOryFormSubmissionErrorCodes({
            error: errorMessage,
            errorCode: errorMessage.messages[0].id,
            action: 'submitVerifyEmailFlow',
            config: error?.config,
          }),
        }
      }
    }

    return { error: handleGetFlowError(error, 'submitVerifyEmailFlow') }
  }
}
