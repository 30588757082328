import styled from 'styled-components'

import { Box, Card, Typography } from '../../atoms'
import { Theme } from '../../theme'

type InfoCardProps = {
  topEmoji?: string
  title: string
  subtitle?: string
  cta?: JSX.Element
}

const StyledCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 100%;
  flex: 1;

  background: ${Theme.Colors.lightSand};

  text-align: center;
`

const StyledEmojiContainer = styled.div`
  line-height: 24px;
  font-size: 24px;
  font-weight: 500;
`

export const InfoCard = ({ topEmoji, title, subtitle, cta }: InfoCardProps) => (
  <StyledCardContainer>
    {topEmoji && <StyledEmojiContainer>{topEmoji}</StyledEmojiContainer>}
    <Typography variant="h4" fontWeight={500}>
      {title}
    </Typography>
    {subtitle && (
      <Typography variant="hint" fontWeight={500}>
        {subtitle}
      </Typography>
    )}
    {cta && <Box mt={8}>{cta}</Box>}
  </StyledCardContainer>
)
