import styled from 'styled-components'

import { BaseFieldCSS } from '../../styles/form.styles'
import { Box } from '../../atoms'
import { Theme } from '../../theme'

export const StyledContainer = styled.label`
  display: block;
  margin: 0;
`
export const StyledTextAreaContainer = styled(Box)`
  position: relative;
  height: 152px;
`

export const StyledTextArea = styled.textarea<{ $hasError: boolean }>`
  height: 100%;
  padding-right: 30px;
  resize: none;

  ${({ $hasError }) => BaseFieldCSS({ hasError: $hasError })}
  &:disabled {
    background-color: ${Theme.Colors.background.inactive};
  }

  &:focus {
    outline: none;
  }

  &:read-only {
    color: ${Theme.Colors.typography.inactive};
  }
`
