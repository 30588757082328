import dayjs, { ManipulateType } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DateType, TimePartsType } from '@web-apps/utils-types'

import { Localisation } from '../localisation'
import { OVERRIDE_LOCALE_COOKIE } from '../localisation/localisation.constants'

dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

const dateInstanceBuilder = (date: DateType) => dayjs(date)

const getDifferenceInMilliseconds = (
  startDate: DateType,
  endDate: DateType
): number => {
  return dayjs(endDate).utc().diff(dayjs(startDate).utc(), 'milliseconds')
}

const getCurrentDateInIso = () => {
  const date = dateInstanceBuilder(new Date())
  return date.toISOString()
}

const getTimePartsForSeconds = (totalSeconds: number): TimePartsType => {
  const durationObject = dayjs.duration(totalSeconds, 'seconds')
  const months = durationObject.months()
  const days = durationObject.days()
  const hours = durationObject.hours()
  const minutes = durationObject.minutes()
  const seconds = durationObject.seconds()

  return { months, days, hours, minutes, seconds }
}

const getSecondsOfDateToNow = (date: DateType) =>
  getDifferenceInMilliseconds(date, getCurrentDateInIso()) / 1000

const getTimePartsOfDateToNow = (date: DateType) =>
  getTimePartsForSeconds(getSecondsOfDateToNow(date))

const getSecondsOfNowToDate = (date: DateType) =>
  getDifferenceInMilliseconds(getCurrentDateInIso(), date) / 1000

const getTimePartsOfNowToDate = (date: DateType) =>
  getTimePartsForSeconds(getSecondsOfNowToDate(date))

const defaultDateStyle = 'medium'
const defaultDateStyleByParts: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
}

type FormatDateOptions = { overrideLocale?: string }
type DateTimeFormatterInterface = (
  date: DateType,
  options?: FormatDateOptions
) => string

const formatDateHighOrder = (
  date: DateType,
  options: Intl.DateTimeFormatOptions & FormatDateOptions
) => {
  return Intl.DateTimeFormat(
    options?.overrideLocale ||
      (typeof window !== 'undefined' &&
        localStorage.getItem(OVERRIDE_LOCALE_COOKIE)) ||
      Localisation.DEVICE_LOCALE,
    options
  ).format(dateInstanceBuilder(date).toDate())
}

const formatDateToText = (date: DateType, options = {}): string =>
  formatDateHighOrder(date, {
    dateStyle: defaultDateStyle,
    ...options,
  })

const formatDateToMonthYear: DateTimeFormatterInterface = (
  date,
  options = {}
) =>
  formatDateHighOrder(date, {
    month: defaultDateStyleByParts.month,
    year: defaultDateStyleByParts.year,
    ...options,
  })

const formatDateToDayMonth: DateTimeFormatterInterface = (date, options = {}) =>
  formatDateHighOrder(date, {
    day: defaultDateStyleByParts.day,
    month: defaultDateStyleByParts.month,
    ...options,
  })

const formatDateMonthOnly: DateTimeFormatterInterface = (date, options = {}) =>
  formatDateHighOrder(date, {
    month: defaultDateStyleByParts.month,
    ...options,
  })

export const getFormatDistanceFromNowToDate = (startDate: string) => {
  return dayjs(startDate).fromNow(true)
}

export const getDateInPast = (amount: number, unit: ManipulateType) => {
  return dayjs().subtract(amount, unit)
}

export const format = (date: DateType, template: string) => {
  return dayjs(date).format(template)
}

export const DateHelpers = {
  dateInstanceBuilder,
  getDifferenceInMilliseconds,
  getCurrentDateInIso,
  getTimePartsForSeconds,
  getSecondsOfDateToNow,
  getTimePartsOfDateToNow,
  getSecondsOfNowToDate,
  getTimePartsOfNowToDate,
  formatDateToMonthYear,
  formatDateToDayMonth,
  formatDateMonthOnly,
  formatDateToText,
  getFormatDistanceFromNowToDate,
  getDateInPast,
  format,
}
