import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import cloneDeep from 'lodash.clonedeep'
import {
  createApi,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { HYDRATE } from 'next-redux-wrapper'
import * as Sentry from '@sentry/browser'

import { clientConfig, PRODUCTION_HOST, urlAuthConfig } from '../config'
import { getParameterizedPathForApiPath } from '../monitoring'
import {
  ApiErrorTypeEnum,
  isCriteriaMatchedForApiErrorResponse,
} from '../error'

export const HTTP_CLIENT_REQUESTS_TIMEOUT_IN_MS = 30000

const FAILURES_409_ENDPOINTS_TO_IGNORE_MONITORING = [
  '/validations/slug',
  '/validations/email',
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trimAll = (obj: any) => {
  for (const prop in obj) {
    if (typeof obj[prop] === 'string') {
      obj[prop] = obj[prop].trim()
    } else {
      trimAll(obj[prop])
    }
  }
}

let apiInstance: AxiosInstance
export const initAPI = ({
  baseURL,
}: {
  baseURL: string
  headers?: AxiosRequestConfig['headers']
}) => {
  apiInstance = Axios.create({
    baseURL: baseURL,
    withCredentials: true,
    timeout: HTTP_CLIENT_REQUESTS_TIMEOUT_IN_MS,
  })

  // this is just to avoid errors while testing when Axios is mocked
  if (!apiInstance) return

  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status
      const url = error.config.url

      if (status === 403) {
        window.location.reload()

        return Promise.reject(error)
      }

      if (status === 401) {
        const host = clientConfig?.HOSTNAME || PRODUCTION_HOST
        window.location.href = urlAuthConfig[host]?.login

        return Promise.reject(error)
      }

      // Ignore 409 handled responses
      if (
        FAILURES_409_ENDPOINTS_TO_IGNORE_MONITORING.includes(url) &&
        status === 409
      ) {
        return Promise.reject(error)
      }

      // 400 for metapic users handled response
      // to ignore errors like this on Sentry: https://zezam.sentry.io/issues/3783746499/?environment=production&project=6232654
      if (
        isCriteriaMatchedForApiErrorResponse(
          { status: 400, type: ApiErrorTypeEnum.UserEmailNotAllowed },
          error.response
        )
      ) {
        return Promise.reject(error)
      }

      // We should ignore 404 and  should not track it
      if (status >= 400 && status < 500 && status !== 404) {
        const parameterizedPath = getParameterizedPathForApiPath(url)
        Sentry.configureScope((scope) =>
          scope
            .setFingerprint([`server-error-${parameterizedPath}-${status}`])
            .setTags({ operations: 'API' })
            .setLevel('error')
            .setExtra(
              'request-details',
              JSON.stringify(error.response.config, null, 2)
            )
            .setExtra('error', JSON.stringify(error.response.data, null, 2))
        )
        Sentry.captureException(new Error(`API error on ${parameterizedPath}`))
      }

      return Promise.reject(error)
    }
  )
}

if (process.env.NODE_ENV === 'test') {
  initAPI({ baseURL: '/' })
}

/* Redux toolkit query configs */

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      path: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      baseUrl?: AxiosRequestConfig['baseURL']
      headers?: AxiosRequestConfig['headers']
      withCredentials?: AxiosRequestConfig['withCredentials']
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    unknown
  > =>
  async ({ path, method, data, headers, withCredentials = true }) => {
    if (!apiInstance) {
      return {
        error: { status: 404 },
      }
    }

    let payloadData = cloneDeep(data)
    try {
      // when passing a FromData to trimAll function it gets corrupted
      if (data instanceof FormData) throw new Error('Not Trimmable')
      trimAll(payloadData)
      // just in case something unexpected happens, we use original data
    } catch {
      payloadData = data
    }

    try {
      const result = await apiInstance({
        url: path,
        method,
        data: payloadData,
        headers,
        withCredentials,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
          code: err.code,
          raw_error: err,
        },
      }
    }
  }

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'CreatorDetails',
    'CreatorPage',
    'CreatorPageAnalyticsPage',
    'CreatorPageCommerceSettings',
    'ContactListDetails',
    'UserAccount',
    'AffiliateLinks',
    'RecentAffiliateLinks',
    'UserAffiliateBalance',
    'AdminOffers',
    'AdminTopOffers',
    'AdminBrands',
    'APIConfigs',
    'DashboardGetStartedFrontendActions',
    'PayoutInfo',
    'DashboardStarterChallenge',
    'ContainerSections',
  ],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: () => ({}),
})

export const isFetchBaseQueryErrorType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
): error is FetchBaseQueryError => Boolean(error?.status)

export const callApiEndpoint = async (
  key: string,
  _queryApi: BaseQueryApi,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any
) => {
  // eslint-disable-next-line no-prototype-builtins
  if (!api.endpoints.hasOwnProperty(key)) {
    throw new Error(`Invalid api endpoint name: ${key}`)
  }

  const args =
    typeof params === 'string'
      ? params
      : Object.assign(params, { stopInvalidatesTags: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, error } = (await (api.endpoints as any)[key].initiate(
    args,
    params.forceRefetch && {
      forceRefetch: true,
    }
  )(_queryApi.dispatch, _queryApi.getState, _queryApi.extra)) as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any
  }

  if (error) throw error

  return data
}

export { apiInstance }
