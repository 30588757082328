import * as Sentry from '@sentry/nextjs'

const delay = (fn: () => Promise<unknown>, ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(fn()), ms))

const randInt = (min = 1, max = 9) =>
  Math.floor(Math.random() * (max - min + 1) + min)

export const retryOryAction = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: () => Promise<any>,
  maxAttempts = 3
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const execute: (attempt: number) => Promise<any> = async (
    attempt: number
  ) => {
    try {
      return await fn()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // We never want to retry some Ory errors as they are intentional and have no need to be retried
      if ([401, 404, 400, 410, 422].includes(error.response?.status)) {
        throw error
      } else {
        // TODO: this is temporary Sentry logging to see how many retries we have and to make sure retries work as expected
        Sentry.withScope(function (scope) {
          scope
            .setTag('operations', 'ory')
            .setLevel('info')
            .setExtra('attemptCount', attempt)
            .setFingerprint(['retry ory'])
          Sentry.captureException(new Error(`Ory operation retried`))
        })

        if (attempt <= maxAttempts) {
          const nextAttempt = attempt + 1
          const delayInMs = nextAttempt * 1000 + randInt() * 100
          return delay(() => execute(nextAttempt), delayInMs)
        } else {
          throw error
        }
      }
    }
  }
  return execute(1)
}
