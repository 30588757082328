import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { emptyFunctionForOptionals } from '@web-apps/utils-shared'

export type ShareLinkType = {
  url?: string
  copyUrl?: string
  container?: HTMLElement | null
} | null

export const ShareLinkContext = createContext<
  ShareLinkType & {
    setShareLink: Dispatch<SetStateAction<ShareLinkType>>
  }
>({
  url: '',
  container: typeof window === 'undefined' ? null : document.body,
  setShareLink: emptyFunctionForOptionals,
})

export const useShareLinkData = () => useContext(ShareLinkContext)
