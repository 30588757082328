import { PriceType } from './money.types'
import { ResponseList } from './common.types'

export enum PayoutInfoStatus {
  NOT_SET = 'NOT_SET',
  VALID = 'VALID',
}

export type PayoutInfoType =
  | {
      status: PayoutInfoStatus.NOT_SET
    }
  | {
      status: PayoutInfoStatus.VALID
      payment_info: {
        iban: string
        swift: string
        account_holder_name: string
      }
      name: string
      vat: string
      small_entrepreneur: boolean
      street: string
      postal_code: string
      city: string
      country: string
      phone_number: string
      email: string
      company_name?: string
    }

export type PayoutInfoFormType = {
  status: PayoutInfoStatus
  name: string
  iban: string
  swift: string
  accountHolderName: string
  vat: string
  smallEntrepreneur: boolean
  street: string
  postalCode: string
  city: string
  country: string
  phone: string
  email: string
  companyName?: string
}

export type PayoutsHistoryAPIResponse = ResponseList<{
  amount: PriceType
  paid_amount: PriceType
  paid_at: string
  invoice_number: string
}>

export type PayoutType = {
  amount: string
  invoiceNumber: string
  paidDate: string
}

export type PayoutsHistoryType = {
  payouts: PayoutType[]
  payoutsCount: number
  prevPage?: string
  nextPage?: string
}
