import React, {
  HTMLAttributes,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { Typography } from '../Typography'
import { Box } from '../Box'

import { Theme } from '../../theme'
import { media, BREAKPOINTS } from '../../helpers'

type LinkProps = Omit<React.ComponentProps<typeof Link>, 'title'>

export const BaseCardCSS = css`
  background: ${Theme.Colors.background.base};
  border: 1px solid ${Theme.Card.borderColor};
  border-radius: ${Theme.Card.borderRadiusInPx}px;
  padding: 16px;

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 24px;
    `
  )};
`

/*
 * adding hasFlex to have it full width when div and a. It is full width be default when "div" is used
 * when "a" element is used to have that effect we need to make it display: flex.
 *
 * Adding flex to all cards would break some of our current components, that is the reason why I opted for this
 * solution. Check the according story book
 */
const StyledCard = styled.div<{ $hasFlex?: boolean }>`
  ${BaseCardCSS};

  ${({ $hasFlex = false }) => ($hasFlex ? 'display: flex;' : '')}
`

type DivProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'>
type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'title'>
type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'>

export type CardProps = {
  headerTitle?: string | JSX.Element
} & (
  | ({ as?: 'div' } & DivProps)
  | ({ as?: 'a' } & AnchorProps)
  | ({ as?: 'button' } & ButtonProps)
  | ({ as?: typeof Link } & LinkProps)
)

const isLinkableComponent = ({ as }: { as: CardProps['as'] }) =>
  as === 'a' || as === Link

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Card = React.forwardRef<any, CardProps>(
  ({ headerTitle, as = 'div', children, ...props }, ref) => (
    <StyledCard
      as={as}
      ref={ref}
      $hasFlex={isLinkableComponent({ as })}
      {...props}
    >
      {headerTitle && (
        <Box mb={16}>
          <Typography variant="h3">{headerTitle}</Typography>
        </Box>
      )}
      {children}
    </StyledCard>
  )
)
