import React from 'react'
import styled from 'styled-components'

import { setUnity } from '../../helpers'

type StyledContainerProps = {
  $color?: string
  $opacity?: number
  $width?: number
  $height?: number
  $mr?: number | string
  $ml?: number | string
  $mt?: number | string
  $mb?: number | string
  $verticalAlign?: string
}
const StyledContainer = styled.div<StyledContainerProps>`
  display: inline-block;
  vertical-align: ${({ $verticalAlign }) => $verticalAlign || 'text-bottom'};
  ${({ $width }) => $width && `width: ${setUnity($width)};`}
  ${({ $height }) => $height && `height: ${setUnity($height)};`}
  ${({ $ml }) => $ml && `margin-left: ${setUnity($ml)};`}
  ${({ $mr }) => $mr && `margin-right: ${setUnity($mr)};`}
  ${({ $mt }) => $mt && `margin-top: ${setUnity($mt)};`}
  ${({ $mb }) => $mb && `margin-bottom: ${setUnity($mb)};`}
  svg {
    display: block;
    ${({ $width }) => $width && `width: ${$width}px;`}
    ${({ $height }) => $height && `height: ${$height}px;`}
  }

  svg,
  path {
    ${({ $color }) => $color && `fill: ${$color};`}
    ${({ $opacity }) => $opacity && `opacity: ${$opacity}`}
  }
`

type SvgIconProps = {
  children: React.ReactNode
  color?: string
  opacity?: number
  width?: number
  height?: number
  mr?: number
  ml?: number
  mt?: number
  mb?: number
  verticalAlign?: string
}
export const SvgIcon = ({
  children,
  color,
  opacity,
  width,
  height,
  mr,
  ml,
  mt,
  mb,
  verticalAlign,
}: SvgIconProps) => {
  return (
    <StyledContainer
      $color={color}
      $opacity={opacity}
      $width={width}
      $height={height}
      $mr={mr}
      $ml={ml}
      $mt={mt}
      $mb={mb}
      $verticalAlign={verticalAlign}
      as="span"
    >
      {children}
    </StyledContainer>
  )
}
