import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { DialogContent as ReachUIDialog, DialogOverlay } from '@reach/dialog'
import { slideUp, fadeIn, fadeOut } from '../../styles'

import '@reach/dialog/styles.css'

import { BaseCardCSS } from '../../atoms'
import { media, BREAKPOINTS } from '../../helpers'
import { Theme } from '../../theme'

type StyledDialogProps = {
  $justify: CSSProperties['justifyContent']
  $hasPaddingBottom: boolean
}

export const INLINE_PADDING_PX = 16
export const MOBILE_BOTTOM_PADDING_PX = 16
export const DESKTOP_BOTTOM_PADDING_PX = 24

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: sticky;
  top: 0;
  background: inherit;
  z-index: 1;

  padding-inline: 24px;
`

export const StyledDialog = styled(ReachUIDialog)<{
  $maxWidthNotMobile?: CSSProperties['maxWidth']
  $opacity: CSSProperties['opacity']
  $maxHeight?: boolean
  $height?: string
}>`
  ${BaseCardCSS};

  /* !important is needed to override ReachUIDialog css */
  padding-top: 0 !important;
  padding-inline: 0 !important;
  padding-bottom: ${MOBILE_BOTTOM_PADDING_PX}px !important;

  border: none;
  overflow: auto;
  width: 100vw !important;
  ${({ $maxHeight }) => $maxHeight && 'height: 90vh;'};
  ${({ $height }) => $height && `height: ${$height}`};

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 90vh;
  margin: 0 auto !important;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  background-color: rgba(255, 255, 255, ${({ $opacity }) => $opacity});
  ${({ $opacity }) => ($opacity === 1 ? '' : 'backdrop-filter: blur(4px);')};
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: ease;

  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${slideUp};
  }

  ${({ $maxWidthNotMobile = '664px' }) =>
    media.from(
      BREAKPOINTS.sm,
      `max-width: min(calc(100% - 16px), ${$maxWidthNotMobile});`
    )}

  ${media.from(
    BREAKPOINTS.sm,
    css`
      ${BaseCardCSS};

      border-radius: ${Theme.Card.borderRadiusInPx}px;
      border-radius: ${Theme.Card.borderRadiusInPx}px;
      width: auto;
      margin: 0 auto !important;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding-bottom: ${DESKTOP_BOTTOM_PADDING_PX}px !important;

      @media (prefers-reduced-motion: no-preference) {
        animation-name: ${fadeIn};
      }
    `
  )}
`

export const StyledHeaderInfoContainer = styled.div<StyledDialogProps>`
  display: flex;
  justify-content: ${({ $justify = 'center' }) => $justify};
  align-items: center;
  padding-top: 24px;
  ${({ $hasPaddingBottom }) => $hasPaddingBottom && 'padding-bottom: 24px;'}
`

export const StyledDialogOverlay = styled(DialogOverlay)<{
  $isVisible?: boolean
}>`
  z-index: 101;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      animation: ${fadeIn} 0.75s ease forwards;
    `}

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      animation: ${fadeOut} 0.75s ease forwards;
    `}
`

export const StyledDialogContentContent = styled.div<{ $fullHeight?: boolean }>`
  padding-block-start: 24px;
  padding-inline: 24px;
  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'}
`

export const StyledSeparator = styled.div`
  height: 1px;
  background: ${Theme.Colors.line};
  margin-inline: -${INLINE_PADDING_PX}px;
`

export const StyledCloseIconContainer = styled.div`
  margin-bottom: auto;
`
