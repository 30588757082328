import React, { InputHTMLAttributes } from 'react'
import { ControllerFieldState } from 'react-hook-form'
import { StyledPhoneInput } from './PhoneField.styles'
import { StyledError, StyledLabelText } from '../../styles'
import { Flex, Typography } from '../../atoms'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  labelDecorator?: string
  labelRightDecorator?: JSX.Element
  name: string
  error?: ControllerFieldState['error']
  /* This is needed when using our form with react-hook-form and we want to do extra things with ref: https://www.react-hook-form.com/faqs/#Howtosharerefusage */
  refHandler?: (instance: HTMLInputElement) => void
}

export const PhoneField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      labelDecorator,
      labelRightDecorator,
      error,
      refHandler,
      ...restProps
    }: Props,
    ref
  ) => {
    const hasError = !!error

    return (
      <div>
        {label && (
          <Flex justify="space-between">
            <StyledLabelText
              hasError={hasError}
              htmlFor={`input-phone-${label}`}
            >
              {label}{' '}
              {labelDecorator && (
                <Typography
                  as="span"
                  color={hasError ? 'error' : 'inactive'}
                  variant="hint"
                >
                  {labelDecorator}
                </Typography>
              )}
            </StyledLabelText>
            {labelRightDecorator}
          </Flex>
        )}
        <StyledPhoneInput
          id={`input-phone-${label}`}
          $hasError={hasError}
          {...restProps}
          ref={
            refHandler
              ? (instance: HTMLInputElement | null) => {
                  if (!instance || typeof ref !== 'function') return

                  ref(instance)
                  refHandler(instance)
                }
              : ref
          }
        />
        {error && <StyledError>{error.message}</StyledError>}
      </div>
    )
  }
)
