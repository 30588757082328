import React from 'react'
import { AnyStyledComponent } from 'styled-components'

import { TypographyProps } from '../../atoms/Typography/Typography'
import { ButtonProps } from '../Button/Button'

export type StatsData = {
  id: string
  icon?: JSX.Element
  text: string | React.ReactNode
  subtitle?: string | React.ReactNode
  hint?: string | React.ReactNode
  value: number
  customizableValueOptions?: {
    valueFormatter?: (value: number) => string
    textColor?: TypographyProps['color']
  }
  as?: AnyStyledComponent['as']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & { [propKey: string]: any }

export type ColumnsData = {
  title: string
}

export type Messages = {
  noData: string
  callToActionInfo: string
  callToActionButtonText: string
}

export enum ContainerHeightVariant {
  Small = 'small',
  Medium = 'medium',
}

export enum AnalyticsBarPanelSkeletonVariant {
  Default = 'default',
  Affiliates = 'affiliates-list',
}

export type AnalyticsBarPanelProps = {
  title: string
  columns: [ColumnsData, ColumnsData]
  stats?: StatsData[]
  disabled?: boolean
  messages: Messages
  callToActionButtonProps?: Omit<ButtonProps, 'children'>
  isLoading?: boolean
  onBottomScrollReached?: () => void
  containerHeightVariant?: ContainerHeightVariant
  skeletonVariant?: AnalyticsBarPanelSkeletonVariant
}
