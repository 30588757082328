import styled, { css } from 'styled-components'

import { Box } from '../../atoms'

import { Theme } from '../../theme'
import { BaseFieldCSS } from '../../styles'
import { TextFieldSize } from './TextField'

export const StyledContainer = styled.div`
  display: block;

  margin: 0;
  width: 100%;
`

export const StyledInputContainer = styled(Box)`
  position: relative;
`

export const StyledInput = styled.input<{
  $hasLeftDecorator?: boolean
  $hasRightDecorator?: boolean
  $borderless?: boolean
  $size: TextFieldSize
  $hasError: boolean
}>`
  ${({ $hasError }) => BaseFieldCSS({ hasError: $hasError })}
  ${({ $size }) => {
    switch ($size) {
      case TextFieldSize.NANO:
        return css`
          padding: 4px 8px;
          font-size: 14px;
          line-height: 16px;
        `
      case TextFieldSize.SLIM:
        return css`
          padding: 12px 16px;
          font-size: 14px;
          line-height: 16px;
        `
      case TextFieldSize.HUGE:
        return css`
          font-size: 32px;
          line-height: 40px;
          padding-top: 0;
          padding-bottom: 0;
          text-align: center;
        `
      case TextFieldSize.REGULAR:
      default:
        return css`
          padding: 15px 16px;
          font-size: 16px;
          line-height: 24px;
        `
    }
  }}
  ${({ $hasLeftDecorator }) => $hasLeftDecorator && `padding-left: 48px;`}
  ${({ $hasRightDecorator }) => $hasRightDecorator && `padding-right: 48px;`}
  ${({ $borderless }) => $borderless && `border: 1px solid transparent;`}
  &:disabled {
    background-color: ${Theme.Colors.background.inactive};
  }

  &:read-only {
    color: ${Theme.Colors.typography.inactive};
  }

  &:focus {
    outline: none;
  }
`

export const StyledLeftDecoratorContainer = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;

  display: flex;
  align-items: center;
`

export const StyledRightDecoratorContainer = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;

  display: flex;
  align-items: center;
`
