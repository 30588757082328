import { HTMLAttributes, forwardRef } from 'react'

import { Flex, Typography } from '../../atoms'
import { Theme } from '../../theme'
import { IconButton } from '../IconButton'

import { StyledCloseContainer, StyledCloseBtn } from './Popover.styles'

type PopoverHeaderProps = HTMLAttributes<HTMLDivElement> & {
  text: string
  onClose: () => void
  onBackClick?: () => void
  withArrowBack?: boolean
  hideOnDesktop?: boolean
  enableCloseButtonOnDesktop?: boolean
}

export const PopoverHeader = forwardRef<HTMLDivElement, PopoverHeaderProps>(
  (
    {
      text,
      onClose,
      withArrowBack = false,
      hideOnDesktop = true,
      enableCloseButtonOnDesktop = false,
      onBackClick,
      ...divProps
    },
    ref
  ) => {
    return (
      <StyledCloseContainer
        {...divProps}
        $hideOnDesktop={hideOnDesktop}
        ref={ref}
      >
        <Flex gap={16}>
          {withArrowBack && (
            <IconButton
              icon="ArrowBack"
              onClick={onBackClick || onClose}
              iconProps={{ size: 16, fillColor: Theme.Button.backgroundColor }}
            />
          )}
          <Typography variant="h3" fontWeight="600">
            {text}
          </Typography>
        </Flex>
        {!withArrowBack && (
          <StyledCloseBtn
            icon="Close"
            onClick={onClose}
            iconProps={{ fillColor: Theme.Button.backgroundColor }}
            $enableCloseButtonOnDesktop={enableCloseButtonOnDesktop}
          />
        )}
      </StyledCloseContainer>
    )
  }
)
