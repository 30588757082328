import styled from 'styled-components'

import { TextField, Theme } from '@web-apps/ui-shared'

export const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

export const StyledTextFieldEmail = styled(TextField)`
  &:read-only {
    cursor: not-allowed;

    background-color: ${Theme.Colors.background.inactive};
  }
`
