import React, { ButtonHTMLAttributes } from 'react'

import { StyledButton } from './Button.styles'

export enum ButtonVariant {
  PRIMARY = 'primary',
  PLAIN = 'plain',
  OUTLINE = 'outline',
}

export enum ButtonSize {
  REGULAR = 'regular',
  SLIM = 'slim',
  SMALL = 'small',
}

export type ButtonProps = {
  children: React.ReactNode | string
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void
  href?: string
  variant?: ButtonVariant
  inverse?: boolean
  disabled?: boolean
  width?: number | string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  style?: ButtonHTMLAttributes<HTMLButtonElement>['style']
  isLoading?: boolean
  openInNewTab?: boolean
  size?: ButtonSize
  outline?: boolean
  /* special case to be able to use button style as div inside another button from third party services */
  asDiv?: boolean
  className?: string
}
export const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
  ButtonProps
>(
  (
    {
      children,
      clickHandler,
      href,
      variant,
      inverse,
      disabled,
      width,
      type,
      style,
      isLoading,
      outline = true,
      openInNewTab = false,
      size = ButtonSize.REGULAR,
      asDiv = false,
      ...restProps
    },
    ref
  ) => {
    if (href) {
      return (
        <StyledButton
          ref={ref as React.RefObject<HTMLAnchorElement>}
          as="a"
          href={href}
          {...(href && openInNewTab
            ? {
                target: '_blank',
                rel: 'noopener noreferrer',
              }
            : {})}
          $variant={variant}
          $inverse={inverse}
          $width={width}
          $size={size}
          $isLoading={isLoading}
          $outline={outline}
          type={type}
          style={style}
          onClick={clickHandler}
          {...restProps}
        >
          {children}
        </StyledButton>
      )
    }

    return !asDiv ? (
      <StyledButton
        ref={ref as React.RefObject<HTMLButtonElement>}
        onClick={clickHandler}
        as="button"
        $variant={variant}
        $inverse={inverse}
        disabled={disabled || isLoading}
        $width={width}
        $size={size}
        $isLoading={isLoading}
        $outline={outline}
        style={style}
        type={type}
        {...restProps}
      >
        {children}
      </StyledButton>
    ) : (
      /* special case to be able to use button style as div inside another button from third party services */
      <StyledButton
        ref={ref as React.RefObject<HTMLDivElement>}
        onClick={clickHandler}
        as="div"
        $variant={variant}
        $inverse={inverse}
        $width={width}
        $size={size}
        $isLoading={isLoading}
        $outline={outline}
        style={style}
        {...restProps}
      >
        {children}
      </StyledButton>
    )
  }
)
