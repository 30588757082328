export enum CookieBannerVariantEnum {
  COOKIE = 'cookie',
  TEXT = 'text',
}

export const initCookies = ({
  cookieDomain,
  host,
  cookieBannerVariant,
  onCookieStatusChange,
  containerElement,
  messages: { cookieBannerText, ...messages },
  styles,
}: {
  cookieDomain: string
  host: string
  cookieBannerVariant: CookieBannerVariantEnum
  onCookieStatusChange: (status: string) => void
  containerElement: HTMLElement
  messages: {
    cookieBannerText?: string
    link: string
    message: string
    allow: string
    deny: string
    linkText: string
  }
  styles: {
    buttonTextColor: string
    buttonBackgroundColor: string
    mainButtonBackgroundColor: string
    mainButtonTextColor: string
    titleTextColor: string
  }
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cookieConsent = (window as any).cookieconsent
  const content = {
    ...messages,
    policy:
      cookieBannerVariant === CookieBannerVariantEnum.TEXT
        ? cookieBannerText
        : '🍪',
    href: 'https://www.zezam.io/privacy-policy',
  }

  if (!cookieConsent) {
    window.requestAnimationFrame(() =>
      initCookies({
        cookieDomain,
        host,
        cookieBannerVariant,
        onCookieStatusChange,
        containerElement,
        messages: {
          ...messages,
          cookieBannerText,
        },
        styles,
      })
    )
  } else {
    cookieConsent.initialise({
      container: containerElement,
      cookie: {
        domain: cookieDomain,
      },
      revokable: true,
      palette: {
        popup: {
          background: styles.titleTextColor,
          text: styles.buttonTextColor,
        },
        button: {
          background: styles.mainButtonBackgroundColor,
          text: styles.mainButtonTextColor,
        },
        highlight: {
          background: styles.buttonBackgroundColor,
          text: styles.buttonTextColor,
        },
      },
      theme: 'classic',
      type: 'opt-in',
      position:
        cookieBannerVariant === CookieBannerVariantEnum.TEXT
          ? 'bottom-custom'
          : 'bottom-right',
      content,
      onStatusChange: (status: string) => {
        onCookieStatusChange(status)
      },
    })
  }
}
