import { ReactComponent as ZezamLogoIcon } from '../../public/logo.svg'

export const themeConfig = {
  theme: 'main',
  logo: {
    icon: ZezamLogoIcon,
    width: 120,
    height: 40,
  },
  meta: {
    title: 'meta:main_title',
    description: 'meta:main_description',
    maskIconColor: '#333333',
    msapplicationTileColor: '#da532c',
    themeColor: '#ffffff',
  },
  footer: {
    bottomMessage: 'bottom_message',
    sections: [
      {
        title: 'company',
        items: [
          {
            title: 'privacy_policy',
            link: '/privacy-policy',
          },
          {
            title: 'terms_and_conditions',
            link: '/terms-and-conditions',
          },
          {
            title: 'imprint',
            link: '/impressum',
          },
        ],
      },
      {
        title: 'social_media',
        items: [
          {
            title: 'instagram',
            link: 'https://www.instagram.com/zezam.io/',
            isExternalLink: true,
          },
          {
            title: 'linkedin',
            link: 'https://www.linkedin.com/company/feelthekaha',
            isExternalLink: true,
          },
          {
            title: 'contact',
            link: '/kontakt',
          },
        ],
      },
    ],
  },
  navigation: {
    options: [],
  },
}
