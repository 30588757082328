import React, { SelectHTMLAttributes } from 'react'

import { Flex } from '../../atoms'
import {
  StyledError,
  StyledFieldDescriptionText,
  StyledLabelText,
  StyledNoteSmall,
} from '../../styles/form.styles'
import { StyledSelect, StyledContainer } from './SelectField.styles'

type SelectFieldProps = SelectHTMLAttributes<HTMLSelectElement> & {
  /* Forcing name to be defined */
  name: string
  label?: string
  description?: string
  errorMessages?: string[]
  notes?: string[]
}

export const SelectField = React.forwardRef<
  HTMLSelectElement,
  SelectFieldProps
>(
  (
    { label, description, notes = [], errorMessages = [], ...selectProps },
    ref
  ) => {
    const hasErrors = errorMessages.length > 0

    return (
      <>
        <StyledContainer htmlFor={selectProps.name}>
          {label && (
            <StyledLabelText hasError={hasErrors}>{label}</StyledLabelText>
          )}
          {description && (
            <StyledFieldDescriptionText>
              {description}
            </StyledFieldDescriptionText>
          )}
          <StyledSelect
            $hasError={hasErrors}
            ref={ref}
            translate="no"
            {...selectProps}
          />
        </StyledContainer>

        {hasErrors && <StyledError>{errorMessages[0]}</StyledError>}

        {!hasErrors && notes.length > 0 && (
          <Flex direction="column">
            {notes.map((note) => (
              <StyledNoteSmall key={note}>{note}</StyledNoteSmall>
            ))}
          </Flex>
        )}
      </>
    )
  }
)
