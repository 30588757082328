import styled from 'styled-components'

import { media, BREAKPOINTS } from '../../helpers'
import { Theme } from '../../theme'

export const StyledPanelWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
  position: relative;
  padding: 0 16px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      flex-basis: initial;
      padding: 0;
      flex: 1;
    `
  )}
  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    background: ${Theme.Colors.background.alternate};
  }

  &:nth-child(even):before {
    display: block;
  }

  ${media.from(
    BREAKPOINTS.md,
    `
        &:not(:first-child):before {
        display: block;
      }
    }
    `
  )}
`

export const StyledSummariesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  margin: 0 -16px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      row-gap: 24px;
      margin: 0;
      justify-content: space-evenly;
    `
  )}
`
