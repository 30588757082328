import styled from 'styled-components'

import { ProfileImageShape } from '@web-apps/utils-types'

export const StyledAvatar = styled.div<{
  $url: string
  $size: number
  $shape: ProfileImageShape
}>`
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  background: ${({ $url }) => `url(${$url})`};
  background-size: cover;
  ${({ $shape }) =>
    $shape === ProfileImageShape.CIRCLE && 'border-radius: 50%'};
  ${({ $shape, $size }) =>
    $shape === ProfileImageShape.DIAMOND &&
    `
      width:${$size}px;
      height: ${$size}px;
      transform: translate(50%, 50%) rotate(45deg);
      margin: -100% 0 0 -100%;
    `}
`

export const StyledAvatarWrapper = styled.div<{ $size: number }>`
  width: ${({ $size }) => `${$size / 1.5}px`};
  height: ${({ $size }) => `${$size / 1.5}px`};
  transform: rotate(-45deg);
  overflow: hidden;
  margin: ${({ $size }) => `${$size / 6}px`};
`
