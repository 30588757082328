import { PriceType } from './money.types'
import { DateType, LinkAPIType, RequestMethodsEnum } from './common.types'
import { ImageResponseAPIType, ImageResponseType } from './image.types'

export type CommissionAPIType =
  | {
      type: 'fixed'
      amount: PriceType
    }
  | {
      type: 'percentage'
      fraction: number
    }
  | {
      type: 'expected'
      amount: PriceType
    }

export type CommissionDiffAPIType =
  | {
      type: 'fixed'
      diff: PriceType
    }
  | {
      type: 'percentage'
      diff: number
    }

export type CommissionBaseType = 'cpc' | 'cpa' | 'custom'

export enum MissedHintsEnum {
  OFFER = 'OFFER',
  TYPE = 'TYPE',
  REGION = 'REGION',
}

export enum TrafficSourceNamesEnum {
  OTHER = 'OTHER',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
}

export type CommissionSourceAPIType = {
  source: TrafficSourceNamesEnum
  cpa?: CommissionAPIType
  cpc?: CommissionAPIType
}

export type CommissionSource = {
  sources: TrafficSourceNamesEnum[]
  renderType: 'cpc' | 'cpa' | 'cpc_cpa'
  values: {
    cpc?: string
    cpa?: string
  }
}

export type CommissionAggregateAPIType = {
  cpa?: CommissionAPIType
  cpc?: CommissionAPIType
  custom?: string
  sources?: CommissionSourceAPIType[]
}

export type ConvertAffiliateLinkAPIResponse = {
  is_convertible: boolean
  is_affiliate_link: boolean
  original_uri: string
  converted_uri?: string
  commission?: CommissionAggregateAPIType
  network?: string
  missed_hints?: MissedHintsEnum[]
  matches: {
    name: string
    commission: {
      cpa?: CommissionAPIType
      cpc?: CommissionAPIType
    }
  }[]
  _links: {
    'affiliate_link/create': LinkAPIType
    'affiliate_link/create/v2'?: (LinkAPIType & { name: string })[]
    'affiliate_link_section/create': (LinkAPIType & { name: string })[]
    'affiliate_link_section/create_in': LinkAPIType
    'creator_page/external_link_section/create'?: LinkAPIType
  }
}

export type CommissionType = (
  | {
      type: 'fixed'
      amount: PriceType
    }
  | {
      type: 'percentage'
      fraction: number
    }
  | {
      type: 'expected'
      amount: PriceType
    }
) & { formattedText: string }

export type DiscountAggregateAPIType = {
  absolute?: PriceType
  relative?: number
}

export type DiscountType = {
  formattedText: string
  type: 'relative' | 'absolute'
  amount: number | PriceType
}

export type CommissionAggregateType = {
  cpa?: CommissionType
  cpc?: CommissionType
  custom?: string
  sources?: CommissionSource[]
}

type AffiliateConverterData = {
  href: string
  id: string
  name: string
  title: string
}

export type AffiliateCommissionMatch = {
  name: string
  commission: {
    cpa?: CommissionType
    cpc?: CommissionType
  }
  createApiEndpoint?: string // Create Affiliate link
  createSectionApiEndpoint?: string // Create Affiliate link section
}

export type AffiliateLinkConversionData = {
  isConvertible: boolean
  isAffiliateLink: boolean
  originalLink: string
  missedHints?: MissedHintsEnum[]
  matches: AffiliateCommissionMatch[]
  network?: string
  commission?: CommissionAggregateType
  api: {
    createContainerApiEndpoint?: string // Create a new Container section
    createExternalSectionAPIEndpoint?: string // Create External link section
  }
}

export type BrandAPI = {
  name: string
  campaign?: {
    click_limit?: {
      current_clicks: number
      max_clicks: number
    }
    earnings_limit?: {
      current_earnings: PriceType
      max_earnings: PriceType
    }
    period_limit: {
      valid_until: string
      valid_from: string
    }
    text?: string
    title?: string
  }
  commission?: CommissionAggregateAPIType
  _links: {
    self: {
      href: string
      id: string
    }
    about?: LinkAPIType
    media?: LinkAPIType
    affiliate_converter?: AffiliateConverterData[]
    'brand/image'?: ImageResponseAPIType
    'self/image'?: ImageResponseAPIType
  }
}

export type OfferWithCommissionDiffAPI = BrandAPI & {
  diff: CommissionDiffAPIType
}

export type Brand = {
  id: string
  name: string
  commission?: CommissionAggregateType
  campaign?: {
    clicksLimit?: {
      currentClicks: number
      maxClicks: number
      clicksLeft: number
      currentClicksFraction: number
    }
    earningsLimit?: {
      currentEarningFraction: number
      amountLeft: string
      currentEarnings: PriceType & {
        formattedText: string
      }
      maxEarnings: PriceType & {
        formattedText: string
      }
    }
    periodLimit?: {
      timeLeftFraction: number
      validUntil: DateType
      validFrom: DateType
      timeLeft: string
    }
    title?: string
    text?: string
  }
  websiteURL?: string
  logoURL?: string
  mediaURL?: string
  affiliateConverter?: AffiliateConverterData[]
}

export type OfferWithCommissionDiff = {
  data: Brand
  diff: CommissionType
}

export enum OfferNetworkEnum {
  METAPIC = 'METAPIC',
  AWIN = 'AWIN',
  ADGOAL = 'ADGOAL',
  DIGIDIP = 'DIGIDIP',
  TRADEDOUBLER = 'TRADEDOUBLER',
  RAKUTEN = 'RAKUTEN',
}

export type NetworkOfferType = {
  id?: string
  name: OfferNetworkEnum
  regions?: string[]
}

export type OfferType = {
  id: string
  entryURL?: string
  commission: CommissionAggregateType
  network?: NetworkOfferType
  enabled: boolean
  brand: Brand
  regionsOverride?: string[]
  topInRegions?: string[]
}

export type BrandSearchAPIResponse = {
  brands?: BrandAPI[] // todo: remove
  content?: BrandAPI[] // todo: required
}

export type BrandSearchData = {
  brands: Brand[]
}

export type AffiliateRequestParams = {
  cpaMetric: boolean
  cpcMetric: boolean
  region?: string
}

export type AffiliateProductAPIType = {
  name: string
  brand: Omit<BrandAPI, '_links'>
  _links: {
    about: LinkAPIType
    image?: LinkAPIType
  }
}

export type AffiliateProductsAPIResponse = {
  content: AffiliateProductAPIType[]
  _links: {
    self: LinkAPIType
  }
}

export type AffiliateProductType = {
  id: string
  title: string
  brandName?: string | null
  url: string
  commission?: CommissionAggregateType
  discount?: DiscountType
  price?: string
  mediaURL?: string
  convertUrl: string
  retailer?: string
  api: {
    addAsSection: string
    addToGrid: string
    createAffiliateLink: string
  }
}

export type AffiliateProductsDataType = {
  products: AffiliateProductType[]
  totalProducts: number
  prevPage?: {
    href: string
  }
  nextPage?: {
    href: string
  }
}

export type BrandsAPIResponse = {
  content: BrandAPI[]
  _links: {
    self: LinkAPIType
  }
}

export type OffersLatestCommissionIncreasesAPIResponse = {
  content: OfferWithCommissionDiffAPI[]
  _links: {
    self: LinkAPIType
  }
}

export type ProductSearchAPI = {
  brand_name: string | null
  commission: CommissionAggregateAPIType
  description: string
  name: string
  list_price?: PriceType
  price?: PriceType
  discount?: DiscountAggregateAPIType
  shipping_fee?: PriceType
  _links: {
    about: LinkAPIType
    'affiliate_link/create': {
      href: string
      name?: string
      methods?: RequestMethodsEnum[]
    }[]
    clickout: LinkAPIType
    'self/image': ImageResponseType[]
    brand: LinkAPIType & { title?: string }
    retailer: LinkAPIType & { title?: string }
    'brand/image': ImageResponseType[]
    'retailer/image': ImageResponseType[]
    'affiliate-link-section/create': LinkAPIType[]
    'affiliate_link_section/create_in': LinkAPIType[]
  }
}

export type TrendingProductSearchAPIResponse = {
  content: ProductSearchAPI[]
  page: {
    number: number
    size: number
    total_elements: number
    total_pages: number
  }
  _links: {
    self: LinkAPIType
    prev?: LinkAPIType
    next?: LinkAPIType
  }
}
