import { useState, useEffect } from 'react'
import { isInInstagramIosBrowser } from './browserDetection'

export const useInstagramIosBrowserExtraHeight = () => {
  const [bottomRowHeight, setBottomRowHeight] = useState(0)

  useEffect(() => {
    if (isInInstagramIosBrowser()) setBottomRowHeight(75)
  }, [])

  return bottomRowHeight
}
