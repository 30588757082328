import { ImgHTMLAttributes, CSSProperties, HTMLAttributes } from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

import { Box, Flex, Typography } from '../../atoms'
import { Theme } from '../../theme'

import { TitledCard, TitledCardProps } from './TitledCard'
import { Badges, BadgeType } from './components/Badges'

type VariantType = 'small' | 'regular' | 'custom'
type MediaProps = {
  imageReplacementTitle?: string
  image?: ImgHTMLAttributes<HTMLImageElement> | { isSkeleton: boolean }
} & {
  size?: CSSProperties['width']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  variant?: VariantType
  disableSeparator?: boolean
  badges?: BadgeType[]
  footerStyles?: HTMLAttributes<HTMLDivElement>['style']
}

type TitledMediaCardProps = TitledCardProps & MediaProps

const StyledImageContainer = styled.div<{
  $width: MediaProps['width']
  $height: MediaProps['height']
}>`
  background: ${Theme.Colors.background.base};

  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  border-radius: 8px;
`

const StyledImage = styled.img<{ $variant: VariantType }>`
  width: 100%;
  height: 100%;

  padding: ${({ $variant }) => ($variant === 'regular' ? '16px' : 0)};
  border-radius: 8px;

  object-fit: contain;
`

const BLOCK_PADDING_IN_PIXELS_FOR_VARIANT: {
  [key in VariantType]: number | string
} = {
  small: 16,
  regular: 16,
  custom: '32px 0 16px',
}

const INLINE_PADDING_IN_PIXELS_FOR_VARIANT: { [key in VariantType]: number } = {
  small: 0,
  regular: 72,
  custom: 0,
}

const SIZE_FOR_VARIANT: { [key in VariantType]: MediaProps['width'] } = {
  small: '96px',
  regular: '128px',
  custom: '96px',
}

const DEFAULT_VARIANT = 'regular'

export const MediaContent = ({
  image,
  width,
  height,
  imageReplacementTitle,
  variant = DEFAULT_VARIANT,
}: MediaProps) => {
  return (
    <Box
      py={BLOCK_PADDING_IN_PIXELS_FOR_VARIANT[variant]}
      px={INLINE_PADDING_IN_PIXELS_FOR_VARIANT[variant]}
    >
      <Flex justify="center" align="center">
        <StyledImageContainer
          $width={width || SIZE_FOR_VARIANT[variant]}
          $height={height || SIZE_FOR_VARIANT[variant]}
        >
          {image &&
            ('isSkeleton' in image ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              <StyledImage $variant={variant} {...image} />
            ))}
          {!image && imageReplacementTitle && (
            <Flex align="center" justify="center" style={{ height: '100%' }}>
              <Typography variant="h3" textAlign="center">
                {imageReplacementTitle}
              </Typography>
            </Flex>
          )}
        </StyledImageContainer>
      </Flex>
    </Box>
  )
}

export const TitledMediaCard = ({
  image,
  size,
  width,
  height,
  variant = DEFAULT_VARIANT,
  disableSeparator = false,
  imageReplacementTitle,
  badges = [],
  footerStyles = {},
  ...props
}: TitledMediaCardProps) => {
  return (
    <TitledCard
      {...props}
      footerStyles={{
        marginTop: 'initial',
        borderTop: disableSeparator ? 'none' : undefined,
        ...footerStyles,
      }}
    >
      {badges?.length > 0 && <Badges badges={badges} />}
      <MediaContent
        image={image}
        imageReplacementTitle={imageReplacementTitle}
        width={width || size}
        height={height || size}
        variant={variant}
      />
    </TitledCard>
  )
}
