import getConfig from 'next/config'

import { SectionType } from '@web-apps/utils-types'

export const SECTION_CLICK_EVENT = 'section:click'
export const SECTION_REDIRECT_EVENT = 'section:redirect'
export const SOCIAL_CLICK_EVENT = 'social_link:click'
export const AFFILIATE_OUT_SCREEN = 'affiliate_out_page'
export const PUBLIC_CREATOR_PROFILE_SHARE_CLICK =
  'public_creator_profile_share:click'

const { publicRuntimeConfig } = getConfig()

export const getAnalyticsDomain = () =>
  publicRuntimeConfig.hostname.split('.').slice(-2).join('.')

export const fromSectionToAnalyticProps = (section: SectionType) => ({
  id: section._links.self.id,
  label: section.label,
  variant: section.styles.variant,
  styles: section.styles,
  type: section.type,
  url: section._links.about?.href,
})

export const plausiblePageView = async (
  req: any,
  redirectSection: SectionType
) => {
  return fetch('https://plausible.io/api/event', {
    method: 'POST',
    headers: {
      'User-Agent': req.headers['user-agent'] ?? '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: SECTION_REDIRECT_EVENT,
      url: req.url,
      domain: getAnalyticsDomain(),
      props: fromSectionToAnalyticProps(redirectSection),
    }),
  })
}
