import React from 'react'
import { ToastContentProps } from 'react-toastify'

import {
  copyLinkToClipboard,
  isCopyClipboardAvailable,
} from '@web-apps/utils-shared'

import { Box, Flex, Icon, StyledInstagramButton } from '../../../atoms'
import { TextField, TextFieldSize } from '../../TextField'
import { CopyButton } from '../../CopyButton'
import { Theme } from '../../../theme'
import { ButtonSize, ButtonVariant, ButtonProps } from '../../Button/Button'
import styled from 'styled-components'
import { BREAKPOINTS, media } from '../../../helpers'

const StyledContainer = styled(Flex)`
  flex-direction: column;

  ${media.from(
    BREAKPOINTS.md,
    `
      flex-direction: row;
    `
  )}
`

type Props = {
  href: string
  message: string
  buttonText: string
  copiedTooltipText: string
  shareButtonText: string
  onCopyClick?: ButtonProps['clickHandler']
  onShareClick?: ButtonProps['clickHandler']
} & Partial<ToastContentProps>

export const ToastShare = ({
  href,
  message,
  buttonText,
  copiedTooltipText,
  shareButtonText,
  onCopyClick,
  onShareClick,
}: Props) => {
  const copy = async () => {
    await copyLinkToClipboard(href)
    window.open('https://www.instagram.com/')
  }

  return (
    <Box ml={-34}>
      <Box pl={30} mb={24}>
        {message}
      </Box>
      <StyledContainer gap={24}>
        <TextField
          name="url"
          leftDecorator={<Icon.Link />}
          readOnly
          value={href}
          fieldSize={TextFieldSize.REGULAR}
          rightDecorator={
            isCopyClipboardAvailable() ? (
              <CopyButton
                value={href}
                buttonSize={ButtonSize.SLIM}
                messages={{
                  buttonText,
                  copiedTooltipText,
                }}
                onClick={onCopyClick}
              />
            ) : undefined
          }
        />
        <StyledInstagramButton
          clickHandler={(e) => {
            copy()

            if (onShareClick) {
              onShareClick(e)
            }
          }}
          variant={ButtonVariant.PLAIN}
        >
          <Flex justify="center" gap={8}>
            <Icon.InstagramMono fillColor={Theme.Colors.typography.inverse} />
            {shareButtonText}
          </Flex>
        </StyledInstagramButton>
      </StyledContainer>
    </Box>
  )
}
