import React from 'react'

import {
  StyledWrapper,
  StyledContainer,
  StyledContent,
  StyledUseFormLink,
} from './AuthWrapper.styles'

import {
  Icon,
  Flex,
  Typography,
  Box,
  IconComponent,
  Theme,
} from '@web-apps/ui-shared'

import {
  METAPIC_HOMEPAGE,
  METAPIC_PARTNER_IDENTIFIER,
} from '@web-apps/utils-shared'

const PARTNER_INFOS_MAP: {
  [partnerId: string]: {
    LogoComponent: IconComponent
    homepage: string
    title?: string
  }
} = {
  [METAPIC_PARTNER_IDENTIFIER]: {
    LogoComponent: Icon.MetapicLogo,
    homepage: METAPIC_HOMEPAGE,
    title: METAPIC_PARTNER_IDENTIFIER,
  },
}

type AuthPageWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  homePage: string
  children: React.ReactNode
  partner?: string | null
}
export const AuthPageWrapper = ({
  children,
  homePage,
  partner,
  ...divProps
}: AuthPageWrapperProps) => {
  const partnerInfo = partner && PARTNER_INFOS_MAP[partner]

  return (
    <StyledWrapper {...divProps}>
      <StyledContainer>
        <Box mb={32}>
          <Flex justify="center" align="center" gap="16" wrap="wrap">
            <a href={homePage} title="zezam">
              <Icon.LogoFull width={134} height={32} />
            </a>
            {partnerInfo && (
              <>
                <Icon.Close
                  fillColor={Theme.Colors.typography.regular}
                  width={16}
                  height={16}
                />
                <span title={partnerInfo.title}>
                  <partnerInfo.LogoComponent />
                </span>
              </>
            )}
          </Flex>
        </Box>
        {children}
      </StyledContainer>
    </StyledWrapper>
  )
}

export const AuthFormWrapper = ({
  children,
  title,
  description,
}: {
  children: React.ReactNode
  title?: string
  description?: string
}) => {
  return (
    <StyledContent>
      {(title || description) && (
        <Box mr={24} ml={24} mb={24}>
          <Flex direction="column" align="center" gap={16}>
            {title && (
              <Typography textAlign="center" variant="h2">
                {title}
              </Typography>
            )}
            {description && (
              <Typography textAlign="center">{description}</Typography>
            )}
          </Flex>
        </Box>
      )}
      {children}
    </StyledContent>
  )
}

export const UseFormLink = ({
  children,
  withBoldLink,
}: {
  children: React.ReactNode
  withBoldLink?: boolean
}) => {
  return (
    <StyledUseFormLink $withBoldLink={withBoldLink}>
      {children}
    </StyledUseFormLink>
  )
}
