import { LinkAPIType, ResourceAPIType } from '@web-apps/utils-types'

export enum HyperlinkIdentifierNameEnum {
  SECTION_ORDER_CREATOR_PAGE = 'section_order',
}

export const extractHyperlinkFrom = (
  resource: ResourceAPIType,
  options: {
    propertyName: string
    hyperlinkIdentifierName?: HyperlinkIdentifierNameEnum
  }
) => {
  const hyperlink = resource._links[options.propertyName]

  if (Array.isArray(hyperlink)) {
    if (options.hyperlinkIdentifierName) {
      return (hyperlink as []).find(
        (h: LinkAPIType) => h.name && h.name === options.hyperlinkIdentifierName
      )
    } else {
      return hyperlink[0]
    }
  }

  return hyperlink
}
