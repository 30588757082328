import styled, { CSSProperties, AnyStyledComponent } from 'styled-components'

import IconAssets, { IconAssetsType, IconAssetsKeys } from './assets'

type IconProps = React.HTMLProps<HTMLElement> & {
  fillColor?: CSSProperties['fill']
  as?: AnyStyledComponent['as']
}

type StyledIconProps = {
  $fillColor?: CSSProperties['fill']
}

export type IconComponent = (props: IconProps) => JSX.Element

const Icon = styled.span<StyledIconProps>`
  display: flex;
  align-items: center;

  svg,
  path {
    ${({ $fillColor }) => $fillColor && `fill: ${$fillColor};`}
  }
`

export type IconAssetsFC = IconAssetsType[IconAssetsKeys]

const withIcon =
  (IconComponent: IconAssetsFC): IconComponent =>
  ({ fillColor, width, height, ...props }) => {
    const svgProps =
      width && height
        ? {
            width,
            height,
          }
        : {}
    return (
      <Icon $fillColor={fillColor} {...props}>
        {<IconComponent {...svgProps} />}
      </Icon>
    )
  }

type IconCatalogType = { [key in IconAssetsKeys]: IconComponent }
export const IconCatalog: IconCatalogType = (() => {
  const icons: IconCatalogType = {} as IconCatalogType
  Object.entries(IconAssets).forEach(([key, value]) => {
    icons[key as IconAssetsKeys] = withIcon(value)
  })

  return icons
})()
