import React, { CSSProperties } from 'react'
import '@reach/dialog/styles.css'

import {
  StyledCloseIconContainer,
  StyledDialog,
  StyledDialogContentContent,
  StyledDialogOverlay,
  StyledHeaderContainer,
  StyledHeaderInfoContainer,
  StyledSeparator,
} from './Dialog.styles'

import { Theme } from '../../theme'
import { IconButton } from '..'
import { Typography } from '../../atoms'

export type DialogProps = {
  isOpen: boolean
  title?: string
  subtitle?: string
  showCloseButton?: boolean
  showHeaderBorder?: boolean
  onDismiss?: () => void
  isBlocked?: boolean
  withMaxHeight?: boolean
  height?: string
  opacity?: CSSProperties['opacity']
  maxWidthNotMobile?: CSSProperties['maxWidth']
  leftHeaderDecorator?: JSX.Element
  children: React.ReactNode
  ariaLabel?: string
}

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      title,
      subtitle,
      isOpen,
      onDismiss,
      children,
      leftHeaderDecorator,
      showCloseButton = true,
      showHeaderBorder = true,
      isBlocked,
      maxWidthNotMobile,
      withMaxHeight,
      height,
      opacity = 1,
      ariaLabel,
    },
    ref
  ) => {
    return (
      <StyledDialogOverlay
        isOpen={isOpen}
        onDismiss={isBlocked || !onDismiss ? undefined : onDismiss}
        $isVisible={isOpen}
      >
        <StyledDialog
          ref={ref}
          aria-label={ariaLabel || title || ''}
          $maxWidthNotMobile={maxWidthNotMobile}
          $opacity={opacity}
          $height={height}
          $maxHeight={withMaxHeight}
        >
          {(title || showCloseButton || leftHeaderDecorator) && (
            <StyledHeaderContainer>
              <StyledHeaderInfoContainer
                $justify={
                  showCloseButton || leftHeaderDecorator
                    ? 'space-between'
                    : 'center'
                }
                $hasPaddingBottom={showHeaderBorder}
              >
                {leftHeaderDecorator}

                <div>
                  {title && (
                    <Typography variant="h3" fontWeight="600">
                      {title}
                    </Typography>
                  )}
                  {subtitle && (
                    <Typography variant="hint" color="inactive">
                      {subtitle}
                    </Typography>
                  )}
                </div>

                {showCloseButton && (
                  <StyledCloseIconContainer>
                    <IconButton
                      title="X"
                      icon="Close"
                      onClick={onDismiss}
                      iconProps={{ fillColor: Theme.Colors.typography.light }}
                    />
                  </StyledCloseIconContainer>
                )}
              </StyledHeaderInfoContainer>
              {showHeaderBorder && <StyledSeparator />}
            </StyledHeaderContainer>
          )}
          <StyledDialogContentContent $fullHeight={!title && withMaxHeight}>
            {children}
          </StyledDialogContentContent>
        </StyledDialog>
      </StyledDialogOverlay>
    )
  }
)
