import styled from "styled-components";

export const StyledLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
`

export const StyledSlugInput = styled.div`
  position: relative;

  &:before {
    content: 'zez.am/';
    position: absolute;
    font-weight: 600;
    top: 17px;
    left: 16px;
    z-index: 1;
    color: rgba(51, 51, 51, 0.7);
  }

  input {
    padding-left: 78px !important;
  }
`
