export enum SocialNetworksEnum {
  AmazonMusic = 'amazon-music',
  AppleMusic = 'apple-music',
  ApplePodcasts = 'apple-podcasts',
  Bandcamp = 'bandcamp',
  Beatport = 'beatport',
  ClubHouse = 'clubhouse',
  Deezer = 'deezer',
  Discord = 'discord',
  Email = 'email',
  Facebook = 'facebook',
  GitHub = 'github',
  GitLab = 'gitlab',
  Instagram = 'instagram',
  LinkedIn = 'linkedin',
  Patreon = 'patreon',
  PayPal = 'paypal',
  Pinterest = 'pinterest',
  Snapchat = 'snapchat',
  Soundcloud = 'soundcloud',
  Spotify = 'spotify',
  Telegram = 'telegram',
  TikTok = 'tiktok',
  Twitch = 'twitch',
  Twitter = 'twitter',
  Vkontakte = 'vkontakte',
  Vimeo = 'vimeo',
  Website = 'website',
  WhatsApp = 'whatsapp',
  YouTube = 'youtube',
  Tumblr = 'tumblr',
}
