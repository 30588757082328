import styled from 'styled-components'
import { Theme } from '../../theme'

export const StyledLabel = styled.label`
  position: relative;
  display: block;
  padding-left: 36px;
  cursor: pointer;
  user-select: none;
`

export const StyledLabelContent = styled.span``

export const StyledCheckbox = styled.span<{ $hasError?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid
    ${({ $hasError }) =>
      $hasError
        ? Theme.Colors.typography.error
        : Theme.Colors.typography.light};
  background-color: ${Theme.Colors.background.base};
  border-radius: 8px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 5px;
    left: 2px;
    width: 17px;
    height: 13px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAbCAYAAAAULC3gAAAAwUlEQVRYCe3WzQ3DIAwF4IzQUSqeubNZO1pH6CYZodWLgkSkOD8IzMW5oHDJl2cLM03+bBOIMT5DCK/t7qA3YgDMAH7DUSWGoKGoPQxBIvI2L5ZjtMg9GU9GS0Db957pnkxK6SEiH0atfexsv1mZiAHwXWfKXINqhuFfF5hlyHEC30E1xRDEoZYnbrFeQjXH5L6oQXXD1KC6Y+6gzDBXUOaYM1S+AxfNb3fTUxo9Hw3Lan7tPEKZY47KNwyzh+qJ+QPJfj2UOPDz0QAAAABJRU5ErkJggg==')
      no-repeat 0 0;
    background-size: cover;
  }
`

export const StyledInputCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${StyledCheckbox} {
    transition: all 0.25s ease-in-out;
  }
  &:checked ~ ${StyledCheckbox}:after {
    display: block;
  }
`
