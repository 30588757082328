import {
  AffiliateLinkAPIType,
  AffiliateLinksListAPIType,
  AffiliateLinksListType,
  AffiliateLinkType,
  ImageNamesEnum,
  PublicAffiliateLinkType,
  RecentAffiliateLinksType,
} from '@web-apps/utils-types'

import { DateHelpers } from '../date'
import { imageUrlBuilder } from '../images'
import { fromCommissionAggregateAPI } from '../affiliates'
import { Money } from '../money'

export const fromAffiliateLinkAPI = (
  affiliateLink: AffiliateLinkAPIType
): AffiliateLinkType => {
  const imageUrl = affiliateLink._links['parent/image']?.find(
    (item) => item.name === ImageNamesEnum.DEFAULT
  )

  const brandName = affiliateLink._links.brand?.title || null
  const brandImage = affiliateLink._links['brand/image']?.find(
    (i) => i.name === ImageNamesEnum.DEFAULT
  )

  return {
    id: affiliateLink._links.self.id,
    title: affiliateLink.title || brandName,
    brandName,
    createdAt: affiliateLink.created_at,
    originalUrl: affiliateLink._links.original.href,
    affiliateUrl: affiliateLink._links.short.href,
    author: affiliateLink._links.parent && {
      id: affiliateLink._links.parent.id,
      name: affiliateLink._links.parent.title,
      ...(imageUrl && {
        avatarUrl: imageUrlBuilder({
          image: imageUrl,
          width: 220,
          height: 220,
        }),
      }),
    },
    commission: fromCommissionAggregateAPI(affiliateLink.commission),
    ...(affiliateLink.earnings && {
      earned:
        (affiliateLink.earnings?.approved &&
          Money.formatPriceWithoutRounding(affiliateLink.earnings.approved)) ||
        '',
    }),
    imageUrl:
      (affiliateLink._links['self/image'] || brandImage) &&
      imageUrlBuilder({
        image: affiliateLink._links['self/image'] || brandImage,
        width: 220,
        height: 220,
      }),
    api: {
      addToGrid: affiliateLink._links['affiliate_link_section/create_in'].href,
      addAsSection: affiliateLink._links['affiliate_link_section/create'].href,
    },
  }
}

export const fromPublicAffiliateLinkAPI = (
  affiliateLink: AffiliateLinkAPIType
): PublicAffiliateLinkType => {
  const imageUrl = affiliateLink._links['parent/image']?.find(
    (item) => item.name === ImageNamesEnum.DEFAULT
  )

  const brandImage = affiliateLink._links['brand/image']?.find(
    (i) => i.name === ImageNamesEnum.DEFAULT
  )
  const brandName = affiliateLink._links.brand?.title || null

  return {
    id: affiliateLink._links.self.id,
    title: affiliateLink.title || brandName,
    brandName,
    originalUrl: affiliateLink._links.original.href,
    affiliateUrl: affiliateLink._links.short.href,
    author: affiliateLink._links.parent && {
      id: affiliateLink._links.parent.id,
      name: affiliateLink._links.parent.name,
      title: affiliateLink._links.parent.title,
      ...(imageUrl && {
        avatarUrl: imageUrlBuilder({
          image: imageUrl,
          width: 220,
          height: 220,
        }),
      }),
    },
    brandImage: brandImage
      ? imageUrlBuilder({
          image: brandImage,
          width: 220,
          height: 220,
        })
      : null,
  }
}

export const fromAffiliateLinksListAPI = (
  affiliateLinksListFromApi: AffiliateLinksListAPIType
): AffiliateLinksListType => {
  // 1. first do a desc sort by date for all items from api list and transform to our internal model
  const affiliateLinksListDescSorted = (affiliateLinksListFromApi.content || [])
    .sort((al1, al2) =>
      DateHelpers.getDifferenceInMilliseconds(al1.created_at, al2.created_at)
    )
    .map(fromAffiliateLinkAPI)

  // 2. aggregate them by month
  const affiliateLinksList: AffiliateLinksListType = {
    linksByMonthDescSorted: [],
    prevAPILink: affiliateLinksListFromApi._links.prev?.href,
    nextAPILink: affiliateLinksListFromApi._links.next?.href,
  }

  affiliateLinksListDescSorted.forEach((affiliateLink) => {
    const createdAt = DateHelpers.dateInstanceBuilder(affiliateLink.createdAt)
    const fullYear = createdAt.year()
    const monthIndex = createdAt.month()

    const monthData = affiliateLinksList.linksByMonthDescSorted.find(
      (y) => fullYear === y.fullYear && monthIndex === y.monthIndex
    )

    if (monthData) {
      monthData.linksDescSortedByDate.push(affiliateLink)
    } else {
      affiliateLinksList.linksByMonthDescSorted.push({
        fullYear,
        monthIndex,
        linksDescSortedByDate: [affiliateLink],
      })
    }
  })

  return affiliateLinksList
}

export const fromRecentAffiliateLinksListApi = (
  data: AffiliateLinksListAPIType
): RecentAffiliateLinksType => {
  return {
    content: data.content.map(fromAffiliateLinkAPI),
  }
}
