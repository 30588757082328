import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

import { Icon } from '../Icon'
import { SvgIcon } from '../SvgIcon'

const DEFAULT_SIZE = 32
const StyledContainer = styled.div<{ $isCentered?: boolean; $size: number }>`
  ${({ $isCentered, $size }) =>
    $isCentered &&
    `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-${$size / 2}px, -${$size / 2}px);
  `}
`

const StyledWrapper = styled.div<{ $height: number }>`
  position: relative;
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
`

type LoadingIndicatorProps = {
  size?: number
  isCentered?: boolean
  delayMs?: number
  isPositionRelative?: boolean
  containerHeight?: number
}

const Wrapper = ({
  children,
  isPositionRelative,
  containerHeight,
}: {
  children: JSX.Element
  isPositionRelative?: boolean
  containerHeight: number
}) => {
  if (isPositionRelative) {
    return <StyledWrapper $height={containerHeight}>{children}</StyledWrapper>
  }

  return children
}

export const LoadingIndicator = ({
  size,
  isCentered,
  delayMs = 0,
  isPositionRelative = false,
  containerHeight = 300,
}: LoadingIndicatorProps) => {
  const [hide, setHide] = useState(delayMs > 0)

  const startLoading = useDebouncedCallback(
    () => setHide(false),
    delayMs > 0 ? delayMs : 0
  )

  useEffect(() => {
    if (!hide) return

    startLoading()
  }, [startLoading, hide])

  if (hide) return null

  return (
    <Wrapper
      isPositionRelative={isPositionRelative}
      containerHeight={containerHeight}
    >
      <StyledContainer $isCentered={isCentered} $size={size || DEFAULT_SIZE}>
        <SvgIcon width={size || DEFAULT_SIZE} height={size || DEFAULT_SIZE}>
          <Icon.ZezamSpinner />
        </SvgIcon>
      </StyledContainer>
    </Wrapper>
  )
}
