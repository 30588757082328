import { CSSProperties } from 'react'
import styled from 'styled-components'

import { Card } from '../../atoms'
import { media, BREAKPOINTS } from '../../helpers'

type StyledStatusProps = {
  $cssColor: CSSProperties['color']
}

export const StyledCard = styled(Card)`
  padding: 0;
`

export const StyledCardContainer = styled.div`
  padding: 8px;

  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;

  p,
  h3 {
    margin: 0;
    padding: 0;

    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${media.from(
    BREAKPOINTS.sm,
    `
      padding: 16px;
    `
  )}

  ${media.from(
    BREAKPOINTS.lg,
    `
      padding: 24px;
    `
  )}
`

export const StyledLeftColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  /* Idea gotten from https://stackoverflow.com/a/66689926/1085387 */
  min-width: 0;
`

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledItemInfoContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
`

export const StyledTextStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  /* Idea gotten from https://stackoverflow.com/a/66689926/1085387 */
  min-width: 0;
`

export const StyledTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`

export const StyledInfoText = styled.p`
  color: #33333352;
`

export const StyledStatusContainer = styled.div<StyledStatusProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px;

  font-weight: 600;

  color: ${({ $cssColor }) => $cssColor};
`

export const StyledStatusIndicator = styled.span<StyledStatusProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;

  background: ${({ $cssColor }) => $cssColor};
`

export const StyledActionsOutsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  padding-right: 8px;
`

export const StyledActionInsideContainer = styled.div`
  display: flex;
  gap: 8px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      gap: 16px;
    `
  )}

  ${media.from(
    BREAKPOINTS.lg,
    `
      gap: 20px;
    `
  )}
`
