import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.span`
  font-size: inherit;
  font-weight: inherit;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 2px;
  vertical-align: top;
`

export const TextEllipsis: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...spanProps
}) => <StyledWrapper {...spanProps}>{children}</StyledWrapper>
