import styled from 'styled-components'

import { Theme } from '../../theme'

import { Card, CountryFlag } from '../../atoms'
import { PopoverSelector } from '../../molecules'

export const StyledFlagImage = styled(CountryFlag)`
  margin-right: 8px;
  vertical-align: middle;
`
export const StyledLine = styled.div`
  height: 1px;
  background: ${Theme.Colors.line};
`
export const StyledResultsWrapper = styled.div`
  position: relative;
  height: 1px;
  top: -10px;
`

export const StyledResults = styled(Card)`
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  max-height: 260px;
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 8px;

  z-index: 1;
`

export const StyledInput = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
`

export const StyledPopoverSelector = styled(PopoverSelector)`
  max-height: min(
    100vh - ${Theme.CreatorApp.header.height}px,
    ${Theme.Popover.maxHeightInPx}px
  );
`
