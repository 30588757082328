import * as Sentry from '@sentry/browser'

export const isCopyClipboardAvailable = () =>
  process.env.NODE_ENV === 'test'
    ? true
    : Boolean(navigator?.clipboard && navigator.permissions)

export const copyLinkToClipboard = async (value: string) => {
  if (isCopyClipboardAvailable()) {
    try {
      await navigator.clipboard.writeText(value)
      return true
    } catch (error) {
      if (error && error.toString().includes('Document is not focused')) {
        try {
          window.focus()
          await navigator.clipboard.writeText(value)
          return true
        } catch {
          Sentry.configureScope((scope) =>
            scope
              .setFingerprint(['copy-clipboard-not-focused-handled'])
              .setTags({ operations: 'copy-clipboard' })
              .setLevel('error')
          )
          Sentry.captureException(error)
        }
      } else {
        Sentry.configureScope((scope) =>
          scope
            .setFingerprint(['copy-clipboard-handled'])
            .setTags({ operations: 'copy-clipboard' })
            .setLevel('error')
        )
        Sentry.captureException(error)
      }
    }
  }

  return false
}
