import styled from 'styled-components'

import { BREAKPOINTS, media } from '../../helpers'

export const StyledPopUpContent = styled.div`
  text-align: center;
  margin-block-end: 32px;

  ${media.from(
    BREAKPOINTS.sm,
    `
    margin-block-end: 0;
    `
  )}
`

export const StyledPopUpTitle = styled.h3`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
`

export const StyledPopUpDescription = styled.p`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
`
