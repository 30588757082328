export enum ImageNamesEnum {
  DEFAULT = 'default',
  AVATAR = 'avatar-image',
  SECTION = 'section-image',
  BACKGROUND = 'background-image',
}

export type ImageResponseType = {
  href: string
  name: ImageNamesEnum
  type?: string
  methods?: string[]
}

export type ImageResponseAPIType = ImageResponseType | ImageResponseType[]

export type ImageFormType = Partial<ImageResponseType> & {
  imageFile?: Blob | string
  hasImageSource?: boolean
}

export type UploadedImageType = {
  imageUrl: string
  imageFile: Blob
}

export enum ProfileImageShape {
  CIRCLE = 'circle',
  SQUARE = 'square',
  DIAMOND = 'diamond',
}
