import styled from 'styled-components'

export const StyledContainer = styled.label<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;

  &.react-switch-handle {
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1) !important;
  }

  .react-switch-bg,
  .react-switch-handle {
    ${({ $disabled }) => $disabled && `cursor: not-allowed !important;`}
  }
`

export const StyledLabelText = styled.span`
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
`
