import styled from 'styled-components'

import { BREAKPOINTS, media } from '../../helpers'
import { IconButton } from '../IconButton'

export const StyledCloseContainer = styled.div<{ $hideOnDesktop?: boolean }>`
  position: sticky;
  top: 0;
  background: inherit;

  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;

  ${({ $hideOnDesktop }) =>
    media.from(
      BREAKPOINTS.sm,
      $hideOnDesktop
        ? `
      display: none;
    `
        : `
      margin-bottom: 8px;
    `
    )}
`

export const StyledCloseBtn = styled(IconButton)<{
  $enableCloseButtonOnDesktop: boolean
}>`
  ${({ $enableCloseButtonOnDesktop }) =>
    media.from(
      BREAKPOINTS.sm,
      $enableCloseButtonOnDesktop ? '' : 'display: none;'
    )}
`
