import { useEffect, useState, useCallback, useRef } from 'react'
import {
  LoginFlow,
  RegistrationFlow,
  RecoveryFlow,
  SettingsFlow,
} from '@ory/client'
import { generateRandomSlug } from '@web-apps/utils-shared'
import { useSearchParams } from 'react-router-dom'

import {
  getUserAuthSession,
  initiateLoginFlow,
  initiateRegistrationFlow,
  initiateRecoverFlow,
  initiateLogoutFlow,
  initiateSettingsFlow,
} from './flows'
import { isUiNodeInputAttributes } from './helpers'
import {
  LoginRegisterRecoverFieldsFormatTypes,
  OryFormInputTypes,
  NodeInputType,
  ProviderTypes,
  SessionType,
  SettingsFieldsFormatTypes,
} from './types'
import { useLogoutMutation, useValidateSlugMutation } from '../api'
import { useCheckSessionMutation } from '../api/auth.api'
import * as Sentry from '@sentry/browser'
import { configureSentryErrors } from './errors'
import { useFlag } from '@unleash/proxy-client-react'
import { FLAG_SEAMLESS_AUTH } from './flags.constants'

// TODO: figure out how to check session on public app with seamless login logic
export const useGetSessionForGetStartedPage = (
  reloadSession?: boolean
): {
  isSessionLoading: boolean
  session: SessionType | null
} => {
  const [isLoading, setIsLoading] = useState(true)
  const [session, setSession] = useState<SessionType | null>(null)

  const getSession = useCallback(async () => {
    try {
      const session = await getUserAuthSession()
      setSession(session as SessionType)
      setIsLoading(false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 401 is always returned when a user is not logged in, any other code is an error
      if (error.response?.status !== 401 && error.status !== 401) {
        Sentry.withScope(function (scope) {
          scope
            .setTag('operations', 'ory')
            .setLevel('error')
            .setFingerprint(['getUserAuthSession'])
            .setExtras({
              action: 'getUserAuthSession',
              ...configureSentryErrors(error),
            })
          Sentry.captureException(error)
        })
      }
      setSession(null)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isLoading) getSession()
  }, [getSession, isLoading, reloadSession])

  useEffect(() => {
    if (reloadSession) getSession()
  }, [getSession, reloadSession])

  return {
    isSessionLoading: isLoading,
    session,
  }
}

export const useGetSession = (
  reloadSession?: boolean,
  skipZezamCheck?: boolean
): {
  isSessionLoading: boolean
  session: SessionType | null
} => {
  const isSeamlessAuthEnabled = useFlag(FLAG_SEAMLESS_AUTH)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [session, setSession] = useState<SessionType | null>(null)
  const [checkZezamSession] = useCheckSessionMutation()

  const getUserAuthZezamSession = useCallback(
    async (skipZezamCheck?: boolean) => {
      if (skipZezamCheck) {
        return null
      }

      const session = await checkZezamSession().unwrap()

      return {
        active: true,
        isSeamlessAuth: true,
        identity: {
          traits: {
            email: session.email,
          },
          metadata_public: {
            user_id: session.user_id,
            roles: [],
          },
        },
      }
    },
    [checkZezamSession]
  )

  const getSession = useCallback(async () => {
    try {
      const isMetapicRedirect =
        (searchParams.get('from-metapic') ||
          localStorage.getItem('isSeamlessAuth')) &&
        isSeamlessAuthEnabled
      const session = isMetapicRedirect
        ? await getUserAuthZezamSession(skipZezamCheck)
        : await getUserAuthSession()
      setSession(session as SessionType)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 401 is always returned when a user is not logged in, any other code is an error
      if (error.response?.status !== 401 && error.status !== 401) {
        Sentry.withScope(function (scope) {
          scope
            .setTag('operations', 'ory')
            .setLevel('error')
            .setFingerprint(['getUserAuthSession'])
            .setExtras({
              action: 'getUserAuthSession',
              ...configureSentryErrors(error),
            })
          Sentry.captureException(error)
        })
      }
      setSession(null)
    } finally {
      setIsLoading(false)
    }
  }, [
    isSeamlessAuthEnabled,
    searchParams,
    getUserAuthZezamSession,
    skipZezamCheck,
  ])

  useEffect(() => {
    if (isLoading) getSession()
  }, [getSession, isLoading, reloadSession])

  useEffect(() => {
    if (reloadSession) getSession()
  }, [getSession, reloadSession])

  useEffect(() => {
    if (searchParams.get('from-metapic')) {
      localStorage.setItem('isSeamlessAuth', 'true')
    }
  }, [searchParams, setSearchParams])

  return {
    isSessionLoading: isLoading,
    session,
  }
}

/*
 *
 * This method is used for login, registration & reset-password flows
 *
 * If it is a 0auth provider (Facebook, Google, etc.), we need to have a separate
 * form that handles sending only provider name and let it handle further by
 * this provider.
 *
 * If it is a classic email+password flow, we need a separate form with the
 * needed inputs and a separate dataset.
 *
 * Fields:
 *
 * 1. `csrf_token` is important for any transaction and should always be added to
 * the form submission.
 *
 * 2. `identifier` || `traits.email` || `email` is an email.
 *
 * 3. `provider` is a 0auth services (Github, Facebook, etc.)
 *
 * 4. `method` is a type needed for email login, we should only include it for email.
 *
 * 5. `password` is a password field, we don't really need to render it from the
 * fields array, but we still use it to render our custom field.
 *
 * */

export const useFormatLoginRegisterRecoveryFlowNodes = (
  flow: LoginFlow | RegistrationFlow | RecoveryFlow | null
): { fields: LoginRegisterRecoverFieldsFormatTypes } => {
  const [fields, setFields] = useState<LoginRegisterRecoverFieldsFormatTypes>({
    csrfToken: [],
    google: [],
    github: [],
    facebook: [],
    twitter: [],
    apple: [],
    tiktok: [],
    email: [],
    password: [],
    method: [],
  })

  useEffect(() => {
    const fields = flow?.ui?.nodes as NodeInputType[]

    if (fields) {
      const tokenFields = fields.filter(
        ({ attributes }) => attributes.name === OryFormInputTypes.CSRF
      )

      const methodFields = fields.filter(
        ({ attributes }) => attributes.name === OryFormInputTypes.METHOD
      )

      const emailFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.IDENTIFIER ||
          attributes.name === OryFormInputTypes.TRAITS_EMAIL ||
          attributes.name === OryFormInputTypes.EMAIL
      )

      const passwordFields = fields.filter(
        ({ attributes }) => attributes.name === OryFormInputTypes.PASSWORD
      )

      const googleFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.GOOGLE
      )

      const githubFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.GITHUB
      )

      const facebookFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.FACEBOOK
      )

      const twitterFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.TWITTER
      )

      const appleFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.APPLE
      )

      const tiktokFields = fields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.PROVIDER &&
          attributes.value === ProviderTypes.TIKTOK
      )

      setFields({
        csrfToken: tokenFields,
        method: methodFields,
        email: emailFields,
        password: passwordFields,
        google: googleFields,
        github: githubFields,
        facebook: facebookFields,
        twitter: twitterFields,
        apple: appleFields,
        tiktok: tiktokFields,
      })
    }
  }, [flow?.ui?.nodes])

  return {
    fields,
  }
}

/*
 *
 * This method is used for settings flow.
 *
 * For every 0auth provider (Facebook, Google, etc.), we need to have a separate
 * form that handles sending only provider name and let it handle further by
 * this provider.
 *
 * For a classic email+password flow, we need a separate form with the
 * needed inputs and a separate dataset.
 *
 * Fields:
 *
 * 1. `csrf_token` is important for any transaction and should always be added to
 * the form submission.
 *
 * 2. `link` is used for adding 0auth services (Github, Facebook, etc.)
 *
 * 3. `method` is a type with value `password` needed for email login, we should only include it for email.
 *
 * 4. `password` is a password field, we don't really need to render it from the
 * fields array, but we still use it to render our custom field.
 *
 * */

export const useFormatSettingsFlowNodes = (
  flow: SettingsFlow | null
): { fields: SettingsFieldsFormatTypes } => {
  const [fields, setFields] = useState<SettingsFieldsFormatTypes>({
    csrfToken: [],
    google: [],
    github: [],
    facebook: [],
    twitter: [],
    apple: [],
    tiktok: [],
    password: [],
    method: [],
  })

  useEffect(() => {
    const fields = flow?.ui?.nodes as NodeInputType[]

    if (fields) {
      // Adding placeholder to the fields
      const formattedFields = fields
        ?.filter(({ attributes }) => isUiNodeInputAttributes(attributes))
        .map((field) => {
          switch (field.attributes.name) {
            case OryFormInputTypes.PASSWORD:
              return {
                ...field,
                attributes: { ...field.attributes, placeholder: 'Password' },
              }
            default:
              return field
          }
        })

      const tokenFields = formattedFields.filter(
        ({ attributes }) => attributes.name === OryFormInputTypes.CSRF
      )

      const methodFields = formattedFields.filter(
        ({ attributes }) =>
          attributes.name === OryFormInputTypes.METHOD &&
          attributes.value === 'password'
      )

      const passwordFields = formattedFields.filter(
        ({ attributes }) => attributes.name === OryFormInputTypes.PASSWORD
      )

      const googleFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.GOOGLE
      )

      const githubFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.GITHUB
      )

      const facebookFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.FACEBOOK
      )

      const twitterFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.TWITTER
      )

      const appleFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.APPLE
      )

      const tiktokFields = formattedFields.filter(
        ({ attributes }) =>
          (attributes.name === OryFormInputTypes.LINK ||
            attributes.name === OryFormInputTypes.UNLINK) &&
          attributes.value === ProviderTypes.TIKTOK
      )

      setFields({
        csrfToken: tokenFields,
        method: methodFields,
        password: passwordFields,
        google: googleFields,
        github: githubFields,
        facebook: facebookFields,
        twitter: twitterFields,
        apple: appleFields,
        tiktok: tiktokFields,
      })
    }
  }, [flow?.ui?.nodes])

  return {
    fields,
  }
}

type UseGetLoginFlowProps = {
  flowId?: string
  returnToUrl?: string
}
export const useGetLoginFlow = ({
  flowId,
  returnToUrl,
}: UseGetLoginFlowProps): {
  flow: LoginFlow | null
  isFlowLoading: boolean
  fields: LoginRegisterRecoverFieldsFormatTypes
  error: string
} => {
  const [flow, setFlow] = useState<LoginFlow | null>(null)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { fields } = useFormatLoginRegisterRecoveryFlowNodes(flow)

  useEffect(() => {
    const createFlow = async () => {
      setIsLoading(true)
      const flowResponse = await initiateLoginFlow(flowId, true, returnToUrl)
      if (flowResponse?.error) {
        setError(flowResponse?.error)
      } else if (flowResponse.flow) {
        setFlow(flowResponse.flow)
      }
      setIsLoading(false)
    }

    createFlow()
  }, [flowId, returnToUrl])

  return {
    isFlowLoading: isLoading,
    flow,
    fields,
    error,
  }
}

type UseGetRegistrationFlowProps = {
  flowId?: string
  returnToUrl?: string
}
export const useGetRegistrationFlow = ({
  flowId,
  returnToUrl,
}: UseGetRegistrationFlowProps): {
  flow: RegistrationFlow | null
  isFlowLoading: boolean
  fields: LoginRegisterRecoverFieldsFormatTypes
  error?: string
  failedEmail?: string
  failedProvider?: string
} => {
  const [flow, setFlow] = useState<RegistrationFlow | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errorData, setErrorData] = useState<
    { error: string; failedEmail?: string; failedProvider?: string } | undefined
  >()
  const { fields } = useFormatLoginRegisterRecoveryFlowNodes(flow)

  useEffect(() => {
    const createFlow = async () => {
      setIsLoading(true)
      const flowResponse = await initiateRegistrationFlow({
        flowId,
        returnToUrl,
      })
      if (flowResponse.error) {
        setErrorData({
          error: flowResponse.error,
          failedEmail: flowResponse.failedEmail,
          failedProvider: flowResponse.failedProvider,
        })
      }

      if (flowResponse.flow) {
        setFlow(flowResponse.flow)
      }

      setIsLoading(false)
    }

    createFlow()
  }, [flowId, returnToUrl])

  return {
    isFlowLoading: isLoading,
    flow,
    fields,
    error: errorData?.error,
    failedEmail: errorData?.failedEmail,
    failedProvider: errorData?.failedProvider,
  }
}

export const useGetRecoveryFlow = (): {
  flow: RecoveryFlow | null
  isFlowLoading: boolean
  fields: LoginRegisterRecoverFieldsFormatTypes
  error?: string
} => {
  const [flow, setFlow] = useState<RecoveryFlow | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const { fields } = useFormatLoginRegisterRecoveryFlowNodes(flow)

  useEffect(() => {
    const createFlow = async () => {
      setIsLoading(true)
      const flowResponse = await initiateRecoverFlow()

      if (flowResponse.error) {
        setError(flowResponse.error)
      } else if (flowResponse.flow) {
        setFlow(flowResponse.flow)
      }
      setIsLoading(false)
    }

    createFlow()
  }, [])

  return {
    isFlowLoading: isLoading,
    flow,
    fields,
    error,
  }
}

export const useCreateLogoutFlow = (): {
  url?: string
  token?: string
  isLoading: boolean
  error?: {
    message?: string
    status: number
  }
} => {
  const [seamlessLogout] = useLogoutMutation()
  const { session, isSessionLoading } = useGetSession()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<
    | {
        message?: string
        status: number
      }
    | undefined
  >(undefined)
  const [logoutData, setLogoutData] = useState<
    | {
        url?: string
        token?: string
        error?: {
          message?: string
          status: number
        }
      }
    | undefined
  >()

  useEffect(() => {
    const getLogoutLink = async () => {
      await seamlessLogout()
      const logoutData = await initiateLogoutFlow()
      if (logoutData.error) {
        setError(logoutData.error)
      } else {
        setLogoutData(logoutData)
      }
    }

    if (session) {
      getLogoutLink()
    } else {
      if (!isSessionLoading) setIsLoading(false)
    }
  }, [session, isSessionLoading, seamlessLogout])

  return {
    url: logoutData?.url,
    token: logoutData?.token,
    isLoading,
    error,
  }
}

export const useGetSettingsFlow = () => {
  const [flow, setFlow] = useState<SettingsFlow | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const { fields } = useFormatSettingsFlowNodes(flow)

  useEffect(() => {
    const createFlow = async () => {
      setIsLoading(true)
      const flowResponse = await initiateSettingsFlow()

      if (flowResponse.error) {
        setError(flowResponse.error)
      } else if (flowResponse.flow) {
        setFlow(flowResponse.flow)
      }
      setIsLoading(false)
    }

    createFlow()
  }, [])

  return { isFlowLoading: isLoading, error, flow, fields }
}

type UseRandomSlugProps = {
  skip?: boolean
  maxRetryAttempts?: number
  baseURL: string
}

type UseRandomSlugResult = {
  validSlug?: string
  isGeneratingSlug: boolean
}

/**
 * Hook responsible for
 *  1. generate a random slug
 *  2. validate it with server
 *  3. when not valid retry to a max of retries defined
 *
 * @param props
 *  skip (optional) - true if we want to skip it
 *    default: false
 *  maxRetryAttempts (optional) - number of retries if validation of slug fails
 *    default: 5
 *
 * @returns
 *  validSlug: undefined when not possible to validate it
 * isGeneratingSlug: true when it is generating and validating slug
 */
export const useRandomSlug = (
  props: UseRandomSlugProps
): UseRandomSlugResult => {
  const skip = props?.skip || false
  const maxRetryAttempts =
    typeof props.maxRetryAttempts === 'number' && props.maxRetryAttempts >= 0
      ? props.maxRetryAttempts
      : 5

  const attemptsCountRef = useRef(0)
  const [isGeneratingSlug, setGeneratingSlug] = useState<boolean>(false)
  const [validSlug, setValidSlug] = useState<string>()
  const [validateSlug] = useValidateSlugMutation()

  useEffect(() => {
    const generateAndValidateSlug = async () => {
      if (skip) return

      setGeneratingSlug(true)
      const slug = generateRandomSlug()
      const validateSlugResponse = await validateSlug({ slug })

      if ('error' in validateSlugResponse) {
        if (attemptsCountRef.current < maxRetryAttempts) {
          attemptsCountRef.current++
          setGeneratingSlug(false)
          generateAndValidateSlug()
        }
      } else {
        setGeneratingSlug(false)
        setValidSlug(slug)
      }
    }

    if (!skip) {
      generateAndValidateSlug()
    }
  }, [skip, maxRetryAttempts, props.baseURL, validateSlug])

  return {
    validSlug,
    isGeneratingSlug:
      isGeneratingSlug && attemptsCountRef.current < maxRetryAttempts,
  }
}
