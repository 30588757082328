import styled from 'styled-components'
import { Theme } from '../../theme'

export enum HorizontalButtonsFilterVariant {
  REGULAR = 'regular',
  LARGE = 'large',
}

export const StyledWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background: ${Theme.Colors.lightSand};
  z-index: 2;
`

export const StyledContainer = styled.div<{
  $variant: HorizontalButtonsFilterVariant
}>`
  display: flex;
  align-items: center;
  gap: ${({ $variant }) =>
    $variant === HorizontalButtonsFilterVariant.LARGE ? '16px' : '8px'};
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 4px;
  margin-left: -4px;
`

export const StyledFilterButton = styled.button<{
  $isActive: boolean
  $variant: HorizontalButtonsFilterVariant
}>`
  padding: ${({ $variant }) =>
    $variant === HorizontalButtonsFilterVariant.LARGE
      ? '16px 24px'
      : '8px 16px'};
  border: 1px solid #dddfe1;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${Theme.Button.primaryColor};
  background: transparent;

  &:hover {
    color: ${Theme.Button.primaryColor};
    transform: scale(1.05);
  }

  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: 600;
      border: 1px solid ${Theme.Button.backgroundColor};
    `}
`
