import {
  ImageFormType,
  ImageResponseType,
  ProfileImageShape,
} from './image.types'

import { CreatorPageType } from './creatorPage.types'

export enum HeaderPosition {
  CENTER = 'center',
  LEFT = 'left',
}

export enum ButtonShapeStyle {
  PILL = 'pill',
  ROUNDED = 'rounded',
  SQUARE = 'square',
}

export enum ButtonBorderStyle {
  FILLED = 'filled',
  OUTLINE = 'outline',
}

export enum ButtonThumbnailPositionStyle {
  REGULAR = 'regular',
  LEADING = 'leading',
  STICK_OUT = 'stick_out',
}

export enum ButtonShadowStyle {
  NO_SHADOW = 'no_shadow',
  HARD = 'hard',
  SOFT = 'soft',
}

export enum SectionCoverFormat {
  LANDSCAPE = 'landscape',
  SQUARE = 'square',
  PORTRAIT = 'portrait',
}

export enum ColorOpacity {
  '10%' = '1a',
  '20%' = '2a',
  '40%' = '4a',
  '60%' = '6a',
  '80%' = '8a',
  '100%' = '',
}

export enum BackgroundTypeStyle {
  REGULAR = 'regular',
  FADED_DARK = 'faded_dark',
  FADED_LIGHT = 'faded_light',
  BLURRED_LIGHT = 'blurred_light',
  BLURRED_DARK = 'blurred_dark',
}

export enum SocialsPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum FontEnum {
  INTER = 'Inter',
  ROBOTO_SLAB = 'Roboto Slab',
  OSWALD = 'Oswald',
  KALAM = 'Kalam',
  AMATIC_SC = 'Amatic SC',
  PLAYFAIR_DISPLAY = 'Playfair Display',
}

export enum CustomizationFormProperties {
  HEADER_POSITION = 'header_position',
  PROFILE_IMAGE_SHAPE = 'profile_image_shape',
  BACKGROUND = 'background_color',
  BACKGROUND_TYPE = 'background_type',
  BACKGROUND_TYPE_STYLE = 'background_type_style',
  BACKGROUND_GRADIENT_COLORS = 'background_gradient_colors',
  TEXT = 'text_color',
  BUTTONS_BACKGROUND_COLOR = 'buttons_background_color',
  BUTTONS_BACKGROUND_OPACITY = 'buttons_background_opacity',
  BUTTONS_TEXT_COLOR = 'buttons_text_color',
  BUTTONS_SHAPE = 'buttons_shape_style',
  BUTTONS_BORDER_STYLE = 'buttons_border_style',
  BUTTONS_BORDER_COLOR = 'buttons_border_color',
  BUTTONS_SHADOW = 'buttons_shadow_style',
  BUTTONS_SHADOW_COLOR = 'buttons_shadow_color',
  BUTTONS_SHADOW_OPACITY = 'buttons_shadow_opacity',
  BUTTONS_THUMBNAIL_POSITION = 'buttons_thumbnail_position',
  SOCIALS_PLACEMENT = 'socials_placement',
  FONT = 'font',
}

export const customizationFormPropertiesNames: string[] = Object.entries(
  CustomizationFormProperties
).map(([, value]) => value)

export enum BackgroundType {
  Solid = 'solid',
  Gradient = 'gradient',
  Image = 'image',
  Header = 'header',
}

type BackgroundSolidType = {
  backgroundType: BackgroundType.Solid
  backgroundColor: string
}

type BackgroundImageType = {
  backgroundType: BackgroundType.Image
  backgroundStyle: BackgroundTypeStyle
  image?: ImageResponseType
}

type BackgroundHeaderType = {
  backgroundType: BackgroundType.Header
  backgroundStyle: BackgroundTypeStyle
  backgroundColor: string
  image?: ImageResponseType
}

type BackgroundGradientType = {
  backgroundType: BackgroundType.Gradient
  gradientColors: string[]
}

export type BackgroundInfo =
  | BackgroundSolidType
  | BackgroundImageType
  | BackgroundHeaderType
  | BackgroundGradientType

export type CustomizationPropsType = {
  [CustomizationFormProperties.HEADER_POSITION]: HeaderPosition
  [CustomizationFormProperties.BACKGROUND_TYPE]: BackgroundType
  [CustomizationFormProperties.BACKGROUND_TYPE_STYLE]: BackgroundTypeStyle
  [CustomizationFormProperties.BACKGROUND]: string
  [CustomizationFormProperties.TEXT]: string
  [CustomizationFormProperties.BUTTONS_BACKGROUND_COLOR]: string
  [CustomizationFormProperties.BUTTONS_BORDER_COLOR]: string
  [CustomizationFormProperties.BUTTONS_TEXT_COLOR]: string
  [CustomizationFormProperties.BUTTONS_BORDER_STYLE]: ButtonBorderStyle
  [CustomizationFormProperties.BUTTONS_SHAPE]: ButtonShapeStyle
  [CustomizationFormProperties.BACKGROUND_GRADIENT_COLORS]?:
    | [string, string]
    | string[]
  [CustomizationFormProperties.BUTTONS_BACKGROUND_OPACITY]?: ColorOpacity
  [CustomizationFormProperties.BUTTONS_SHADOW]?: ButtonShadowStyle
  [CustomizationFormProperties.BUTTONS_SHADOW_OPACITY]?: ColorOpacity
  [CustomizationFormProperties.BUTTONS_SHADOW_COLOR]?: string
  [CustomizationFormProperties.PROFILE_IMAGE_SHAPE]?: ProfileImageShape
  [CustomizationFormProperties.BUTTONS_THUMBNAIL_POSITION]?: ButtonThumbnailPositionStyle
  [CustomizationFormProperties.SOCIALS_PLACEMENT]?: SocialsPlacement
  [CustomizationFormProperties.FONT]?: FontEnum
}

export type MyPageAppearanceForm = CustomizationPropsType & {
  backgroundImage?: ImageFormType
  gradientStartColor?: string
  gradientEndColor?: string
  name: string
  about?: string
  profileImage?: ImageFormType
  hideFooterBanner: boolean
}

export type MyPageAppearanceApiPayload = MyPageAppearanceForm & {
  profileImageAction: 'none' | 'delete' | 'upload'
  _links: CreatorPageType['_links']
}
