import styled from 'styled-components'

import { setUnity } from '../../helpers'

import {
  SpaceStylesType,
  SpaceStylesTypeStyledProps,
} from '../../styles/styles.types'

const StyledBox = styled.div<SpaceStylesTypeStyledProps>`
  ${({ $display }) => $display && `display: ${$display};`}
  ${({ $position }) => $position && `position: ${$position};`}
  ${({ $borderRadius }) =>
    $borderRadius && `border-radius: ${setUnity($borderRadius)};`}
  ${({ $m }) => $m && `margin: ${setUnity($m)};`}
  ${({ $ml }) => $ml && `margin-left: ${setUnity($ml)};`}
  ${({ $w }) => $w && `width: ${setUnity($w)};`}
  ${({ $maxW }) => $maxW && `max-width: ${setUnity($maxW)};`}
  ${({ $h }) => $h && `height: ${setUnity($h)};`}
  ${({ $mr }) => $mr && `margin-right: ${setUnity($mr)};`}
  ${({ $mt }) => $mt && `margin-top: ${setUnity($mt)};`}
  ${({ $mb }) => $mb && `margin-bottom: ${setUnity($mb)};`}
  ${({ $my = 0, $mx = 0 }) =>
    Boolean($my || $mx) && `margin: ${setUnity($my)} ${setUnity($mx)};`}
  ${({ $p }) => $p && `padding: ${setUnity($p)};`}
  ${({ $pl }) => $pl && `padding-left: ${setUnity($pl)};`}
  ${({ $pr }) => $pr && `padding-right: ${setUnity($pr)};`}
  ${({ $pt }) => $pt && `padding-top: ${setUnity($pt)};`}
  ${({ $pb }) => $pb && `padding-bottom: ${setUnity($pb)};`}
  ${({ $py = 0, $px = 0 }) =>
    Boolean($py || $px) && `padding: ${setUnity($py)} ${setUnity($px)};`}
`

type BoxProps = {
  className?: string
  children: React.ReactNode
  onClick?: () => void
} & SpaceStylesType
export const Box = ({
  className,
  children,
  display,
  position,
  borderRadius,
  w,
  h,
  maxW,
  m,
  ml,
  mr,
  mt,
  mb,
  mx,
  my,
  p,
  pl,
  pr,
  pt,
  pb,
  px,
  py,
  onClick,
  ...props
}: BoxProps) => {
  return (
    <StyledBox
      className={className}
      $display={display}
      $position={position}
      $borderRadius={borderRadius}
      $w={w}
      $h={h}
      $maxW={maxW}
      $m={m}
      $ml={ml}
      $mr={mr}
      $mb={mb}
      $mt={mt}
      $mx={mx}
      $my={my}
      $p={p}
      $pl={pl}
      $pr={pr}
      $pb={pb}
      $pt={pt}
      $px={px}
      $py={py}
      onClick={onClick || undefined}
      {...props}
    >
      {children}
    </StyledBox>
  )
}
