import styled from 'styled-components'

import { Card, CardProps, Icon, TextEllipsis } from '../../atoms'

import { Carousel, StyledCarouselWrapper } from '../Carousel'
import { ListItemCard } from '../CustomCard'

type ArticleType = { title: string; url: string }

export type InstagramArticlesCardProps = Required<
  Pick<CardProps, 'headerTitle'>
> & {
  articles: { title: string; url: string }[]
  onArticleClick?: (a: ArticleType) => void
  articlesPerColumn?: number
}

const StyledListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledContainer = styled.div`
  height: 256px;
`

export const InstagramArticlesCard = ({
  articles,
  onArticleClick,
  articlesPerColumn = 4,
  ...cardProps
}: InstagramArticlesCardProps) => {
  if (!articles.length) return null

  const children = []
  for (let i = 0; i < articles.length; i += articlesPerColumn) {
    children.push(
      <StyledListContainer key={articles[i].url}>
        {articles.slice(i, i + articlesPerColumn).map((a) => (
          <li key={a.url}>
            <ListItemCard
              as="a"
              href={a.url}
              target="_blank"
              rel="noopener noreferrer"
              title={<TextEllipsis>{a.title}</TextEllipsis>}
              rightDecoratorOverride={<Icon.InstagramMono />}
              disableHoverEffect
              onClick={() => onArticleClick && onArticleClick(a)}
            />
          </li>
        ))}
      </StyledListContainer>
    )
  }

  return (
    <Card {...cardProps}>
      <StyledContainer>
        <StyledCarouselWrapper>
          <Carousel
            slidesToShow={articles.length <= articlesPerColumn ? 1 : 1.2}
            slidesToScroll={0.9}
          >
            {children}
          </Carousel>
        </StyledCarouselWrapper>
      </StyledContainer>
    </Card>
  )
}
