import styled, { css } from 'styled-components'
import { BREAKPOINTS, media } from '../../helpers'
import { Theme } from '../../theme'

export const StyledArrowWrapper = styled.div`
  display: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 24px;
  padding: 12px;
  background: ${Theme.Colors.background.base};
  border: 1px solid ${Theme.Colors.background.alternate};
  border-radius: 50%;
  pointer-events: all;

  ${media.from(
    BREAKPOINTS.md,
    css`
      display: block;
    `
  )};
`

export const StyledArrowContainer = styled.div<{ $isRightArrow?: boolean }>`
  display: flex;
  pointer-events: none;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 200px;
  max-width: 15%;
  z-index: 1;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  left: -16px;

  ${media.from(
    BREAKPOINTS.lg,
    css`
      width: 200px;
    `
  )}

  ${({ $isRightArrow }) =>
    $isRightArrow &&
    css`
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      left: unset;
      right: -16px;
    `};
`
