import { nanoid } from 'nanoid'
import {
  formatCommission,
  formatCommissionWithoutRounding,
  formatPrice,
} from '../money/money.helpers'

import {
  AffiliateLinkConversionData,
  AffiliateProductsDataType,
  Brand,
  BrandAPI,
  BrandSearchData,
  CommissionAggregateAPIType,
  CommissionAggregateType,
  CommissionAPIType,
  CommissionType,
  ConvertAffiliateLinkAPIResponse,
  BrandsAPIResponse,
  TrendingProductSearchAPIResponse,
  ProductSearchAPI,
  AffiliateProductType,
  DiscountType,
  OffersLatestCommissionIncreasesAPIResponse,
  OfferWithCommissionDiff,
  CommissionSource,
  CommissionSourceAPIType,
} from '@web-apps/utils-types'
import { getDefaultImageFromAPIResponse, imageUrlBuilder } from '../images'
import { DateHelpers } from '../date'

export const fromAffiliateCommissionAPI = (
  c?: CommissionAPIType,
  withoutRounding = false
): CommissionType | undefined => {
  if (!c) return

  const formatter = withoutRounding
    ? formatCommissionWithoutRounding
    : formatCommission

  switch (c.type) {
    case 'fixed':
    case 'expected':
      return c.amount.amount
        ? {
            formattedText: formatter({
              type: 'money',
              value: c.amount,
            }),
            type: c.type,
            amount: c.amount,
          }
        : undefined
    case 'percentage':
      return c.fraction
        ? {
            formattedText: formatter({
              type: 'percent',
              value: c.fraction,
            }),
            type: c.type,
            fraction: c.fraction,
          }
        : undefined
    default:
      return
  }
}

export const fromCommissionAggregateAPI = (
  commissionAggregateAPI?: CommissionAggregateAPIType
): CommissionAggregateType | undefined => {
  if (!commissionAggregateAPI) return

  return {
    ...(commissionAggregateAPI.sources && {
      sources: fromAffiliateCommissionSourcesAPI(
        commissionAggregateAPI.sources
      ),
    }),
    ...(commissionAggregateAPI.cpc && {
      cpc: fromAffiliateCommissionAPI(commissionAggregateAPI.cpc),
    }),
    ...(commissionAggregateAPI.cpa && {
      cpa: fromAffiliateCommissionAPI(commissionAggregateAPI.cpa),
    }),
    ...(commissionAggregateAPI.custom && {
      custom: commissionAggregateAPI.custom,
    }),
  }
}

export const fromAffiliateLinkConversionAPI = (
  conversionResponse: ConvertAffiliateLinkAPIResponse
): AffiliateLinkConversionData => ({
  isConvertible: conversionResponse.is_convertible,
  isAffiliateLink: conversionResponse.is_affiliate_link,
  originalLink: conversionResponse.original_uri,
  commission: fromCommissionAggregateAPI(conversionResponse.commission),
  ...(conversionResponse.network && {
    network: conversionResponse.network,
  }),
  missedHints: conversionResponse.missed_hints,
  matches: (conversionResponse.matches || []).map((item) => ({
    name: item.name,
    createApiEndpoint: conversionResponse._links[
      'affiliate_link/create/v2'
    ]?.find((link) => link.name === item.name)?.href,
    createSectionApiEndpoint: conversionResponse._links[
      'affiliate_link_section/create'
    ]?.find((link) => link.name === item.name)?.href,
    commission: fromCommissionAggregateAPI(
      item.commission
    ) as CommissionAggregateType,
  })),
  api: {
    ...(conversionResponse._links?.['affiliate_link_section/create_in'] && {
      createContainerApiEndpoint:
        conversionResponse._links['affiliate_link_section/create_in'].href,
    }),
    ...(conversionResponse._links[
      'creator_page/external_link_section/create'
    ] && {
      createExternalSectionAPIEndpoint:
        conversionResponse._links['creator_page/external_link_section/create']
          ?.href,
    }),
  },
})

export const fromBrandAPI = (brandAPI: BrandAPI): Brand => ({
  id: brandAPI._links?.self?.id,
  name: brandAPI.name,
  commission: fromCommissionAggregateAPI(brandAPI.commission),
  campaign: fromBrandCampaign(brandAPI?.campaign),
  websiteURL: brandAPI._links?.about?.href,
  logoURL: imageUrlBuilder({
    width: 192,
    height: 192,
    image: getDefaultImageFromAPIResponse(
      brandAPI._links &&
        (brandAPI._links['brand/image'] || brandAPI._links['self/image'])
    ),
  }),
  mediaURL: brandAPI._links?.media?.href,
  affiliateConverter: brandAPI._links?.affiliate_converter,
})

export const fromBrandSearchAPI = (
  response: BrandsAPIResponse
): BrandSearchData => ({
  brands: (response.content || []).map(fromBrandAPI),
})

export const fromTopBrandsAPI = (response: BrandsAPIResponse): Brand[] =>
  response.content.map(fromBrandAPI)

export const fromOffersLatestCommissionIncreasesAPI = (
  response: OffersLatestCommissionIncreasesAPIResponse
): OfferWithCommissionDiff[] =>
  response.content.map(({ diff, ...offerData }) => {
    const diffTransformed: CommissionType =
      diff.type === 'percentage'
        ? {
            type: 'percentage',
            fraction: diff.diff,
            formattedText: formatCommission({
              type: 'percent',
              value: diff.diff,
            }),
          }
        : {
            type: 'fixed',
            amount: diff.diff,
            formattedText: formatCommission({
              type: 'money',
              value: diff.diff,
            }),
          }

    return {
      data: fromBrandAPI(offerData),
      diff: diffTransformed,
    }
  })

export const fromDiscountAPI = (
  product: ProductSearchAPI
): DiscountType | undefined => {
  if (product.discount?.relative)
    return {
      formattedText: `-${Math.round(product.discount.relative * 100)}%`,
      type: 'relative',
      amount: product.discount.relative,
    }
  if (product.discount?.absolute?.amount)
    return {
      formattedText: `-${formatCommission({
        type: 'money',
        value: product.discount.absolute,
      })}`,
      type: 'absolute',
      amount: product.discount.absolute.amount,
    }
  return undefined
}

export const fromTrendingProductsAPI = (
  product: ProductSearchAPI
): AffiliateProductType => ({
  id: nanoid(),
  title: product.name,
  brandName: product._links.brand?.title || null,
  discount: fromDiscountAPI(product),
  price: product.price ? formatPrice(product.price) : undefined,
  url: product._links.clickout.href,
  commission: fromCommissionAggregateAPI(product.commission),
  mediaURL: product._links['self/image']?.[0].href,
  convertUrl: product._links['affiliate_link/create']?.[0].href,
  retailer: product._links.retailer?.href,
  api: {
    addAsSection: product._links['affiliate-link-section/create']?.[0].href,
    addToGrid: product._links['affiliate_link_section/create_in']?.[0].href,
    createAffiliateLink: product._links['affiliate_link/create']?.[0].href,
  },
})

export const fromTrendingProductsSearchAPI = (
  response: TrendingProductSearchAPIResponse
): AffiliateProductsDataType => ({
  products: (response.content || []).map(fromTrendingProductsAPI),
  totalProducts: response.page.total_elements,
  prevPage: response._links.prev
    ? { href: response._links.prev.href }
    : undefined,
  nextPage: response._links.next
    ? { href: response._links.next.href }
    : undefined,
})

export const fromAffiliateCommissionSourcesAPI = (
  sources: CommissionSourceAPIType[]
): CommissionSource[] => {
  const sourcesMap = sources.reduce(
    (result, item) => {
      const formattedCpc = fromAffiliateCommissionAPI(item.cpc, true)
      const formattedCpa = fromAffiliateCommissionAPI(item.cpa, true)

      let key: string | undefined
      let type: 'cpc' | 'cpa' | 'cpc_cpa' | undefined
      let values: CommissionSource['values'] | undefined

      if (formattedCpc && formattedCpa) {
        type = 'cpc_cpa'
        key = `${formattedCpc.formattedText}_${formattedCpa.formattedText}`
        values = {
          cpc: formattedCpc.formattedText,
          cpa: formattedCpa.formattedText,
        }
      } else if (formattedCpa) {
        type = 'cpa'
        key = formattedCpa.formattedText
        values = {
          cpa: formattedCpa.formattedText,
        }
      } else if (formattedCpc) {
        type = 'cpc'
        key = formattedCpc.formattedText
        values = {
          cpc: formattedCpc?.formattedText,
        }
      }

      if (key && type && values) {
        if (result[key]) {
          result[key].sources.push(item.source)
        } else {
          result[key] = {
            sources: [item.source],
            renderType: type,
            values,
          }
        }
      }

      return result
    },
    {} as {
      [key: string]: CommissionSource
    }
  )

  return Object.entries(sourcesMap).map(([, value]) => value)
}

export const fromBrandCampaign = (
  campaign: BrandAPI['campaign']
): Brand['campaign'] | undefined => {
  if (!campaign) return

  return {
    ...(campaign.click_limit && {
      clicksLimit: {
        currentClicks: campaign.click_limit.current_clicks,
        maxClicks: campaign.click_limit.max_clicks,
        clicksLeft:
          campaign.click_limit.max_clicks - campaign.click_limit.current_clicks,
        currentClicksFraction:
          campaign.click_limit.current_clicks / campaign.click_limit.max_clicks,
      },
    }),
    ...(campaign.earnings_limit && {
      earningsLimit: {
        currentEarningFraction:
          campaign.earnings_limit.current_earnings.amount /
          campaign.earnings_limit.max_earnings.amount,
        amountLeft: formatPrice({
          amount:
            campaign.earnings_limit.max_earnings.amount -
            campaign.earnings_limit.current_earnings.amount,
          currency: campaign.earnings_limit.max_earnings.currency,
        }),
        currentEarnings: {
          ...campaign.earnings_limit.current_earnings,
          formattedText: formatPrice(campaign.earnings_limit.current_earnings),
        },
        maxEarnings: {
          ...campaign.earnings_limit.max_earnings,
          formattedText: formatPrice(campaign.earnings_limit.max_earnings),
        },
      },
    }),
    ...(campaign.period_limit && {
      periodLimit: {
        timeLeftFraction:
          1 -
          DateHelpers.getDifferenceInMilliseconds(
            campaign.period_limit.valid_until,
            DateHelpers.getCurrentDateInIso()
          ) /
            DateHelpers.getDifferenceInMilliseconds(
              campaign.period_limit.valid_until,
              campaign.period_limit.valid_from ||
                DateHelpers.getDateInPast(10, 'd')
            ),
        validUntil: campaign.period_limit.valid_until,
        validFrom: campaign.period_limit.valid_from,
        timeLeft: DateHelpers.getFormatDistanceFromNowToDate(
          campaign.period_limit.valid_until
        ),
      },
    }),
    title: campaign.title,
    text: campaign.text,
  }
}
