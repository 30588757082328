import { SocialNetworksEnum } from '@web-apps/utils-types'

import { withHttps, regexp } from '../common'

type SlugPattern = {
  match: RegExp
  isSlugPattern: boolean
  pattern: string
  prepareSlug?: (slug: string) => string
}

type SocialObject = {
  title: string
  placeholder: string
  icon: string
  monoIcon: string
  validatePatterns: ({ match: RegExp } | SlugPattern)[]
  errorMessage: string
}

type SocialNetworksType = {
  [key in SocialNetworksEnum]: SocialObject
}

export const isSocialValid = (type: SocialNetworksEnum, str: string) => {
  const patterns = SocialNetworks({})[type]?.validatePatterns
  str = str?.trim()

  return !!patterns && patterns.some((pattern) => pattern.match.test(str))
}

export const sanitizeSocialSlug = (type: SocialNetworksEnum, slug: string) => {
  if (!isSocialValid(type, slug)) {
    return null
  }

  const sanitize = SocialNetworks({})[type].validatePatterns.find(
    (item) => 'isSlugPattern' in item
  ) as SlugPattern
  slug = (slug || '').trim()

  if (!sanitize?.match.test(slug)) {
    return withHttps(slug)
  }

  if (sanitize.prepareSlug) {
    slug = sanitize.prepareSlug(slug)
  }

  return sanitize.pattern.replace('{SLUG}', slug)
}

const makeValidateDomainPattern = (str: string) => ({
  match: regexp(`${str}/([^?#:]+).*`),
})

const makeValidateSlugPattern = (
  str: string,
  prepareSlug?: (str: string) => string
) => ({
  match: new RegExp(`^[a-z0-9-_@.]+$`, 'i'),
  isSlugPattern: true,
  pattern: `https://${str}`,
  prepareSlug,
})

const SocialNetworks = ({
  translationsNamespace = 'creator',
}: {
  translationsNamespace?: string
}): SocialNetworksType => ({
  [SocialNetworksEnum.AmazonMusic]: {
    title: 'Amazon Music',
    placeholder: 'music.amazon.com/user-id/username',
    icon: 'AmazonMusic',
    monoIcon: 'AmazonMusicMono',
    validatePatterns: [
      makeValidateDomainPattern('music.amazon.com'),
      makeValidateSlugPattern('music.amazon.com/user-id/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_url`,
  },
  [SocialNetworksEnum.AppleMusic]: {
    title: 'Apple Music',
    placeholder: 'music.apple.com/artist/username/user-id',
    icon: 'AppleMusic',
    monoIcon: 'AppleMusicMono',
    validatePatterns: [makeValidateDomainPattern('(music|podcasts).apple.com')],
    errorMessage: `${translationsNamespace}:social_link.invalid_url`,
  },
  [SocialNetworksEnum.ApplePodcasts]: {
    title: 'Apple Podcasts',
    placeholder: 'podcasts.apple.com/podcast/your-podcast',
    icon: 'ApplePodcasts',
    monoIcon: 'ApplePodcastsMono',
    validatePatterns: [makeValidateDomainPattern('podcasts.apple.com')],
    errorMessage: `${translationsNamespace}:social_link.invalid_url`,
  },
  [SocialNetworksEnum.Bandcamp]: {
    title: 'Bandcamp',
    placeholder: 'username.bandcamp.com',
    icon: 'Bandcamp',
    monoIcon: 'BandcampMono',
    validatePatterns: [
      {
        match: new RegExp(
          '(https?://)?(www.)?[a-z0-9]+.bandcamp.com(/([^?#:]+).*)?'
        ),
      },
      {
        match: new RegExp(`^[a-z0-9-_@]+$`, 'i'),
        isSlugPattern: true,
        pattern: `https://{SLUG}.bandcamp.com`,
      },
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Beatport]: {
    title: 'Beatport',
    placeholder: 'beatport.com/artist/username/user-id',
    icon: 'Beatport',
    monoIcon: 'BeatportMono',
    validatePatterns: [makeValidateDomainPattern('beatport.com')],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.ClubHouse]: {
    title: 'ClubHouse',
    placeholder: 'clubhouse.com/@username',
    icon: 'ClubHouse',
    monoIcon: 'ClubhouseMono',
    validatePatterns: [
      makeValidateDomainPattern('clubhouse.com'),
      makeValidateSlugPattern('clubhouse.com/@{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Deezer]: {
    title: 'Deezer',
    placeholder: 'deezer.com/profile/username',
    icon: 'Deezer',
    monoIcon: 'DeezerMono',
    validatePatterns: [
      makeValidateDomainPattern('deezer.com'),
      makeValidateDomainPattern('deezer.page.link'),
      makeValidateSlugPattern('www.deezer.com/profile/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_url`,
  },
  [SocialNetworksEnum.Discord]: {
    title: 'Discord',
    placeholder: 'discord.com/invite-link',
    icon: 'Discord',
    monoIcon: 'DiscordMono',
    validatePatterns: [
      makeValidateDomainPattern('discord.(com|gg)'),
      makeValidateSlugPattern('discord.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_url`,
  },
  [SocialNetworksEnum.Email]: {
    title: 'Email',
    placeholder: 'your@emailaddress.com',
    icon: 'Email',
    monoIcon: 'EmailMono',
    validatePatterns: [
      {
        match: new RegExp('(mailto:)?([\\w\\.]+@([\\w-]+\\.)+[\\w-]{2,20}$)'),
      },
      {
        match: new RegExp('^([\\w\\.]+@([\\w-]+\\.)+[\\w-]{2,20}$)$'),
        isSlugPattern: true,
        pattern: 'mailto:{SLUG}',
      },
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_email`,
  },
  [SocialNetworksEnum.Facebook]: {
    title: 'Facebook',
    placeholder: 'facebook.com/username',
    icon: 'Facebook',
    monoIcon: 'FacebookMono',
    validatePatterns: [
      makeValidateDomainPattern('facebook.com'),
      makeValidateSlugPattern('facebook.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.GitHub]: {
    title: 'GitHub',
    placeholder: 'github.com/username',
    icon: 'GitHub',
    monoIcon: 'GithubMono',
    validatePatterns: [
      makeValidateDomainPattern('github.com'),
      makeValidateSlugPattern('github.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.GitLab]: {
    title: 'GitLab',
    placeholder: 'gitlab.com/username',
    icon: 'GitLab',
    monoIcon: 'GitlabMono',
    validatePatterns: [
      makeValidateDomainPattern('gitlab.com'),
      makeValidateSlugPattern('gitlab.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Instagram]: {
    title: 'Instagram',
    placeholder: 'instagram.com/username',
    icon: 'Instagram',
    monoIcon: 'InstagramMono',
    validatePatterns: [
      makeValidateDomainPattern('instagram.com'),
      makeValidateSlugPattern('instagram.com/{SLUG}', (slug) =>
        slug.replace('@', '')
      ),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.LinkedIn]: {
    title: 'LinkedIn',
    placeholder: 'linkedin.com/in/username',
    icon: 'LinkedIn',
    monoIcon: 'LinkedinMono',
    validatePatterns: [
      makeValidateDomainPattern('linkedin.com'),
      makeValidateSlugPattern('linkedin.com/in/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Patreon]: {
    title: 'Patreon',
    placeholder: 'patreon.com/username',
    icon: 'Patreon',
    monoIcon: 'PatreonMono',
    validatePatterns: [
      makeValidateDomainPattern('patreon.com'),
      makeValidateSlugPattern('patreon.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.PayPal]: {
    title: 'PayPal',
    placeholder: 'paypal.me/username',
    icon: 'PayPal',
    monoIcon: 'PaypalMono',
    validatePatterns: [
      makeValidateDomainPattern('paypal.(me|com)'),
      makeValidateSlugPattern('paypal.me/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Pinterest]: {
    title: 'Pinterest',
    placeholder: 'pinterest.com/username',
    icon: 'Pinterest',
    monoIcon: 'PinterestMono',
    validatePatterns: [
      makeValidateDomainPattern('pinterest.([a-z]{2,3}|(co.uk))'),
      makeValidateSlugPattern('pinterest.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Snapchat]: {
    title: 'Snapchat',
    placeholder: 'snapchat.com/add/username',
    icon: 'Snapchat',
    monoIcon: 'SnapchatMono',
    validatePatterns: [
      makeValidateDomainPattern('snapchat.com'),
      makeValidateSlugPattern('snapchat.com/add/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Soundcloud]: {
    title: 'Soundcloud',
    placeholder: 'soundcloud.com/username',
    icon: 'Soundcloud',
    monoIcon: 'SoundcloudMono',
    validatePatterns: [
      makeValidateDomainPattern('soundcloud.com'),
      makeValidateSlugPattern('soundcloud.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Spotify]: {
    title: 'Spotify',
    placeholder: 'open.spotify.com/artist/username',
    icon: 'Spotify',
    monoIcon: 'SpotifyMono',
    validatePatterns: [
      makeValidateDomainPattern('((open.)?spotify.com|spoti.fi)'),
      makeValidateSlugPattern('open.spotify.com/user/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Telegram]: {
    title: 'Telegram',
    placeholder: 't.me/invite-link',
    icon: 'Telegram',
    monoIcon: 'TelegramMono',
    validatePatterns: [
      makeValidateDomainPattern('t.me'),
      makeValidateSlugPattern('t.me/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.TikTok]: {
    title: 'TikTok',
    placeholder: 'tiktok.com/@username',
    icon: 'TikTok',
    monoIcon: 'TikTokMono',
    validatePatterns: [
      makeValidateDomainPattern('tiktok.com'),
      makeValidateSlugPattern('www.tiktok.com/@{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Twitch]: {
    title: 'Twitch',
    placeholder: 'twitch.tv/username',
    icon: 'Twitch',
    monoIcon: 'TwitchMono',
    validatePatterns: [
      makeValidateDomainPattern('twitch.tv'),
      makeValidateSlugPattern('twitch.tv/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Twitter]: {
    title: 'Twitter',
    placeholder: 'twitter.com/username',
    icon: 'Twitter',
    monoIcon: 'TwitterMono',
    validatePatterns: [
      makeValidateDomainPattern('twitter.com'),
      makeValidateSlugPattern('twitter.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Vimeo]: {
    title: 'Vimeo',
    placeholder: 'vimeo.com/username',
    icon: 'Vimeo',
    monoIcon: 'VimeoMono',
    validatePatterns: [
      makeValidateDomainPattern('vimeo.com'),
      makeValidateSlugPattern('vimeo.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Vkontakte]: {
    title: 'Vkontakte',
    placeholder: 'vk.com/username',
    icon: 'Vkontakte',
    monoIcon: 'VKontakteMono',
    validatePatterns: [
      makeValidateDomainPattern('vk.com'),
      makeValidateSlugPattern('vk.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.WhatsApp]: {
    title: 'WhatsApp',
    placeholder: '+00000000000',
    icon: 'WhatsApp',
    monoIcon: 'WhatsappMono',
    validatePatterns: [
      {
        match: new RegExp(
          '^([\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,9}$)$'
        ),
        isSlugPattern: true,
        pattern: 'https://api.whatsapp.com/send?phone={SLUG}',
      },
      {
        match: new RegExp(
          '(https?://)(www.)?api.whatsapp.com/send\\?phone=([\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,9}$)',
          'i'
        ),
      },
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_phone_number`,
  },
  [SocialNetworksEnum.Website]: {
    title: 'Website',
    placeholder: 'yourwebsite.here',
    icon: 'Website',
    monoIcon: 'WebsiteMono',
    validatePatterns: [
      { match: new RegExp(`(https?://)?(www.)?([^?#:]+).*`, 'i') },
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.YouTube]: {
    title: 'YouTube',
    placeholder: 'youtube.com/channel/username',
    icon: 'YouTube',
    monoIcon: 'YoutubeMono',
    validatePatterns: [
      makeValidateDomainPattern('(youtube.com|youtu.be)'),
      makeValidateSlugPattern('youtube.com/user/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
  [SocialNetworksEnum.Tumblr]: {
    title: 'Tumblr',
    placeholder: 'tumblr.com/username',
    icon: 'Tumblr',
    monoIcon: 'TumblrMono',
    validatePatterns: [
      makeValidateDomainPattern('tumblr.com'),
      makeValidateSlugPattern('tumblr.com/{SLUG}'),
    ],
    errorMessage: `${translationsNamespace}:social_link.invalid_username`,
  },
})

export const getSocialData = (
  type: SocialNetworksEnum,
  translationsNamespace = 'creator'
) => {
  return SocialNetworks({ translationsNamespace })[type]
}
