import axios from 'axios'
import { clientConfig } from '../config'
import { raise } from '../error'

declare global {
  // eslint-disable-next-line no-var
  var iframely: { load: () => void }
}

export type EmbedDataType = {
  html: string
  url: string
  title: string
  provider_name: string
}

export const loadIframelyEmbedJS = () => {
  if (
    !document.querySelectorAll('[data-iframely-url]').length &&
    !document.querySelectorAll('iframe[src*="iframe.ly"]').length
  )
    return

  const iframely = window?.iframely

  if (iframely?.load) {
    iframely.load()
  } else {
    const ifs = document.createElement('script')
    ifs.type = 'text/javascript'
    ifs.async = true
    ifs.src =
      ('https:' === document.location.protocol ? 'https:' : 'http:') +
      `//cdn.iframe.ly/embed.js`
    const s = document.getElementsByTagName('script')[0]

    s.parentNode?.insertBefore(ifs, s)
  }
}

export const getEmbedData = async (url: string): Promise<EmbedDataType> => {
  url = encodeURIComponent(url)
  const key =
    clientConfig?.IFRAMELY_KEY || raise("Iframely key hasn't been provided")

  const result = await axios.get(
    `https://cdn.iframe.ly/api/oembed?url=${url}&key=${key}&omit_script=1&lazy=1&maxheight=450`
  )

  return result.data
}
