import React from 'react'
import Head from 'next/head'
import { themeConfig } from '../../configs/theme.configs'

export const MetaHead = () => {
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`/safari-pinned-tab.svg`}
        color={themeConfig.meta.maskIconColor}
      />
      <link rel="shortcut icon" href={`/favicon.ico`} />
      <meta name="msapplication-config" content={`/browserconfig.xml`} />
      <meta
        name="msapplication-TileColor"
        content={themeConfig.meta.msapplicationTileColor}
      />
      <meta name="theme-color" content={themeConfig.meta.themeColor} />
    </Head>
  )
}
