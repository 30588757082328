import { AxiosError, AxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/nextjs'

enum ORY_FLOW_ACTION_ERROR_CODES {
  session_already_available = 'session_already_available',
  session_refresh_required = 'session_refresh_required',
  reload_page = 'reload_page',
  validation_error = 'validation_error',
  generic_error = 'generic_error',
  network_error = 'network_error',
}

export const configureSentryErrors = (
  error: AxiosError
): {
  data?: unknown
  errorCode?: string | number
  type: 'serverError' | 'noResponse' | 'orySdkError'
  message?: string
  name?: string
  rawError?: AxiosError
} => {
  // The client was given an error response (5xx, 4xx errors).
  const serverResponse = error.response
  // This occurs when the browser was able to initiate a request but did not receive a valid answer for any reason.
  // This error is most commonly caused by a bad/spotty network, a hanging backend that does not respond instantly to
  // each request, unauthorized or cross-domain requests, and lastly if the backend API returns an error.
  const request = error.request

  if (serverResponse)
    return {
      type: 'serverError',
      errorCode: serverResponse.status,
      data: JSON.stringify(
        serverResponse.data?.messages ||
          serverResponse.data?.ui?.messages ||
          serverResponse.data ||
          serverResponse
      ),
      message: error.message,
      name: error.name,
    }

  if (request)
    return {
      type: 'noResponse',
      data: error.request,
      errorCode: error.code,
      message: error.message,
      name: error.name,
    }

  // Something happened on the level of Ory SDK creating Axios request
  return { type: 'orySdkError', rawError: error }
}

/*
 *
 * This is a function that takes the Ory flow error response and converts it to
 * error code that will be used in the translations.
 *
 * In future we should implement manual page reload instead of showing errors.
 *
 * */
export const handleGetFlowError = (
  err: AxiosError,
  action: string
): string | undefined => {
  switch (err.response?.data.error?.id) {
    case 'session_already_available':
      // User is already signed in, we should redirect them to the home page.
      return ORY_FLOW_ACTION_ERROR_CODES.session_already_available
    case 'session_refresh_required':
      // User needs to re-authenticate to perform this action
      return ORY_FLOW_ACTION_ERROR_CODES.session_refresh_required
    case 'self_service_flow_return_to_forbidden':
      // The flow expired. User should request a new flow.
      return ORY_FLOW_ACTION_ERROR_CODES.reload_page
    case 'self_service_flow_expired':
      // The flow expired. User should request a new flow.
      return ORY_FLOW_ACTION_ERROR_CODES.reload_page
    case 'security_csrf_violation':
      // A CSRF violation occurred. User should request a new flow.
      return ORY_FLOW_ACTION_ERROR_CODES.reload_page
    case 'security_identity_mismatch':
      // The requested item was intended for someone else. User should request a new flow.
      return ORY_FLOW_ACTION_ERROR_CODES.reload_page
    case 'browser_location_change_required':
      // Ory asked us to point the user to this URL, usually is used when using oid provider flow.
      window.location.href = err.response.data.redirect_browser_to
      return ''
  }

  switch (err.response?.status) {
    case 410:
      // The flow expired. User should request a new flow.
      return ORY_FLOW_ACTION_ERROR_CODES.reload_page
    case 400:
      // Form data submitted is not valid
      return ORY_FLOW_ACTION_ERROR_CODES.validation_error
    case 404:
      // It is possible that we are passing a wrong `flow` so we can ignore it
      return
  }

  Sentry.withScope(function (scope) {
    scope
      .setTag('operations', 'ory')
      .setLevel('error')
      .setExtras({
        action,
        ...configureSentryErrors(err),
      })
      .setFingerprint([action])
    Sentry.captureException(new Error(`Ory operation failed: ${action}`))
  })
  if (err.message === 'Network Error') {
    return ORY_FLOW_ACTION_ERROR_CODES.network_error
  }
  return ORY_FLOW_ACTION_ERROR_CODES.generic_error
}

/*
 *
 * These are ory-specific form submit response codes. Represents which fields are
 * invalid.
 *
 * */

enum ORY_FLOW_SUBMIT_ERROR_CODES {
  generic_error = 'generic_error',
  wrong_credentials = 'wrong_credentials',
  insecure_password = 'insecure_password',
  reload_page = 'reload_page',
  email_taken = 'email_taken',
  invalid_token = 'invalid_token',
}

export const mapOryFormSubmissionErrorCodes = ({
  errorCode,
  action,
  error,
  config,
}: {
  errorCode: number
  action: string
  error?: unknown
  config?: AxiosRequestConfig
}): string => {
  switch (errorCode) {
    case 4000006:
      return ORY_FLOW_SUBMIT_ERROR_CODES.wrong_credentials
    case 4000005:
      return ORY_FLOW_SUBMIT_ERROR_CODES.insecure_password
    case 4000007:
      return ORY_FLOW_SUBMIT_ERROR_CODES.email_taken
    case 4010003:
      return ORY_FLOW_SUBMIT_ERROR_CODES.reload_page
    case 4000001:
      return ORY_FLOW_SUBMIT_ERROR_CODES.reload_page
    case 4070001:
      return ORY_FLOW_SUBMIT_ERROR_CODES.invalid_token
    default: {
      Sentry.withScope(function (scope) {
        scope
          .setTag('operations', 'ory')
          .setLevel('error')
          .setExtras({
            action,
            errorCode,
            data: error ? JSON.stringify(error) : undefined,
            config: config ? JSON.stringify(config) : undefined,
          })
        Sentry.captureException(
          new Error('Ory form submission operation failed')
        )
      })
      return ORY_FLOW_SUBMIT_ERROR_CODES.generic_error
    }
  }
}
