import React, { useContext, useLayoutEffect } from 'react'
import { TabListProps } from '@reach/tabs'
import { useRect, PRect } from '@reach/rect'
import {
  StyledBorder,
  StyledTab,
  StyledTabIcon,
  StyledTabList,
} from './Tabs.styles'
import { Icon, IconAssetsKeys } from '../../atoms'

type TabBaseProps = React.ComponentProps<typeof StyledTab>
type TabProps = Omit<TabBaseProps, 'children'> & {
  isActive?: boolean
  iconId?: IconAssetsKeys
  iconActiveId?: IconAssetsKeys
  label: string
  count?: number
}
const AnimatedContext = React.createContext<
  React.Dispatch<React.SetStateAction<PRect | null>>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>(() => {})

export const TabList = ({ children }: TabListProps) => {
  const [activeRect, setActiveRect] = React.useState<PRect | null>(null)
  const ref = React.useRef(null)
  const rect = useRect(ref)

  return (
    <AnimatedContext.Provider value={setActiveRect}>
      <StyledTabList ref={ref}>
        <>
          <StyledBorder
            $transform={
              activeRect && rect
                ? `translateX(${activeRect.left - rect.left}px) scaleX(${
                    activeRect.width / rect.width
                  })`
                : undefined
            }
          />
          {children}
        </>
      </StyledTabList>
    </AnimatedContext.Provider>
  )
}

export const Tab = ({
  isActive = false,
  iconId,
  iconActiveId,
  label,
  count,
  ...tabProps
}: TabProps) => {
  const ref = React.useRef(null)
  const rect = useRect(ref, { observe: isActive })
  const setActiveRect = useContext(AnimatedContext)

  useLayoutEffect(() => {
    if (isActive) {
      setActiveRect(rect)
    }
  }, [isActive, rect, setActiveRect])

  const TabComponent = iconId ? StyledTabIcon : StyledTab
  const IconComponent = iconId && Icon[iconId]
  const ActiveIconComponent = iconActiveId && Icon[iconActiveId]

  return (
    <TabComponent ref={ref} {...tabProps}>
      {IconComponent && (!isActive || !ActiveIconComponent) && (
        <IconComponent />
      )}
      {ActiveIconComponent && isActive && <ActiveIconComponent />}
      <span>
        {label}
        {typeof count === 'number' ? ` (${count})` : ''}
      </span>
    </TabComponent>
  )
}
