import React from 'react'
import ReactSwitch from 'react-switch'

import { Theme } from '../../theme'

import { StyledContainer, StyledLabelText } from './Switch.styles'

export enum SwitchVariant {
  NORMAL = 'normal',
  SMALL = 'small',
}

type ReactSwitchProps = React.ComponentProps<typeof ReactSwitch>
type SwitchProps = Pick<
  ReactSwitchProps,
  'onChange' | 'checked' | 'disabled'
> & { name: string; label?: string; variant?: SwitchVariant }

export const Switch = ({
  label,
  name,
  variant = SwitchVariant.NORMAL,
  ...restProps
}: SwitchProps) => {
  const { height, width, handleDiameter, borderRadius } =
    Theme.Switch.variant[variant]

  return (
    <StyledContainer $disabled={restProps.disabled} htmlFor={name}>
      <ReactSwitch
        {...restProps}
        name={name}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={handleDiameter}
        height={height}
        width={width}
        borderRadius={borderRadius}
        offColor={Theme.Switch.offColor}
      />

      {label && <StyledLabelText>{label}</StyledLabelText>}
    </StyledContainer>
  )
}
