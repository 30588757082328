import styled, { keyframes } from 'styled-components'
import { Theme } from '../../theme'

export const StyledSpinnerContainer = styled.div<{ $size: number }>`
  display: inline-block;
  position: relative;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const StyledSpinnerSection = styled.div<{
  $index?: number
  $size: number
  $color?: string
}>`
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ $size }) => $size - 4}px;
  height: ${({ $size }) => $size - 4}px;
  margin: 2px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ $color }) => $color || Theme.Colors.background.base}
    transparent transparent transparent;
  animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation-delay: ${({ $index }) => {
    switch ($index) {
      case 1:
        return '-0.45s'
      case 2:
        return '-0.3s'
      case 3:
        return '-0.15s'
      default:
        return '0s'
    }
  }};
`
