import styled from 'styled-components'

import { BREAKPOINTS, media } from '../../helpers/media.helpers'
import { Theme } from '../../theme'
import { Popover } from '../Popover'

export const StyledPopover = styled(Popover)`
  ${media.from(
    BREAKPOINTS.sm,
    `
      position: absolute;
      top: 100%;
      bottom unset;
      left: 0;
      right: unset;
    `
  )}
`

export const StyledOptionsWrapper = styled.div<{ $containerWidth: number }>`
  ${({ $containerWidth }) =>
    media.from(BREAKPOINTS.sm, `width: ${$containerWidth}px;`)}
`

export const StyledOptionsItem = styled.a<{ $isActive?: boolean }>`
  padding: 8px 4px;
  width: 100%;

  ${({ $isActive }) => $isActive && 'font-weight: bold;'}

  &:hover {
    background: ${Theme.Colors.background.global};
    border-radius: 8px;
  }
`
