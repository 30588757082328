import Axios from 'axios'
import axiosRetry from 'axios-retry'

const DEFAULT_RETRIES_COUNT = 3
const setRetryTimeout = (retryCount: number): number => {
  switch (retryCount) {
    case 1:
      return 10
    case 2:
      return 100
    default:
      return 1000
  }
}

/**
 *
 * This method should be used when we want to fetch any third-party service or
 * next.js API function.
 *
 * Returns a modified instance of axios.
 *
 * It is an extension of axios function, so can be used the same way as any
 * simple axios instance.
 *
 * The method fetches an API call and retries failed requests that return a
 * 5xx errors. By default, we allow 3 retries, and then the request will fail.
 *
 * If you need to change the number of retries or cancel retries, you can do it
 * when calling `request` method by passing `retriesCount` as 0.
 *
 * **/
export const request = (params?: {
  baseURL?: string
  retriesCount?: number
  timeoutMs?: number
}) => {
  const {
    baseURL = '',
    retriesCount = DEFAULT_RETRIES_COUNT,
    timeoutMs,
  } = params || {}

  const axiosInstance = Axios.create({ baseURL, timeout: timeoutMs })
  if (retriesCount !== 0) {
    axiosRetry(axiosInstance, {
      retries: retriesCount,
      retryDelay: setRetryTimeout,
    })
  }
  return axiosInstance
}

export default request
