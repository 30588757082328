import { ImageFormType, ImageResponseType } from '@web-apps/utils-types'

type ImageUrlBuilderProps = {
  image?: ImageResponseType | ImageFormType
  width?: number
  height?: number
}

type ImageLoaderProps = {
  src: string
  width?: number
  height?: number
}

export const SUPPORTED_IMAGE_TYPES = ['image/png', 'image/jpeg']

export const isImageOptimized = ({
  href,
  type,
}: ImageResponseType | ImageFormType) => {
  return (
    type &&
    href?.startsWith('https://media.zezam') &&
    SUPPORTED_IMAGE_TYPES.includes(type)
  )
}

export const imageUrlBuilder = ({
  image,
  width,
  height,
}: ImageUrlBuilderProps) => {
  if (!image) {
    return undefined
  }

  if (!isImageOptimized(image)) {
    return image.href
  }

  return buildImageUrl({ src: image.href as string, width, height })
}

const buildImageUrl = ({ src, width, height }: ImageLoaderProps) => {
  const url = new URL(src)
  const request = JSON.stringify({
    key: url.pathname.slice(1),
    edits: {
      resize: {
        ...(width && { width }),
        ...(height && { height }),
      },
    },
  })

  return `${url.origin}/${Buffer.from(request).toString('base64')}`
}

export const imageLoader = ({ src, width, height }: ImageLoaderProps) => {
  const link = buildImageUrl({ src, width, height })

  // The width parameter is needed to skip next js warning
  return `${link}?=${width || height}`
}
