const localePath =
  process.env.NODE_ENV === 'development'
    ? 'apps/public-app/public/locales'
    : './public/locales'

module.exports = {
  i18n: {
    locales: ['en', 'de'],
    defaultNS: [],
    localeDetection: false,
    localePath,
  },
}
