import axios from 'axios'

import { getEmbedData } from './iframely'
import { regexp } from '../common'
import { calculatePlatformRootURL } from '../urlBuilder/publicUrlsBuilder.helper'

export enum EmbedProvidersEnum {
  SPOTIFY = 'spotify',
  APPLE = 'apple',
  AMAZON = 'amazon',
  SOUND_CLOUD = 'soundCloud',
  BANDCAMP = 'bandcamp',
  DEEZER = 'deezer',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
}

export enum EmbedProvidersCategory {
  MUSIC = 'music',
  VIDEO = 'video',
  LINK = 'link',
}

type EmbedProvidersType = {
  id: EmbedProvidersEnum
  name: string
  matches: { pattern: RegExp; fallback?: string | boolean }[]
  getUrlDetails: (url: string) => Promise<{ title: string; url: string }>
}

export const MUSIC_PROVIDERS = [
  EmbedProvidersEnum.SPOTIFY,
  EmbedProvidersEnum.APPLE,
  EmbedProvidersEnum.AMAZON,
  EmbedProvidersEnum.SOUND_CLOUD,
  EmbedProvidersEnum.DEEZER,
  EmbedProvidersEnum.BANDCAMP,
]

export const VIDEO_PROVIDERS = [
  EmbedProvidersEnum.YOUTUBE,
  EmbedProvidersEnum.TWITCH,
  EmbedProvidersEnum.VIMEO,
  EmbedProvidersEnum.TIKTOK,
]

export const EMBED_PROVIDERS_MAP = {
  [EmbedProvidersCategory.VIDEO]: VIDEO_PROVIDERS,
  [EmbedProvidersCategory.MUSIC]: MUSIC_PROVIDERS,
}

export const embedProviders: {
  [key in EmbedProvidersEnum]: EmbedProvidersType
} = {
  [EmbedProvidersEnum.SPOTIFY]: {
    id: EmbedProvidersEnum.SPOTIFY,
    name: 'Spotify',
    matches: [
      {
        pattern: regexp(
          'open.spotify.com/(track|playlist|artist|album|show|episode)/([^?#:]+).*'
        ),
      },
      {
        pattern: regexp('spoti.fi/([^?#:/]+).*'),
      },
      {
        pattern: regexp('open.spotify.com/user/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_SPOTIFY_PROFILE',
      },
    ],
    getUrlDetails: async (url) => {
      if (
        regexp(
          'open.spotify.com/(track|playlist|artist|album)/([^?#:]+).*'
        ).test(url)
      ) {
        const { data } = await axios.get(
          `${calculatePlatformRootURL()}/api/embed`,
          {
            params: {
              url: encodeURIComponent(url),
              provider: EmbedProvidersEnum.SPOTIFY,
            },
          }
        )

        return data
      }

      return await getEmbedData(url)
    },
  },
  [EmbedProvidersEnum.DEEZER]: {
    id: EmbedProvidersEnum.DEEZER,
    name: 'Deezer',
    matches: [
      {
        pattern: regexp(
          'deezer.com(/[a-z]{2,3})?/(show|episode|album|playlist|track)/([^?#:]+).*'
        ),
      },
      {
        pattern: regexp('deezer.page.link/([^?#:]+).*'),
      },
      {
        pattern: regexp('deezer.com/[a-z]{2,3}/artist/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_DEEZER_ARTIST',
      },
    ],
    getUrlDetails: async (url) => {
      const { data } = await axios.get(
        `${calculatePlatformRootURL()}/api/embed`,
        {
          params: {
            url: encodeURIComponent(url),
            provider: EmbedProvidersEnum.DEEZER,
          },
        }
      )

      return data
    },
  },
  [EmbedProvidersEnum.APPLE]: {
    id: EmbedProvidersEnum.APPLE,
    name: 'Apple Music',
    matches: [
      {
        pattern: regexp(
          'music.apple.com/[a-z]{2,3}/(playlist|album)/([^?#:]+).*'
        ),
      },
      {
        pattern: regexp('podcasts.apple.com/[a-z]{2,3}/podcast/([^?#:]+).*'),
      },
      {
        pattern: regexp('music.apple.com/[a-z]{2,3}/artist/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_APPLE_ARTIST',
      },
      {
        pattern: regexp('music.apple.com/[a-z]{2,3}/music-video/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_APPLE_MUSIC_VIDEO',
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.AMAZON]: {
    id: EmbedProvidersEnum.AMAZON,
    name: 'Amazon Music',
    matches: [
      {
        pattern: regexp(
          'music.amazon.com/(playlists|artists|albums)/([^?#:]+).*'
        ),
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.SOUND_CLOUD]: {
    id: EmbedProvidersEnum.SOUND_CLOUD,
    name: 'SoundCloud',
    matches: [
      {
        pattern: regexp('soundcloud.com/([^?#:]+).*'),
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.BANDCAMP]: {
    id: EmbedProvidersEnum.BANDCAMP,
    name: 'Bandcamp',
    matches: [
      {
        pattern: regexp('[a-z0-9]+.bandcamp.com/(track|album)/([^?#:]+).*'),
      },
      {
        pattern: regexp('[a-z0-9]+.bandcamp.com/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_BANDCAMP_PAGE_NOT_SUPPORTED',
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.YOUTUBE]: {
    id: EmbedProvidersEnum.YOUTUBE,
    name: 'Youtube',
    matches: [
      { pattern: regexp('youtube.com/watch\\?v=([^?#:]+).*') },
      { pattern: regexp('youtube.com/playlist\\?list=([^?#:]+).*') },
      { pattern: regexp('youtube.com/shorts/([^?#:]+).*') },
      { pattern: regexp('youtu.be/([^?#:]+).*') },
      {
        pattern: regexp('youtube.com/channel/([^?#:]+).*'),
        fallback: 'EMBED_ERROR_YOUTUBE_CHANNEL',
      },
    ],
    getUrlDetails: async (url) => {
      const { data } = await axios.get(
        `${calculatePlatformRootURL()}/api/embed`,
        {
          params: {
            url: encodeURIComponent(url),
            provider: EmbedProvidersEnum.YOUTUBE,
          },
        }
      )

      return data
    },
  },
  [EmbedProvidersEnum.VIMEO]: {
    id: EmbedProvidersEnum.VIMEO,
    name: 'Vimeo',
    matches: [
      {
        pattern: regexp('vimeo.com/[0-9]+'),
      },
      {
        pattern: regexp('vimeo.com/[0-9]+/[a-z0-9]+'),
      },
      {
        pattern: regexp('vimeo.com/[a-z0-9]+'),
        fallback: 'EMBED_ERROR_VIMEO_CHANNEL',
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.TWITCH]: {
    id: EmbedProvidersEnum.TWITCH,
    name: 'Twitch',
    matches: [
      {
        pattern: regexp('twitch.tv/videos/([^?#:]+).*'),
      },
      {
        pattern: regexp('twitch.tv/([^?#:]+).*'),
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
  [EmbedProvidersEnum.TIKTOK]: {
    id: EmbedProvidersEnum.TIKTOK,
    name: 'TikTok',
    matches: [
      {
        pattern: regexp('tiktok.com/@[.a-z0-9_-]+/video/([^?#:]+).*'),
      },
      {
        pattern: regexp('tiktok.com/@[.a-z0-9_-]+'),
        fallback: 'EMBED_ERROR_TIKTOK_PROFILE',
      },
    ],
    getUrlDetails: async (url) => await getEmbedData(url),
  },
}
