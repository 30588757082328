import React, { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  gap: CSSProperties['gridGap']
  templateColumns?: CSSProperties['gridTemplateColumns']
}

type StyledGridTypes = {
  $gap: CSSProperties['gridGap']
  $templateColumns?: CSSProperties['gridTemplateColumns']
}

const StyledGrid = styled.div<StyledGridTypes>`
  display: grid;
  ${({ $gap }) => $gap && `gap: ${$gap}px`};
  ${({ $templateColumns }) =>
    $templateColumns && `grid-template-columns: ${$templateColumns}`};
`

export const Grid: React.FC<React.PropsWithChildren<Props>> = ({
  gap,
  templateColumns,
  children,
}) => {
  return (
    <StyledGrid $gap={gap} $templateColumns={templateColumns}>
      {children}
    </StyledGrid>
  )
}
