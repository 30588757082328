import styled from 'styled-components'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import '@reach/tabs/styles.css'

import { Theme } from '../../theme'
import { media, BREAKPOINTS } from '../../helpers'

export const StyledTabList = styled(TabList)`
  z-index: 3;
  gap: 24px;
  overflow-x: auto;
  top: 55px;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;

  background: ${Theme.Colors.background.base};
  border-bottom: 1px solid ${Theme.Colors.border.darker};

  padding: 0 16px;
  ${media.from(
    BREAKPOINTS.lg,
    `
    padding: 0 48px;
    left: ${Theme.Sidebar.width}px;
  `
  )}
`

export const StyledTab = styled(Tab)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 0 16px;

  &[data-selected] {
    border-bottom: none;
    font-weight: 600;
  }

  &[data-reach-tab]:active {
    background: initial;
  }
`

export const StyledTabIcon = styled(StyledTab)`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 4px 16px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      gap: 12px;
    `
  )}
`

export const StyledBorder = styled.div<{
  $transform?: string
}>`
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid ${Theme.Colors.background.dark};
  transition: transform 0.15s cubic-bezier(0.4, 0, 1, 1) 0s;
  transform-origin: left center;
  transform: ${({ $transform }) => $transform || 'scaleX(0)'};
`

export { Tabs, TabPanel, TabPanels }
