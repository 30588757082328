import { api } from '@web-apps/utils-shared'

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkSession: builder.mutation<{ email: string; user_id: string }, void>({
      query: () => ({
        path: '/whoami',
        method: 'GET',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        path: '/auth/logout',
        method: 'POST',
      }),
    }),
  }),
})

export const { useCheckSessionMutation, useLogoutMutation } = authApi
