import styled, { CSSProperties } from 'styled-components'

import { BREAKPOINTS, media } from '../../helpers'
import { Theme } from '../../theme'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const StyledPanelTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: ${Theme.Colors.typography.inactive};
  white-space: nowrap;
`

export const StyledPanelResultsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${media.from(
    BREAKPOINTS.sm,
    `
      gap: 8px;
    `
  )}
`

export const StyledValue = styled.span<{ $color: CSSProperties['color'] }>`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: ${({ $color }) => $color};
`

export const StyledGrowth = styled.span<{ $color: CSSProperties['color'] }>`
  font-size: 14px;
  line-height: 24px;
  color: ${({ $color }) => $color};
`

export const StyledIndicatorApprovedClicks = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: ${Theme.Colors.background.dark};
`

export const StyledIndicatorApprovedOrders = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: ${Theme.Colors.status.accepted};
`

export const StyledIndicatorApprovedEarnings = styled.div`
  width: 8px;
  height: 2px;
  border-radius: 2px;
  background: ${Theme.Colors.status.approved};
`

export const StyledIndicatorPendingEarnings = styled.div`
  width: 8px;
  height: 2px;
  border-radius: 2px;
  background: ${Theme.Colors.status.pending};
`

export const StyledIndicatorCanceledEarnings = styled.div`
  width: 8px;
  height: 2px;
  border-radius: 2px;
  background: ${Theme.Colors.status.cancelled};
`
