import styled from 'styled-components'

import { CountryISOCodeType } from '@web-apps/utils-types'

const flagSpritePositions: { [country in CountryISOCodeType]: string } = {
  DZ: 'center 0.2287%',
  AO: 'center 0.4524%',
  BJ: 'center 0.6721%',
  BW: 'center 0.8958%',
  BF: 'center 1.1162%',
  BI: 'center 1.3379%',
  CM: 'center 1.5589%',
  CV: 'center 1.7805%',
  CF: 'center 2.0047%',
  TD: 'center 2.2247%',
  CD: 'left 2.4467%',
  DJ: 'left 2.6674%',
  EG: 'center 2.8931%',
  GQ: 'center 3.1125%',
  ER: 'left 3.3325%',
  ET: 'center 3.5542%',
  GA: 'center 3.7759%',
  GM: 'center 4.0015%',
  GH: 'center 4.2229%',
  GN: 'center 4.441%',
  GW: 'left 4.66663%',
  CI: 'center 4.8844%',
  KE: 'center 5.1061%',
  LS: 'center 5.3298%',
  LR: 'left 5.5495%',
  LY: 'center 5.7712%',
  MG: 'center 5.994%',
  MW: 'center 6.2156%',
  ML: 'center 6.4363%',
  MR: 'center 6.658%',
  MU: 'center 6.8805%',
  YT: 'center 7.1038%',
  MA: 'center 7.3231%',
  MZ: 'left 7.5448%',
  NA: 'left 7.7661%',
  NE: 'center 7.98937%',
  NG: 'center 8.2099%',
  CG: 'center 8.4316%',
  RE: 'center 8.6533%',
  RW: 'right 8.875%',
  SH: 'center 9.0967%',
  AC: 'center 9.0967%',
  ST: 'center 9.32237%',
  SN: 'center 9.5426%',
  SC: 'left 9.7628%',
  SL: 'center 9.9845%',
  SO: 'center 10.2052%',
  ZA: 'left 10.4269%',
  SS: 'left 10.6486%',
  SD: 'center 10.8703%',
  SR: 'center 11.0945%',
  SZ: 'center 11.3135%',
  TG: 'left 11.5354%',
  TN: 'center 11.7593%',
  UG: 'center 11.9799%',
  TZ: 'center 12.2005%',
  EH: 'center 12.4222%',
  YE: 'center 12.644%',
  ZM: 'center 12.8664%',
  ZW: 'left 13.0873%',
  AI: 'center 13.309%',
  AG: 'center 13.5307%',
  AR: 'center 13.7524%',
  AW: 'left 13.9741%',
  BS: 'left 14.1958%',
  BB: 'center 14.4175%',
  BQ: 'center 14.6415%',
  BZ: 'center 14.8609%',
  BM: 'center 15.0826%',
  BO: 'center 15.306%',
  VG: 'center 15.528%',
  BR: 'center 15.7496%',
  CA: 'center 15.9694%',
  KY: 'center 16.1911%',
  CL: 'left 16.4128%',
  CO: 'left 16.6345%',
  KM: 'center 16.8562%',
  CR: 'center 17.0779%',
  CU: 'left 17.2996%',
  CW: 'center 17.5213%',
  DM: 'center 17.743%',
  DO: 'center 17.968%',
  EC: 'center 18.1864%',
  SV: 'center 18.4081%',
  FK: 'center 18.6298%',
  GF: 'center 18.8515%',
  GL: 'left 19.0732%',
  GD: 'center 19.2987%',
  GP: 'center 19.518%',
  GT: 'center 19.7383%',
  GY: 'center 19.96%',
  HT: 'center 20.1817%',
  HN: 'center 20.4034%',
  JM: 'center 20.6241%',
  MQ: 'center 20.8468%',
  MX: 'center 21.0685%',
  MS: 'center 21.2902%',
  NI: 'center 21.5119%',
  PA: 'center 21.7336%',
  PY: 'center 21.9553%',
  PE: 'center 22.177%',
  PR: 'left 22.4002%',
  BL: 'center 22.6204%',
  KN: 'center 22.8421%',
  LC: 'center 23.0638%',
  PM: 'center 23.2855%',
  VC: 'center 23.5072%',
  SX: 'left 23.732%',
  TT: 'center 23.9506%',
  TC: 'center 24.1723%',
  US: 'center 24.394%',
  VI: 'center 24.6157%',
  UY: 'left 24.8374%',
  VE: 'center 25.0591%',
  AB: 'center 25.279%',
  AF: 'center 25.5025%',
  AZ: 'center 25.7242%',
  BD: 'center 25.9459%',
  BT: 'center 26.1676%',
  BN: 'center 26.3885%',
  KH: 'center 26.611%',
  CN: 'left 26.8327%',
  GE: 'center 27.0544%',
  HK: 'center 27.2761%',
  IN: 'center 27.4978%',
  ID: 'center 27.7195%',
  JP: 'center 27.9412%',
  KZ: 'center 28.1615%',
  LA: 'center 28.3846%',
  MO: 'center 28.6063%',
  MY: 'center 28.829%',
  MV: 'center 29.0497%',
  MN: 'left 29.2714%',
  MM: 'center 29.4931%',
  NP: 'left 29.7148%',
  KP: 'left 29.9365%',
  MP: 'center 30.1582%',
  PW: 'center 30.3799%',
  PG: 'center 30.6016%',
  PH: 'left 30.8233%',
  SG: 'left 31.045%',
  KR: 'center 31.2667%',
  LK: 'right 31.4884%',
  TW: 'left 31.7101%',
  TJ: 'center 31.9318%',
  TH: 'center 32.1535%',
  TL: 'left 32.3752%',
  TM: 'center 32.5969%',
  VN: 'center 32.8186%',
  AL: 'center 33.0403%',
  AD: 'center 33.25975%',
  AM: 'center 33.4837%',
  AT: 'center 33.7054%',
  BY: 'left 33.9271%',
  BE: 'center 34.1488%',
  BA: 'center 34.3705%',
  BG: 'center 34.5922%',
  HR: 'center 34.8139%',
  CY: 'center 35.0356%',
  CZ: 'left 35.2555%',
  DK: 'center 35.479%',
  EE: 'center 35.7007%',
  FO: 'center 35.9224%',
  FI: 'center 36.1441%',
  FR: 'center 36.3658%',
  DE: 'center 36.5875%',
  GI: 'center 36.8092%',
  GR: 'left 37.0309%',
  HU: 'center 37.2526%',
  IS: 'center 37.4743%',
  IE: 'center 37.696%',
  IM: 'center 37.9177%',
  IT: 'center 38.1394%',
  JE: 'center 38.3611%',
  XK: 'center 38.5828%',
  LV: 'center 38.8045%',
  LI: 'left 39.0262%',
  LT: 'center 39.2479%',
  LU: 'center 39.4696%',
  MT: 'left 39.6913%',
  MD: 'center 39.913%',
  MC: 'center 40.1347%',
  ME: 'center 40.3564%',
  NL: 'center 40.5781%',
  MK: 'center 40.7998%',
  NO: 'center 41.0215%',
  PL: 'center 41.2432%',
  PT: 'center 41.4649%',
  RO: 'center 41.6866%',
  RU: 'center 41.9083%',
  SM: 'center 42.13%',
  RS: 'center 42.3517%',
  SK: 'center 42.5734%',
  SI: 'center 42.7951%',
  ES: 'left 43.0168%',
  SE: 'center 43.2385%',
  CH: 'center 43.4602%',
  TR: 'center 43.6819%',
  UA: 'center 43.9036%',
  GB: 'center 44.1253%',
  VA: 'right 44.347%',
  BH: 'center 44.5687%',
  IR: 'center 44.7904%',
  IQ: 'center 45.0121%',
  IL: 'center 45.2338%',
  KW: 'left 45.4555%',
  JO: 'left 45.6772%',
  KG: 'center 45.897%',
  LB: 'center 46.1206%',
  OM: 'left 46.3423%',
  PK: 'center 46.561%',
  PS: 'center 46.7857%',
  QA: 'center 47.0074%',
  SA: 'center 47.2291%',
  SY: 'center 47.4508%',
  AE: 'center 47.6725%',
  UZ: 'left 47.8942%',
  AS: 'right 48.1159%',
  AU: 'center 48.3376%',
  CX: 'center 48.5593%',
  CC: 'center 48.781%',
  CK: 'center 49.002%',
  FJ: 'center 49.2244%',
  PF: 'center 49.4445%',
  GU: 'center 49.6678%',
  KI: 'center 49.8895%',
  MH: 'left 50.1112%',
  FM: 'center 50.3329%',
  NC: 'center 50.5546%',
  NZ: 'center 50.7763%',
  NR: 'left 50.998%',
  NU: 'center 51.2197%',
  NF: 'center 51.4414%',
  WS: 'left 51.6631%',
  SB: 'left 51.8848%',
  TK: 'center 52.1065%',
  TO: 'left 52.3282%',
  TV: 'center 52.5499%',
  VU: 'left 52.7716%',
  WF: 'center 52.9933%',
}

// We should always pass the image file because we can't host it within the libraries
export const CountryFlag = styled.span<{
  $imgFile: string
  $countryISOCode: CountryISOCodeType
}>`
  display: inline-block;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  background-size: 100% 49494%;
  background-image: url(${({ $imgFile }) => $imgFile});
  background-repeat: no-repeat;
  background-position: ${({ $countryISOCode }) =>
    flagSpritePositions[$countryISOCode]};
  width: 18px;
  height: 11px;
`
