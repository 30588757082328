import styled from 'styled-components'
import { BREAKPOINTS, media } from '../../helpers'

import { Popover } from '../../molecules'
import { Theme } from '../../theme'

export const StyledShareLinkPopover = styled(Popover)<{ $dir: string }>`
  ${media.from(
    BREAKPOINTS.sm,
    `
    position: absolute;
    bottom 0;
    width: 375px;
    `
  )}

  ${({ $dir }) =>
    media.from(
      BREAKPOINTS.sm,
      `
        left: ${$dir === 'right' ? '0' : 'auto'};
      `
    )};
`

export const StyledLinkShareListItem = styled.a`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding-top: 16px;
  padding-bottom: 10px;

  & > button {
    width: 100%;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Theme.Colors.border.regular};
  }
`

export const StyledLinkShare = styled.div`
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    to right,
    rgb(255 255 255 / 10%) 5%,
    rgb(255, 255, 255) 30%
  );
`
