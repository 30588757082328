import { isUri, isWebUri } from 'valid-url'

const SLUG_MIN_LENGTH = 3
const SLUG_MAX_LENGTH = 50
const CONTENT_MAX_LENGTH = 2000
const URL_MAX_LENGTH = 4096

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const slugRegex = new RegExp(
  `^[a-zA-Z0-9._-]{${SLUG_MIN_LENGTH},${SLUG_MAX_LENGTH}}$`
)
export const urlRegex = new RegExp(
  '(https?:\\/\\/)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,50}\\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)'
)

const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 72

const isEmpty = (value?: string) => {
  return !(value && value.trim().length > 0)
}

const isValidURL = (url: string, options?: { allowedProtocols?: string[] }) => {
  if (!isUri(url)) return false

  const allowedProtocols = options?.allowedProtocols
  try {
    const parsedURL = new URL(url)

    return allowedProtocols
      ? allowedProtocols.includes(parsedURL.protocol)
      : true
  } catch {
    return false
  }
}

const isValidWebURL = (str: string) => {
  return !!isWebUri(str)
}

const isHtmlStringEmpty = (str: string) => {
  if (!str) {
    return false
  }

  return str.replace(/<(.|\n)*?>/g, '').trim().length === 0
}

const getHtmlTextOnlyLength = (htmlText: string) => {
  const regex = /(<([^>]+)>)/gi
  const htmlTextStrippedFromTags = (htmlText || '').replace(regex, '')

  return htmlTextStrippedFromTags.length
}

export const FormValidationHelpers = {
  isEmpty,
  isValidURL,
  isValidWebURL,
  slugRegex,
  emailRegex,
  urlRegex,
  passwordMinLength: PASSWORD_MIN_LENGTH,
  passwordMaxLength: PASSWORD_MAX_LENGTH,
  textareaMaxLength: CONTENT_MAX_LENGTH,
  urlMaxLength: URL_MAX_LENGTH,
  slugMinLength: SLUG_MIN_LENGTH,
  slugMaxLength: SLUG_MAX_LENGTH,
  isHtmlStringEmpty,
  getHtmlTextOnlyLength,
}
