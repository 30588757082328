import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

import { Backdrop, Icon, Flex, Typography, ClickOutside } from '../../atoms'
import { Button, ButtonSize, ButtonVariant } from '../Button'
import { Theme } from '../../theme'

import {
  StyledButton,
  StyledContainer,
  StyledPopover,
  StyledPopoverWrapper,
  StyledHeader,
} from './PopoverSelector.styles'

type PopoverSelectorProps = {
  value: string | React.ReactNode
  title: string
  isOpen?: boolean
  onClose: () => void
  onOpen: () => void
  usePortal?: boolean
  buttonSize?: ButtonSize
  hasError?: boolean
  maxContainerDesktopWidth?: string
  maxContainerDesktopHeight?: string
} & React.HTMLAttributes<HTMLDivElement>

export const PopoverSelector: React.FC<
  PopoverSelectorProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  value,
  children,
  title,
  isOpen,
  onClose,
  onOpen,
  usePortal = false,
  buttonSize = ButtonSize.SLIM,
  hasError,
  maxContainerDesktopWidth,
  maxContainerDesktopHeight,
  ...props
}: PopoverSelectorProps) => {
  const buttonRef = useRef(null)
  const popover = (
    <>
      <Backdrop mobileOnly />
      <StyledPopover $usePortal={usePortal} {...props}>
        <ClickOutside
          handleClickOutside={() => onClose()}
          ignoreElementRef={buttonRef}
        >
          <StyledPopoverWrapper
            $maxDesktopWidth={maxContainerDesktopWidth}
            $maxDesktopHeight={maxContainerDesktopHeight}
          >
            <StyledHeader>
              <Flex justify="space-between" align="center">
                <Typography variant="h3">{title}</Typography>
                <Button
                  type="button"
                  variant={ButtonVariant.PLAIN}
                  clickHandler={onClose}
                >
                  <Icon.Close fillColor={Theme.Button.backgroundColor} />
                </Button>
              </Flex>
            </StyledHeader>
            {children}
          </StyledPopoverWrapper>
        </ClickOutside>
      </StyledPopover>
    </>
  )

  return (
    <StyledContainer>
      <StyledButton
        ref={buttonRef}
        type="button"
        inverse
        size={buttonSize}
        width="100%"
        $hasError={hasError}
        clickHandler={() => (isOpen ? onClose() : onOpen())}
      >
        <Flex justify="space-between" align="center" gap={16} flex={1}>
          <div>{value}</div>
          <Icon.Chevron />
        </Flex>
      </StyledButton>
      {isOpen && (usePortal ? createPortal(popover, document.body) : popover)}
    </StyledContainer>
  )
}
