import React from 'react'

import { Icon, IconCatalogPaths } from '../../atoms'

import {
  StyledNotificationBannerContainer,
  StyledNotificationBannerContent,
  StyledCloseButton,
  StyledCustomImage,
} from './NotificationBanner.styles'

// To be extended when more styles are defined in the Design System e.g Info | Error | Success | Warning
type VariantType = 'Info' | 'Warning' | 'Custom' | 'Plain'
type VariantTypeNoCustom = Exclude<VariantType, 'Custom'>

type NotificationBannerBaseProps = {
  onClose?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

type NotificationBannerStaticVariants = {
  variant?: VariantTypeNoCustom
} & NotificationBannerBaseProps

type NotificationBannerCustomProps = {
  imageSrc: string
  imageAlt?: string
}

type NotificationBannerCustom = {
  variant: 'Custom'
} & NotificationBannerBaseProps &
  NotificationBannerCustomProps

type NotificationBannerProps =
  | NotificationBannerStaticVariants
  | NotificationBannerCustom

const VARIANT_ICONS: { [key in VariantTypeNoCustom]?: string } = {
  Info: IconCatalogPaths.InfoRegular,
  Warning: IconCatalogPaths.InfoImportant,
}

const VARIANT_ALT_MESSAGES: { [key in VariantTypeNoCustom]?: string } = {
  Info: 'i',
  Warning: '!',
}

export const NotificationBanner: React.FC<
  React.PropsWithChildren<NotificationBannerProps>
> = ({ variant = 'Info', onClose, children, className, ...props }) => {
  // TODO: The as should not be needed, I am trying to follow this technique, but having errors (https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions)
  const imageSrc =
    variant === 'Custom'
      ? (props as NotificationBannerCustomProps).imageSrc
      : VARIANT_ICONS[variant]
  const imageAlt =
    variant === 'Custom'
      ? (props as NotificationBannerCustomProps).imageAlt
      : VARIANT_ALT_MESSAGES[variant]

  const ImageComponent = variant === 'Custom' ? StyledCustomImage : 'img'

  return (
    <StyledNotificationBannerContainer className={className}>
      <StyledNotificationBannerContent>
        {ImageComponent && imageSrc && (
          <ImageComponent src={imageSrc} alt={imageAlt} />
        )}
        {children}
      </StyledNotificationBannerContent>

      {onClose && (
        <StyledCloseButton onClick={onClose}>
          <Icon.RoundedClose />
        </StyledCloseButton>
      )}
    </StyledNotificationBannerContainer>
  )
}
