import styled, { CSSProperties, css } from 'styled-components'

import { Card, Icon } from '../../atoms'

import { Theme } from '../../theme'
import { media, BREAKPOINTS } from '../../helpers'
import { ContainerHeightVariant } from './AnalyticsBarPanel.types'

export const StyledPanelContainer = styled(Card)`
  width: 100%;
  position: relative;
`

export const StyledHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  background: ${Theme.Colors.background.base};
`

export const StyledBarContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  /* important for ellipsis to work */
  min-width: 0;

  /* needed for cases where we want elements to be clickable and it not be prevented by filled bar */
  z-index: 1;
`

export const StyledStatsTable = styled.div<{
  $variant: ContainerHeightVariant
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: auto;

  font-weight: 400;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ $variant }) => {
    return css`
      height: ${$variant === ContainerHeightVariant.Medium ? '344px' : '256px'};
      ${media.from(
        BREAKPOINTS.md,
        `
            height: ${
              $variant === ContainerHeightVariant.Medium ? '392px' : '320px'
            };};
          `
      )};
    `
  }}
`

export const StyledEmptyStatsContainer = styled(StyledStatsTable)`
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: ${Theme.Colors.typography.inactive};
`

export const StyledInfoColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 70%;
  padding-inline-end: 8px;

  ${media.from(
    BREAKPOINTS.sm,
    `
    width: 75%;
    `
  )}

  ${media.from(
    BREAKPOINTS.md,
    `
    width: 80%;
    `
  )}
`

export const StyledResultColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;

  ${media.from(
    BREAKPOINTS.sm,
    `
    width: 25%;
    `
  )}

  ${media.from(
    BREAKPOINTS.md,
    `
    width: 20%;
    `
  )}
`

export const StyledColumnHeader = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  line-height: 24px;
  font-weight: 600;
  color: ${Theme.Colors.typography.inactive};
`

export const StyledValueColumnHeader = styled(StyledColumnHeader)`
  text-align: right;
`

export const StyledStatsCell = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
`

export const StyledStatsInfoCell = styled(StyledStatsCell)`
  position: relative;
  padding-block: 4px;
  padding-inline-start: 8px;
`

export const StyledStatsInfoContentBox = styled.div`
  display: flex;
  flex-direction: column;

  /* important for ellipsis to work */
  min-width: 0;
`

export const StyledStatsResultCell = styled(StyledStatsCell)`
  justify-content: flex-end;
  font-weight: 600;
`

export const StyledFilledBar = styled.div<{ $width?: CSSProperties['width'] }>`
  background: ${Theme.Colors.background.alternate};
  border-radius: 0 4px 4px 0;
  width: ${({ $width }) => $width};

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`

export const StyledCallToActionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`

export const StyledCallToActionHeader = styled.h4`
  font-weight: 600;
  font-size: 18px;

  margin: 0;
  padding: 0;
`

export const StyledPanelContent = styled.div<{ $disabled: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};

  ${StyledStatsInfoCell}, ${StyledStatsResultCell} {
    filter: ${({ $disabled }) => ($disabled ? `blur(4px)` : 'revert')};
  }
`

export const StyledCallToActionButtonContent = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledRightChevronIcon = styled(Icon.Chevron)`
  transform: rotate(-90deg);
`
