import styled, { css, keyframes } from 'styled-components'
import { Theme } from '../../theme'
import { setUnity } from '../../helpers'
import { ButtonSize, ButtonVariant } from './Button'

type StyledButtonType = {
  $variant?: ButtonVariant
  $inverse?: boolean
  $width?: number | string
  $size?: ButtonSize
  $isLoading?: boolean
  $outline: boolean
}

const buttonLoadingSpinner = keyframes`
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`

const StyledPrimaryButton = css<StyledButtonType>`
  display: inline-block;
  cursor: pointer;
  position: relative;
  ${({ $width }) => $width && `width: ${setUnity($width)};`};
  border: ${({ $outline }) =>
    $outline ? `1px solid ${Theme.Button.backgroundColor}` : 'none'};
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: ${({ $inverse }) =>
    $inverse ? Theme.Button.primaryColor : Theme.Colors.typography.inverse};
  background: ${({ $inverse }) =>
    $inverse ? Theme.Button.backgroundInverse : Theme.Button.backgroundColor};

  &:hover {
    color: ${({ $inverse }) =>
      $inverse ? Theme.Button.primaryColor : Theme.Colors.typography.inverse};
    text-decoration: none;
  }

  ${({ $size }) => {
    switch ($size) {
      case ButtonSize.SMALL:
        return css`
          padding: 8px;
          font-size: 12px;
          line-height: 16px;
          height: max-content;
          min-width: max-content;
        `
      case ButtonSize.SLIM:
        return css`
          padding: 8px 16px;
          font-size: 14px;
          line-height: 24px;
        `
      case ButtonSize.REGULAR:
      default:
        return css`
          padding: 12px 24px;
          line-height: 24px;
        `
    }
  }};

  &:disabled,
  &[disabled] {
    ${({ $isLoading, $inverse }) =>
      !$isLoading && !$inverse
        ? css`
            border: 1px solid ${Theme.Button.backgroundDisabledColor};
            background-color: ${Theme.Button.backgroundDisabledColor};
            color: ${Theme.Button.textDisabledColor};
            cursor: default;

            &:hover {
              color: ${Theme.Button.textDisabledColor};
            }
          `
        : 'opacity: 0.5'}
  }

  ${({ $isLoading, $inverse }) =>
    $isLoading &&
    css`
      > div {
        visibility: hidden;
        opacity: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: ${$inverse
          ? Theme.Button.primaryColor
          : Theme.Colors.background.base};
        border-radius: 50%;
        animation: ${buttonLoadingSpinner} 1s ease infinite;
      }
    `}
`

const StyledPlainButton = css<{ $width?: number | string }>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  font-weight: 600;
  cursor: pointer;
  outline: inherit;
  ${({ $width }) => $width && `width: ${setUnity($width)};`}
`

const StyledOutlineButton = css`
  ${StyledPrimaryButton};
  background: none;
  border: 1px solid ${Theme.Button.borderColor};
  color: ${Theme.Button.borderColor};
  outline: none;

  &:hover {
    color: ${Theme.Button.borderColor};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      &:after {
        border-top-color: ${Theme.Button.borderColor};
      }
    `}
`

export const StyledButton = styled.button<StyledButtonType>`
  ${({ $variant }) => {
    switch ($variant) {
      case ButtonVariant.PLAIN:
        return StyledPlainButton
      case ButtonVariant.OUTLINE:
        return StyledOutlineButton
      default:
        return StyledPrimaryButton
    }
  }}
`
