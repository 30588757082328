import React, { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

type StyledFlexTypes = {
  $direction?: CSSProperties['flexDirection']
  $justify?: CSSProperties['justifyContent']
  $align?: CSSProperties['alignItems']
  $flex?: CSSProperties['flex']
  $wrap?: CSSProperties['flexWrap']
  $shrink?: CSSProperties['flexShrink']
  $grow?: CSSProperties['flexGrow']
  $gap?: CSSProperties['gap']
}
const StyledFlex = styled.div<StyledFlexTypes>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction || 'row'};
  ${({ $flex }) => $flex && `flex: ${$flex}`};
  ${({ $justify }) => $justify && `justify-content: ${$justify}`};
  ${({ $align }) => $align && `align-items: ${$align}`};
  ${({ $wrap }) => $wrap && `flex-wrap: ${$wrap}`};
  ${({ $shrink }) => $shrink && `flex-shrink: ${$shrink}`}
  ${({ $grow }) => $grow && `flex-grow: ${$grow}`};
  ${({ $gap }) => $gap && `gap: ${$gap}px`};
`

type FlexProps = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactNode | ReactNode[]
  direction?: CSSProperties['flexDirection']
  justify?: CSSProperties['justifyContent']
  align?: CSSProperties['alignItems']
  flex?: CSSProperties['flex']
  wrap?: CSSProperties['flexWrap']
  shrink?: CSSProperties['flexShrink']
  grow?: CSSProperties['flexGrow']
  gap?: CSSProperties['gap']
}
export const Flex: React.FC<FlexProps> = ({
  direction,
  flex,
  justify,
  align,
  children,
  wrap,
  shrink,
  grow,
  gap,
  ...props
}) => {
  return (
    <StyledFlex
      $direction={direction}
      $flex={flex}
      $justify={justify}
      $align={align}
      $wrap={wrap}
      $shrink={shrink}
      $grow={grow}
      $gap={gap}
      {...props}
    >
      {children}
    </StyledFlex>
  )
}
