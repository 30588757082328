import { Configuration, OAuth2Api, IdentityApi, FrontendApi } from '@ory/client'
import { clientConfig } from '@web-apps/utils-shared'

export let ory: {
  identity: IdentityApi
  frontend: FrontendApi
  oauth2: OAuth2Api
}

export const initOry = () => {
  const config = new Configuration({
    basePath: clientConfig?.AUTH_URL,
    baseOptions: {
      withCredentials: true,
    },
  })
  ory = {
    identity: new IdentityApi(config),
    frontend: new FrontendApi(config),
    oauth2: new OAuth2Api(config),
  }
}
