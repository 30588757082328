import {
  BACKGROUND_ALTERNATE,
  BACKGROUND_BASE,
  BACKGROUND_GLOBAL,
  BACKGROUND_INFO,
  BUTTON_BACKGROUND,
  BUTTON_TEXT,
  BUTTON_INVERSE,
  BUTTON_TEXT_DISABLED,
  CHIP_ACTIVE_BACKGROUND_COLOR,
  CHIP_ACTIVE_BORDER_COLOR,
  BORDER_COLOR,
  INACTIVE_BG,
  LIGHT_SAND,
  LINE,
  PROGRESS_COLOR,
  SELECT_HIGHLIGHT_COLOR,
  STATUS_ACCEPTED,
  STATUS_COMPLETED,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_CANCELLED,
  SWITCH_OFF_COLOR,
  TEXT,
  TEXT_DANGER,
  TEXT_DISABLED,
  TEXT_ERROR,
  TEXT_INVERSE,
  TEXT_LABEL,
  TEXT_LIGHTER,
  TEXT_SUCCESS,
  TEXT_SUCCESS_LIGHTER,
  BADGE_COLOR,
  TEXT_AREA_ALTERNATIVE_BACKGROUND_COLOR,
  TEXT_INFO,
  BACKGROUND_DARK,
  HALF_TRANSPARENT_BLACK,
  TEXT_SKY,
  STAR,
  PAPAYA,
  TEXT_TITLE,
  LIGHT_GREEN,
} from './colors.constants'

const CARD_BORDER_RADIUS_IN_PX = 8
const FORM_FIELD_LABEL_SPACING_IN_PX = 8
const POPOVER_MAX_HEIGHT_IN_PX = 600
const POPOVER_Z_INDEX = 5
const CREATOR_PAGE_FOOTER_BANNER_Z_INDEX = 100

/* Colors */

// in the future this could be used as styled-components theme
export const Theme = {
  Card: {
    borderRadiusInPx: CARD_BORDER_RADIUS_IN_PX,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    borderColor: SELECT_HIGHLIGHT_COLOR,
    borderHoverColor: TEXT_DISABLED,
  },
  Form: {
    fieldLabelSpacingInPx: FORM_FIELD_LABEL_SPACING_IN_PX,
  },
  Text: {
    disabled: TEXT_DISABLED,
    success: TEXT_SUCCESS,
    inverted: TEXT_INVERSE,
  },
  Button: {
    backgroundColor: BUTTON_BACKGROUND,
    borderColor: BUTTON_BACKGROUND,
    backgroundDisabledColor: INACTIVE_BG,
    primaryColor: BUTTON_TEXT,
    primaryTextColor: TEXT_INVERSE,
    textDisabledColor: BUTTON_TEXT_DISABLED,
    backgroundInverse: BUTTON_INVERSE,
  },
  Switch: {
    offColor: SWITCH_OFF_COLOR,
    variant: {
      normal: {
        handleDiameter: 23,
        height: 31,
        width: 51,
        borderRadius: 15.5,
      },
      small: {
        handleDiameter: 16,
        height: 24,
        width: 40,
        borderRadius: 32,
      },
    },
  },
  Select: {
    highlightColor: SELECT_HIGHLIGHT_COLOR,
  },
  Chip: {
    borderColor: BORDER_COLOR,
    activeBorderColor: CHIP_ACTIVE_BORDER_COLOR,
    activeBackgroundColor: CHIP_ACTIVE_BACKGROUND_COLOR,
  },
  Tag: {
    variant: {
      info: {
        backgroundColor: BACKGROUND_INFO,
        textColor: TEXT,
      },
      success: {
        backgroundColor: TEXT_SUCCESS_LIGHTER,
        textColor: TEXT_SUCCESS,
      },
      emphasis: {
        backgroundColor: TEXT_LIGHTER,
        textColor: TEXT_INVERSE,
      },
      metapic: {
        backgroundColor: 'linear-gradient(45deg, #e47b49, #ea4c89)',
        textColor: TEXT_INVERSE,
      },
      warning: {
        backgroundColor: `${STAR}33`,
        textColor: STAR,
      },
      danger: {
        backgroundColor: `${TEXT_DANGER}22`,
        textColor: TEXT_DANGER,
      }
    },
  },
  TextArea: {
    variant: {
      alternative: {
        backgroundColor: TEXT_AREA_ALTERNATIVE_BACKGROUND_COLOR,
      },
    },
  },
  Sidebar: {
    width: 88,
    height: 64,
  },
  Popover: {
    maxHeightInPx: POPOVER_MAX_HEIGHT_IN_PX,
    zIndex: POPOVER_Z_INDEX,
  },
  CreatorPage: {
    footer: {
      zIndex: CREATOR_PAGE_FOOTER_BANNER_Z_INDEX,
      height: 40,
      bannerHeight: 120,
      hexAlpha: 66,
      defaultTextColor: '#333333',
    },
    wrapper: {
      maxWidth: 440,
    },
    customLink: {
      coverTextColor: TEXT_INVERSE,
    },
  },
  CreatorApp: {
    backgroundColor: LIGHT_SAND,
    header: {
      height: 64,
      zIndex: 4,
    },
  },
  Colors: {
    typography: {
      error: TEXT_ERROR,
      valid: TEXT_SUCCESS,
      inactive: TEXT_DISABLED,
      regular: TEXT,
      light: TEXT_LIGHTER,
      danger: TEXT_DANGER,
      title: TEXT_TITLE,
      label: TEXT_LABEL,
      inverse: TEXT_INVERSE,
      highlight: SELECT_HIGHLIGHT_COLOR,
      info: TEXT_INFO,
      sky: TEXT_SKY,
    },
    line: LINE,
    progress: PROGRESS_COLOR,
    lightSand: LIGHT_SAND,
    star: STAR,
    papaya: PAPAYA,
    selection: STATUS_ACCEPTED,
    border: {
      regular: BORDER_COLOR,
      darker: SELECT_HIGHLIGHT_COLOR,
      error: TEXT_ERROR,
    },
    background: {
      global: BACKGROUND_GLOBAL,
      alternate: BACKGROUND_ALTERNATE,
      base: BACKGROUND_BASE,
      inactive: INACTIVE_BG,
      badge: BADGE_COLOR,
      highlight: SELECT_HIGHLIGHT_COLOR,
      dark: BACKGROUND_DARK,
      halfTransparent: HALF_TRANSPARENT_BLACK,
    },
    status: {
      pending: STATUS_PENDING,
      accepted: STATUS_ACCEPTED,
      completed: STATUS_COMPLETED,
      cancelled: STATUS_CANCELLED,
      approved: STATUS_APPROVED,
    },
    rating: {
      worst: STATUS_CANCELLED,
      bad: BADGE_COLOR,
      neutral: STAR,
      good: LIGHT_GREEN,
      great: PROGRESS_COLOR,
    },
  },
  /* Hard coded because those colors do not come from our design system */
  CookieBanner: {
    buttonBackgroundColor: '#000',
    buttonTextColor: '#fff',
    mainButtonBackgroundColor: '#fff',
    mainButtonTextColor: '#000',
    titleTextColor: '#1f1f1f',
  },
  Tooltip: {
    background: { dark: BUTTON_BACKGROUND },
  },
}
