import styled from 'styled-components'
import { HTMLProps } from 'react'
import { Icon, IconAssetsKeys, IconComponent } from '../Icon'
import { Theme } from '../../theme'
import { PlainButton } from '../PlainButton'

export type TagVariantType =
  | 'info'
  | 'success'
  | 'emphasis'
  | 'metapic'
  | 'warning'
  | 'danger'
type StyledTagProps = {
  $variant: TagVariantType
}

export type TagProps = HTMLProps<HTMLSpanElement> & {
  text?: string | JSX.Element
  variant?: TagVariantType
  showIcon?: boolean
  onActionClick?: () => void
  actionIconName?: IconAssetsKeys
}

const StyledTag = styled.span<StyledTagProps>`
  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;

  padding: 4px 8px;
  border-radius: 8px;

  ${({ $variant }) => {
    if (['emphasis', 'metapic'].includes($variant)) {
      return `
        font-weight: 700;
        font-size: 12px;
      `
    }

    return `
      font-weight: 400;
      font-size: 10px;
    `
  }}

  line-height: 16px;
  text-transform: uppercase;

  ${({ $variant }) =>
    `
    color: ${Theme.Tag.variant[$variant].textColor};
    background: ${Theme.Tag.variant[$variant].backgroundColor};
  `}
`

const StyledDismissIcon = styled(Icon.RoundedClose)`
  position: absolute;
  top: -4px;
  right: -4px;
`

const IconForVariant: { [variant in TagVariantType]?: IconComponent } = {
  success: Icon.PlainCheck,
}

export const Tag = ({
  text,
  variant = 'info',
  showIcon = false,
  onActionClick,
  actionIconName,
  style = {},
}: TagProps) => {
  const IconComponent = showIcon && IconForVariant[variant]

  return (
    <StyledTag
      $variant={variant}
      style={{ ...style, cursor: onActionClick ? 'pointer' : undefined }}
      onClick={onActionClick}
    >
      {IconComponent && <IconComponent />} {text && text}
      {onActionClick && (
        <PlainButton onClick={onActionClick}>
          <StyledDismissIcon
            width="12px"
            height="12px"
            as={actionIconName ? Icon[actionIconName] : undefined}
          />
        </PlainButton>
      )}
    </StyledTag>
  )
}
