import styled from 'styled-components'
import { BREAKPOINTS, media } from '../../helpers/media.helpers'
import { StyledButton } from '../Button/Button.styles'

export const StyledPrimaryButton = styled(StyledButton)`
  overflow: hidden;
  ${media.to(
    BREAKPOINTS.md,
    `
    background: none;
    color: inherit;
    border: none;
    padding: 8px;
    margin-right: -8px;
  `
  )}
`

export const StyledPrimaryButtonMobile = styled.div`
  display: block;
  ${media.from(
    BREAKPOINTS.lg,
    `
      display: none;
    `
  )}
`

export const StyledPrimaryButtonDesktop = styled.span`
  display: none;

  ${media.from(
    BREAKPOINTS.lg,
    `
      display: block;
    `
  )}
`
