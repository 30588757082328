import { useEffect, useRef } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import { FieldValues, useForm, useWatch } from 'react-hook-form'

import {
  computeFieldsToWatch,
  enhanceFormFields,
  isHTMLElement,
} from './Form.utils'
import { FormConfigsType, FormProps } from './Form.types'

export const Form = <T extends FieldValues = FieldValues>(
  props: FormProps<T>
) => {
  const prevDefaultValueRef = useRef<FormProps<T>['initialValues']>()

  const formInfo = useForm<T>({
    defaultValues: props.initialValues,
  })

  // it will reset form when initial values are set for the first time
  const reset = formInfo.reset
  useEffect(() => {
    if (!prevDefaultValueRef.current && props.initialValues) {
      reset(props.initialValues)
    }
    prevDefaultValueRef.current = props.initialValues
  }, [props.initialValues, reset])

  const childrenToRender = flattenChildren(
    props.children({
      watch: formInfo.watch,
      register: formInfo.register,
      getValues: formInfo.getValues,
      setValue: formInfo.setValue,
      control: formInfo.control,
      formState: formInfo.formState,
      reset: formInfo.reset,
      unregister: formInfo.unregister,
      trigger: formInfo.trigger,
      setFocus: formInfo.setFocus,
      handleSubmit: formInfo.handleSubmit,
    })
  )

  useEffect(() => {
    const errorsValues = Object.values(formInfo.formState.errors)
    const firstErrorData = errorsValues.length ? errorsValues[0] : undefined

    if (
      firstErrorData &&
      formInfo.formState.isSubmitted &&
      formInfo.formState.submitCount > 0
    ) {
      // uses ref for uncontrolled elements supplied by react-hook-form and input name to find input element for controlled elements
      const firstErrorElement = isHTMLElement(firstErrorData.ref)
        ? firstErrorData.ref
        : // parentElement is used for controlled elements because our ImageUploader input is hidden and not positioned for the user in the page
          document.getElementsByName(firstErrorData.ref.name)[0]?.parentElement

      firstErrorElement?.scrollIntoView &&
        firstErrorElement?.scrollIntoView({
          behavior: `smooth`,
          block: 'center',
        })
    }
  }, [
    formInfo.formState.errors,
    formInfo.formState.submitCount,
    formInfo.formState.isSubmitted,
  ])

  const watchableFieldNames = Array.from(
    computeFieldsToWatch(
      childrenToRender,
      new Set(props.initialWatchableFields || [])
    )
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const watchableFieldValues: any = useWatch<T, any>({
    name: watchableFieldNames,
    control: formInfo.control,
  })

  const clonedChildren = enhanceFormFields(childrenToRender, {
    ...props,
    ...formInfo,
    watchableInfo: {
      watchableFieldNames,
      watchableFieldValues,
    },
  } as FormConfigsType)

  return (
    <form
      onSubmit={(e) => {
        if (props.stopPropagation) {
          e.preventDefault()
          e.stopPropagation()
        }

        const highOrderFunction = formInfo.handleSubmit(props.onSubmit)
        highOrderFunction(e)
      }}
    >
      {clonedChildren}
    </form>
  )
}
