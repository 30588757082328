import React from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import getConfig from 'next/config'
import { appWithTranslation } from 'next-i18next'
import PlausibleProvider from 'next-plausible'
import { createGlobalStyle } from 'styled-components'

import '../src/styles/index.css'
import { initConfig, initAPI } from '@web-apps/utils-shared'
import { initOry } from '@web-apps/feature-auth'
import { wrapper } from '../src/services/store'
import { getAnalyticsDomain } from '../src/utils/helpers/analytics.helpers'
import { MetaHead } from '../src/components/MetaHead/MetaHead.component'
import { resetStyleCss } from '@web-apps/ui-shared'
import {
  CreatorPageTheme,
  CustomizationFormProperties,
  FontEnum,
} from '@web-apps/utils-types'
import 'react-loading-skeleton/dist/skeleton.css'

const { i18n } = require('../next-i18next.config')

const GlobalStyle = createGlobalStyle`${resetStyleCss}`
const FontStyles = createGlobalStyle`
  body {
    font-family: ${({ theme }: { theme: CreatorPageTheme }) =>
      `${
        theme?.customization?.[CustomizationFormProperties.FONT] ||
        FontEnum.INTER
      }, sans-serif`};

    ${({ theme }) => {
      return theme?.customization?.[CustomizationFormProperties.FONT] ===
        FontEnum.AMATIC_SC
        ? 'font-size: 16px;'
        : ''
    }}
  }
`

const App: React.ComponentType<AppProps> = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig()

  initConfig({
    IFRAMELY_KEY: publicRuntimeConfig.iframely.key,
    CHARGEBEE_SITE: publicRuntimeConfig.chargebee.site,
    AUTH_URL: publicRuntimeConfig.baseUrls.auth,
    HOSTNAME: publicRuntimeConfig.hostname.split('.').slice(-2).join('.'),
  })
  initOry()
  initAPI({
    baseURL: publicRuntimeConfig.baseUrls.backend,
  })

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1"
        />
      </Head>
      <MetaHead />
      <GlobalStyle />
      <FontStyles />
      <PlausibleProvider
        domain={getAnalyticsDomain()}
        exclude="/z/register, /z/login, /z/change-password, /z/forgot-password"
      >
        <Component {...pageProps} />
      </PlausibleProvider>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default wrapper.withRedux(appWithTranslation(App, { i18n }))
