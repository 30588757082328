import { CountryISOCodeType } from '@web-apps/utils-types'

const DEFAULT_LANGUAGE = 'en'

export const OVERRIDE_LOCALE_COOKIE = 'override-locale'

// More explanation on preferred languages codes here: https://sbarnea.com/articles/simplified-locale-codes/
const SUPPORTED_LANGUAGES = ['en', 'de', 'es', 'fr', 'it', 'be']

const languages: {
  [key in string]: {
    value: string
    label: string
  }
} = {
  en: { value: 'en', label: 'English' },
  de: { value: 'de', label: 'Deutsch' },
  es: { value: 'es', label: 'Español' },
  fr: { value: 'fr', label: 'Français' },
  it: { value: 'it', label: 'Italiano' },
}

const DEVICE_LOCALE =
  process.env.NODE_ENV === 'test'
    ? 'en-GB'
    : Intl.NumberFormat().resolvedOptions().locale

const TRANSLATION_LANGUAGE =
  (typeof window !== 'undefined' &&
    localStorage.getItem(OVERRIDE_LOCALE_COOKIE)) ||
  // We want to support all browser options like `en-GB`, `en_GB`, `en`
  DEVICE_LOCALE.split('-')[0].split('_')[0].toLowerCase()

const DEFAULT_TIMEZONE = 'Europe/Berlin'

const CURRENT_DEVICE_TIMEZONE =
  Intl?.DateTimeFormat().resolvedOptions().timeZone

const DEFAULT_COUNTRY: CountryISOCodeType = 'DE'

export const LocalisationConstants = {
  languages,
  DEFAULT_LANGUAGE,
  OVERRIDE_LOCALE_COOKIE,
  TRANSLATION_LANGUAGE,
  SUPPORTED_LANGUAGES,
  DEFAULT_TIMEZONE,
  CURRENT_DEVICE_TIMEZONE,
  DEVICE_LOCALE,
  DEFAULT_COUNTRY,
}
