import styled from 'styled-components'
import { CSSProperties } from 'react'

import { Backdrop, ClickOutside, ClickOutsideProps } from '../../atoms'
import { BasePopoverCSS, StyledPopoverWrapper } from '../../styles'

import { PopoverHeader } from './PopoverHeader'

type PopoverProps = {
  title: string
  onClose: () => void
  onBackClick?: () => void
  withArrowBack?: boolean
  hideHeaderOnDesktop?: boolean
  backdropMobileOnly?: boolean
  enableCloseButtonOnDesktop?: boolean
  clickOutsideOverrideProps?: Partial<
    Pick<ClickOutsideProps, 'handleClick' | 'ignoreElementRef'>
  >
  wrapperStyles?: CSSProperties
  backdropStyles?: CSSProperties
}

const StyledWrapper = styled.div`
  ${BasePopoverCSS};
`

export const Popover: React.FC<
  PopoverProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  title,
  onClose,
  onBackClick,
  withArrowBack = false,
  hideHeaderOnDesktop = true,
  children,
  backdropMobileOnly = true,
  enableCloseButtonOnDesktop = false,
  clickOutsideOverrideProps = {},
  wrapperStyles = {},
  backdropStyles = {},
  ...props
}) => {
  return (
    <>
      <Backdrop mobileOnly={backdropMobileOnly} style={backdropStyles} />
      <StyledWrapper {...props}>
        <ClickOutside
          {...clickOutsideOverrideProps}
          handleClickOutside={(e) => {
            e.preventDefault()
            e.stopPropagation()

            onClose()
          }}
        >
          <StyledPopoverWrapper style={wrapperStyles}>
            <PopoverHeader
              text={title}
              onClose={onClose}
              onBackClick={onBackClick}
              withArrowBack={withArrowBack}
              hideOnDesktop={hideHeaderOnDesktop}
              enableCloseButtonOnDesktop={enableCloseButtonOnDesktop}
            />
            {children}
          </StyledPopoverWrapper>
        </ClickOutside>
      </StyledWrapper>
    </>
  )
}
