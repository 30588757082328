import { Chip, ChipProps } from '../../atoms'
import {
  StyledFilterLabel,
  StyledInsideContainer,
  StyledOutsideContainer,
} from './ChipFilter.styles'

type FilterBaseOption = {
  id: string
  label: string
  isActive?: boolean
}

type FilterOption = FilterBaseOption & ChipProps

type ChipFilterProps = {
  options: FilterOption[]
  label?: string
  onOptionSelected?: (selectedOptionId: string) => void
  sticky?: boolean
  className?: string
}

export const ChipFilter = ({
  label,
  options,
  onOptionSelected,
  sticky = false,
  className,
}: ChipFilterProps) => {
  return (
    <StyledOutsideContainer $sticky={sticky} className={className}>
      <StyledInsideContainer>
        {label && <StyledFilterLabel>{label}</StyledFilterLabel>}
        {(options || []).map(
          ({ id, label, isActive = false, as = 'a', onClick, ...props }) => (
            <Chip
              as={as}
              key={id}
              isActive={isActive}
              onClick={(e) => {
                onOptionSelected && onOptionSelected(id)
                if (onClick) onClick(e)
              }}
              {...props}
            >
              {label}
            </Chip>
          )
        )}
      </StyledInsideContainer>
    </StyledOutsideContainer>
  )
}
