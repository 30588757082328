import styled from 'styled-components'
import { Flex } from '../Flex'
import { BREAKPOINTS, media } from '../../helpers'
import { Theme } from '../../theme'
import { Button } from '../../molecules/Button'

export const StyledInstagramButton = styled(Button)`
  padding: 16px 24px;

  background: radial-gradient(
      92.18% 99.11% at 26.56% 107.7%,
      #ffdd55 0%,
      #ffdd55 10%,
      #ff543e 50%,
      #c837ab 100%
    ),
    linear-gradient(90deg, #833ab4 0%, #f77737 100%);
  border-radius: 8px;
  color: ${Theme.Colors.typography.inverse};

  min-width: fit-content;
`

export const StyledContainer = styled(Flex)`
  flex-direction: column;

  ${media.from(
    BREAKPOINTS.md,
    `
      flex-direction: row;
    `
  )}
`
