import { Box, Flex } from '../../atoms'
import { Button, ButtonVariant } from '../Button'
import { Dialog } from '../Dialog'

import {
  StyledPopUpContent,
  StyledPopUpDescription,
  StyledPopUpTitle,
} from './ProceedToLinkPopUp.styles'

type ProceedToLinkPopUpProps = {
  isOpen: boolean
  proceedUrl: string
  openInNewTab?: boolean
  closeDialog: () => void
  onConfirmButtonClick?: () => void
  translations?: {
    title: string
    description: string
    proceed: string
    cancel: string
  }
}

export const ProceedToLinkPopUp = ({
  isOpen,
  closeDialog,
  translations,
  proceedUrl,
  onConfirmButtonClick,
  openInNewTab = false,
}: ProceedToLinkPopUpProps) => {
  return (
    <Dialog isOpen={isOpen} showCloseButton={false} onDismiss={closeDialog}>
      <StyledPopUpContent>
        <StyledPopUpTitle>{translations?.title}</StyledPopUpTitle>
        <StyledPopUpDescription>
          {translations?.description}
        </StyledPopUpDescription>
        <Flex justify="center">
          <Box pr={12}>
            <Button
              clickHandler={closeDialog}
              variant={ButtonVariant.OUTLINE}
              width={156}
            >
              {translations?.cancel}
            </Button>
          </Box>
          <Box pl={12}>
            <Button
              href={proceedUrl}
              width={156}
              openInNewTab={openInNewTab}
              clickHandler={onConfirmButtonClick}
            >
              {translations?.proceed}
            </Button>
          </Box>
        </Flex>
      </StyledPopUpContent>
    </Dialog>
  )
}
