import { Theme } from './theme'

/* using this reset here: https://meyerweb.com/eric/tools/css/reset/ */
export const resetStyleCss = `
  a, a:hover, a:active {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  button {
    color: ${Theme.Colors.typography.regular};
    cursor: pointer;
  }

  button[data-reach-tab][data-selected]:focus-visible {
    outline: none;
  }

  select {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  body {
    color: ${Theme.Colors.typography.regular};
    background-color: ${Theme.Colors.background.base};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    *, :after, :before {
      box-sizing: border-box;
    }
  }

  html.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input, textarea, button {
    font-family: inherit
  }`
