import React, { ButtonHTMLAttributes, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import styled from 'styled-components'
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
  useComboboxContext,
} from '@reach/combobox'
import '@reach/combobox/styles.css'

import { emptyFunctionForOptionals } from '@web-apps/utils-shared'

import { Card, Typography } from '../../atoms'
import { Theme } from '../../theme'

import { SearchField, SearchFieldProps } from '../SearchField/SearchField'

type ResultItem = {
  key: string
  value: string
  subtitleText?: string
}
type SearchFieldListProps = SearchFieldProps & {
  resultItems?: ResultItem[]
  onOptionSelect: (item: ResultItem) => void
  noResultsInfo?: React.ReactNode
  debouncedMsc?: number
  debouncedCallback?: (value: string) => void
  onExpandedStateChange?: (isExpanded: boolean) => void
  searchIconOverrideProps?: ButtonHTMLAttributes<HTMLButtonElement>
  closeIconOverrideProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

const StyledCardOption = styled(Card)`
  padding: 16px;
`

const StyledComboboxOption = styled(ComboboxOption)`
  &:hover {
    background: none;
  }

  padding-inline: 0;
  margin-block-end: 0;

  /* the matching segments of text */
  [data-user-value] {
    font-weight: bold;
  }

  /* the unmatching segments */
  [data-suggested-value] {
    font-weight: revert;
  }

  &[data-highlighted],
  &[data-highlighted]:hover {
    background: none;
  }

  &[data-highlighted] ${StyledCardOption} {
    background: ${Theme.Colors.background.highlight};
  }
`

const StyledComboboxPopover = styled(ComboboxPopover)`
  border: none;
  background: none;
  font-size: initial;
  padding-block: 48px;
  margin-top: -4px;
  z-index: 2;
`

const StyledNoResultsContainer = styled(Card)`
  position: relative;
`

const StyledComboboxList = styled(ComboboxList)`
  position: relative;
`

const SearchFieldResults = ({
  resultItems,
  noResultsInfo,
  onExpandedStateChange,
  onItemClick,
}: Pick<
  SearchFieldListProps,
  'resultItems' | 'noResultsInfo' | 'onExpandedStateChange'
> & { onItemClick: (item: ResultItem) => void }) => {
  const { isExpanded } = useComboboxContext()

  useEffect(
    () => onExpandedStateChange && onExpandedStateChange(isExpanded),
    [isExpanded, onExpandedStateChange]
  )
  return resultItems ? (
    <StyledComboboxPopover>
      {resultItems.length > 0 ? (
        <StyledComboboxList>
          {resultItems.map((result, index) => (
            <StyledComboboxOption
              key={result.key}
              value={result.value}
              index={index}
            >
              <StyledCardOption
                translate="no"
                onClick={() => {
                  onItemClick(result)
                }}
              >
                <ComboboxOptionText />
                {result.subtitleText && (
                  <Typography variant="hint" color="inactive" translate="yes">
                    {result.subtitleText}
                  </Typography>
                )}
              </StyledCardOption>
            </StyledComboboxOption>
          ))}
        </StyledComboboxList>
      ) : (
        noResultsInfo && (
          <StyledNoResultsContainer>{noResultsInfo}</StyledNoResultsContainer>
        )
      )}
    </StyledComboboxPopover>
  ) : null
}
export const SearchFieldList = React.forwardRef<
  HTMLInputElement,
  SearchFieldListProps
>(
  (
    {
      onReset,
      onOptionSelect,
      onChange,
      resultItems,
      noResultsInfo,
      debouncedMsc = 0,
      debouncedCallback = emptyFunctionForOptionals,
      onExpandedStateChange,
      closeIconOverrideProps,
      searchIconOverrideProps,
      ...inputProps
    },
    ref
  ) => {
    const resultItemClickedRef = useRef<ResultItem>()
    const debouncedSearchCallback = useDebouncedCallback((value) => {
      debouncedCallback(value)
    }, debouncedMsc)

    return (
      <Combobox
        openOnFocus={(resultItems?.length || 0) > 0 ? true : false}
        onSelect={() => {
          if (resultItemClickedRef.current)
            onOptionSelect(resultItemClickedRef.current)
        }}
      >
        {/* this is needed because SearchField does extend type in comparison to an input component, which leads to a slight mismatch */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ComboboxInput
          {...inputProps}
          ref={ref}
          as={SearchField}
          onReset={onReset}
          autocomplete={false}
          autoComplete="off"
          onChange={(e) => {
            onChange && onChange(e)

            debouncedSearchCallback(e.target.value)
          }}
          closeIconOverrideProps={closeIconOverrideProps}
          searchIconOverrideProps={searchIconOverrideProps}
        />
        <SearchFieldResults
          resultItems={resultItems}
          noResultsInfo={noResultsInfo}
          onExpandedStateChange={onExpandedStateChange}
          onItemClick={(i) => {
            resultItemClickedRef.current = i
          }}
        />
      </Combobox>
    )
  }
)
