import { FC, CSSProperties } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { Card } from '../../atoms'

const StyledTooltip = styled(ReactTooltip)<{
  $maxWidth: CSSProperties['maxWidth']
}>`
  padding: 0;
  max-width: ${({ $maxWidth }) => $maxWidth};

  &.__react_component_tooltip {
    padding: 0;
    margin: 0;
  }

  &.__react_component_tooltip.show {
    opacity: 1;
  }
`

const StyledCard = styled(Card)`
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

export type TooltipProps = {
  id: string
  maxWidthOverride?: CSSProperties['maxWidth']
}

export const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  maxWidthOverride,
  id,
}) => {
  return (
    <StyledTooltip
      id={id}
      multiline={true}
      place="bottom"
      type="light"
      effect="solid"
      backgroundColor="transparent"
      $maxWidth={maxWidthOverride}
    >
      <StyledCard>{children}</StyledCard>
    </StyledTooltip>
  )
}
