export const BACKGROUND_GLOBAL = '#fafafa'
export const BACKGROUND_ALTERNATE = '#efefef'
export const BACKGROUND_BASE = '#ffffff'
export const INACTIVE_BG = '#F1F1F2'
export const BACKGROUND_INFO = '#E2E2E266'
export const BACKGROUND_DARK = '#303030'
export const HALF_TRANSPARENT_BLACK = 'rgba(0,0,0,0.24)'

export const LINE = '#e2e2e2'
export const PROGRESS_COLOR = '#4FC01A'

export const TEXT = '#000000'
export const BUTTON_TEXT = '#303030'
export const TEXT_LIGHTER = '#303030'
export const TEXT_TITLE = '#333333'
export const TEXT_INVERSE = '#ffffff'
export const TEXT_DISABLED = '#949494'
export const TEXT_LABEL = '#616161'
export const TEXT_ERROR = '#c30707'
export const TEXT_DANGER = '#d60000'
export const TEXT_SUCCESS = '#4FC01A'
export const TEXT_SUCCESS_LIGHTER = '#4FC01A33'
export const TEXT_INFO = '#396994'
export const TEXT_SKY = '#89C1F4'

export const BUTTON_BACKGROUND = '#333333'
export const BUTTON_INVERSE = '#FFFFFF'
export const BUTTON_TEXT_DISABLED = '#949494'

export const SWITCH_OFF_COLOR = '#DEDDDC'

export const BORDER_COLOR = '#dddfe1'
export const CHIP_ACTIVE_BORDER_COLOR = '#333333'
export const CHIP_ACTIVE_BACKGROUND_COLOR = '#efefef'

export const SELECT_HIGHLIGHT_COLOR = '#efefef'

export const STATUS_PENDING = '#33333352'
export const STATUS_ACCEPTED = '#89C1F4'
export const STATUS_COMPLETED = TEXT_SUCCESS
export const STATUS_APPROVED = '#4FC01A'
export const STATUS_CANCELLED = '#d60000'

export const LIGHT_SAND = '#fafafa'
export const STAR = '#F6C343'
export const PAPAYA = '#EFA490'
export const BADGE_COLOR = '#FE8E29'
export const LIGHT_GREEN = '#C4D600'

export const TEXT_AREA_ALTERNATIVE_BACKGROUND_COLOR = '#00000014'
